import { Language } from '../language/constants';

import {
  SearchSuggestionResponse,
  DepopRequestMeta,
  FetchProductsResponse,
  FetchFilterAggregatesRequest,
  FetchFilterAggregatesResponse,
  FetchProductAttributesResponse,
  FetchFilterProductAttributesResponse,
} from './types';
import { buildSearchQueryParams as buildProductSearchParams } from './helpers';

import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import {
  CATEGORY_SEARCH_SUGGESTIONS_ENDPOINT,
  FILTER_AGGREGATES,
  FILTER_PRODUCT_ATTRIBUTES_ENDPOINT,
  PRODUCT_ATTRIBUTES_ENDPOINT,
  PRODUCT_SEARCH_ENDPOINT,
} from '@/constants/endpoints';

type DepopRequestHeaders = {
  ['Depop-Session-Id']?: string;
};

export function depopMetaToHeaders(
  meta: DepopRequestMeta
): DepopRequestHeaders {
  return { ['Depop-Session-Id']: meta.sessionId };
}

export function getSearchSuggestions(
  meta: DepopRequestMeta,
  query?: string,
  country?: string
): Promise<AxiosCompatibleResponse<SearchSuggestionResponse>> {
  return http.get(CATEGORY_SEARCH_SUGGESTIONS_ENDPOINT, {
    params: { query, country },
    headers: depopMetaToHeaders(meta),
    withDeviceId: true,
  });
}

export function fetchProducts({
  params,
  searchId,
  language,
  sessionId,
}: {
  params: ReturnType<typeof buildProductSearchParams>;
  searchId: string;
  language?: string;
  sessionId?: string | null;
}): Promise<AxiosCompatibleResponse<FetchProductsResponse>> {
  return http.get(PRODUCT_SEARCH_ENDPOINT, {
    params,
    withDeviceId: true,
    withAuth: true,
    headers: {
      ['Depop-Session-Id']: sessionId ?? '',
      ['Depop-Search-Id']: searchId,
      ...(language === 'en' && { 'X-Cached-Sizes': 'true' }),
    },
  });
}

export function fetchFilterAggregates({
  params,
  meta,
}: FetchFilterAggregatesRequest): Promise<
  AxiosCompatibleResponse<FetchFilterAggregatesResponse>
> {
  return http.get(FILTER_AGGREGATES, {
    params,
    withDeviceId: true,
    withAuth: true,
    headers: depopMetaToHeaders(meta),
  });
}

export function fetchProductAttributes(
  language: string = Language.en
): Promise<AxiosCompatibleResponse<FetchProductAttributesResponse>> {
  return http.get(PRODUCT_ATTRIBUTES_ENDPOINT, {
    params: { country: language },
    cache: 'force-cache',
  });
}

export function fetchFilterProductAttributes(
  language: string = Language.en
): Promise<AxiosCompatibleResponse<FetchFilterProductAttributesResponse>> {
  return http.get(FILTER_PRODUCT_ATTRIBUTES_ENDPOINT, {
    params: { country: language },
    cache: 'force-cache',
  });
}
