import React, { forwardRef } from 'react';
import { clsx, ClassValue } from 'clsx';
import { ArrowDownIcon } from '@depop/web-ui-kit/Icons/ArrowDownIcon';

import styles from './styles.module.css';

export type SelectChangeEvent<T> = React.ChangeEvent<HTMLSelectElement> & {
  target: {
    value: T;
  };
};

export type CustomStyles = {
  '--width'?: string;
};

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  children: React.ReactNode;
  value?: string;
  defaultValue?: string;
  name?: string;
  label?: string;
  onChange?: (e: SelectChangeEvent<never>) => void;
  small?: boolean;
  testId?: string;
  className?: string;
  style?: React.SelectHTMLAttributes<HTMLDivElement>['style'] & CustomStyles;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const Select = forwardRef<HTMLDivElement, SelectProps>(function Select(
  {
    children,
    value,
    defaultValue,
    name,
    onChange,
    small,
    testId,
    className,
    style,
    onFocus,
    onBlur,
    label,
  },
  ref
) {
  const conditionalStyles: ClassValue = {
    [styles['selectContainer--sm']]: small,
  };
  return (
    <div
      className={clsx(styles.selectContainer, conditionalStyles, className)}
      ref={ref}
      style={style}
    >
      <select
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
        data-testid={testId}
        onFocus={onFocus}
        onBlur={onBlur}
        aria-label={label}
      >
        {children}
      </select>
      <ArrowDownIcon title="Open" />
    </div>
  );
});
