'use client';

import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import { MenuIcon } from '@depop/web-ui-kit/Icons/MenuIcon';

import { MobileNavigationMenu } from './MobileNavigationMenu';
import styles from './styles.module.css';

import { useCookieBanner } from '@/modules/cookieBanner/useCookieBanner';
import { useAuth } from '@/modules/auth/useAuth';
import { SecondaryNav } from '@/components/MobileNavigation/SecondaryNav';

export function MobileNavigation() {
  const t = useTranslations('common');
  const [isOpen, setIsOpen] = useState(false);

  const { showCookiesModal } = useCookieBanner();
  const { logout } = useAuth();

  function handleCookiesClick() {
    setIsOpen(false);
    showCookiesModal();
  }

  function confirmLogout(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    logout();
    // Ensure mobile menu is closed when log out is called
    setIsOpen(false);
  }

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.triggerButton}
        onClick={() => setIsOpen(true)}
        data-testid="mobileNavigation"
        aria-haspopup="dialog"
      >
        <MenuIcon className={styles.triggerIcon} title={t('Nav.Menu')} />
      </button>
      <MobileNavigationMenu
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        handleLogout={confirmLogout}
        secondaryNav={<SecondaryNav onCookiesClick={handleCookiesClick} />}
      />
    </div>
  );
}
