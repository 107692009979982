import React from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';

import styles from './styles.module.css';

import { ImageLinkList } from '@/modules/meganav/types';
import { FeaturedImage } from '@/components/MegaNav/FeatureImage';
import { isNotNull } from '@/modules/cms/helpers/isNotNull';

type Props = {
  config: ImageLinkList;
};

export function PopularTiles({ config }: Props) {
  return (
    <div className={styles.container}>
      {config.title && <H3 className={styles.heading}>{config.title}</H3>}
      <div className={styles.featuredContainer}>
        {config.itemsCollection.items.filter(isNotNull).map((item) => (
          <FeaturedImage key={item.path} {...item} />
        ))}
      </div>
    </div>
  );
}
