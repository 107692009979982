import React from 'react';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

// data-testid is used in the id for aria-describedby
type Props = {
  'data-testid'?: string;
} & React.ComponentProps<typeof Text>;

export function ErrorMessage({
  children,
  className,
  'data-testid': dataTestId,
  ...rest
}: Props) {
  return (
    <Text
      id={dataTestId}
      type="caption1"
      role="alert"
      aria-live="polite"
      className={clsx(styles.errorMessage, className)}
      {...rest}
    >
      {children}
    </Text>
  );
}
