'use client';

import React, { useContext, useRef } from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { VisuallyHidden } from '@depop/web-ui-kit/VisuallyHidden';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import clsx from 'clsx';
import { XIcon } from '@depop/web-ui-kit/Icons/XIcon';
import { InstagramIcon } from '@depop/web-ui-kit/Icons/InstagramIcon';
import { TiktokIcon } from '@depop/web-ui-kit/Icons/TiktokIcon';

import { SOCIAL_MEDIA_SITES } from '../../constants/externalUrls';
import { ELEMENT_IDS } from '../../constants/elementIds';
import { ExtendedLink } from '../ExtendedLink';
import { SelectLocation } from '../SelectLocation/index';

import styles from './styles.module.css';
import { FooterAccordian } from './FooterAccordian';

import { useCookieBanner } from '@/modules/cookieBanner/useCookieBanner';
import { useDynamicSelectWidth } from '@/modules/footer/useDynamicSelectWidth';
import {
  FooterUpperConfig,
  FooterLowerConfig,
} from '@/modules/footer/constants';
import { RefererContext } from '@/modules/activityTracker/RefererProvider.tsx';
import { CookieBanner } from '@/components/CookieBanner';
import { useHideFooter } from '@/modules/sharedLayout/useHideFooter';

type Props = {
  isInAppView: boolean;
  segmentWriteKey?: string;
  googleMeasureMentId?: string;
};

export function Footer({
  isInAppView,
  segmentWriteKey,
  googleMeasureMentId,
}: Props) {
  const t = useTranslations('common');
  const isFooterHidden = useHideFooter(isInAppView);
  const referer = useContext(RefererContext) || '';
  const locationSelectRef = useRef<HTMLDivElement | null>(null);
  const { width: locationWidth, calculateWidth: calculateLocationWidth } =
    useDynamicSelectWidth({
      ref: locationSelectRef,
    });

  const { showCookieBanner, setShowCookieBanner, showModalOnly } =
    useCookieBanner(segmentWriteKey, googleMeasureMentId);

  function handleCookiesClick() {
    setShowCookieBanner(true);
  }

  if (isFooterHidden) {
    return null;
  }

  return (
    <>
      {showCookieBanner && (
        <CookieBanner
          referer={referer}
          blocker
          showModalOnly={showModalOnly}
          showCookieBanner={setShowCookieBanner}
        />
      )}
      <footer data-testid="footerV2" id={ELEMENT_IDS.footer}>
        <div className={styles.footerUpperContainer}>
          <div className={styles.footerUpperContent}>
            <div className={styles.footerColumnMobileContainer}>
              {FooterUpperConfig.map(({ title, children }) => (
                <FooterAccordian key={title} title={t(title) || title}>
                  {children.map(({ text, linkAddress }) => (
                    <div className={styles.footerLinkWrapper} key={text}>
                      <ExtendedLink
                        className={styles.footerLink}
                        href={linkAddress}
                      >
                        <Text>{t(text) || text}</Text>
                      </ExtendedLink>
                    </div>
                  ))}
                </FooterAccordian>
              ))}
              <FooterAccordian title={t('Nav.SiteInformation')}>
                {FooterLowerConfig.map(({ text, linkAddress }) => (
                  <div className={styles.footerLinkWrapper} key={text}>
                    <ExtendedLink
                      className={styles.footerLink}
                      href={linkAddress}
                    >
                      <Text>{t(text) || text}</Text>
                    </ExtendedLink>
                  </div>
                ))}
                <button
                  className={styles.footerCookieButton}
                  aria-haspopup="dialog"
                  aria-controls="cookies-modal"
                  onClick={handleCookiesClick}
                >
                  <Text> {t('Nav.Cookies')}</Text>
                </button>
              </FooterAccordian>
            </div>
            <div className={styles.footerColumnDesktopContainer}>
              {FooterUpperConfig.map(({ title, children }) => (
                <div className={styles.footerColumn} key={title}>
                  <H3 className={styles.footerColumTitle}>
                    {t(title) || title}
                  </H3>
                  {children.map(({ text, linkAddress }) => (
                    <div className={styles.footerLinkWrapper} key={text}>
                      <ExtendedLink
                        className={styles.footerLink}
                        href={linkAddress}
                      >
                        <Text>{t(text) || text}</Text>
                      </ExtendedLink>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div
              className={clsx(
                styles.footerColumnDesktopContainer,
                styles.footerSocialColumn
              )}
            >
              <div className={styles.footerSocialContainer}>
                <a
                  className={styles.socialLink}
                  href={SOCIAL_MEDIA_SITES.TWITTER}
                  rel="noreferrer"
                  target="_blank"
                >
                  <VisuallyHidden>X</VisuallyHidden>
                  <XIcon color="black" />
                </a>
                <a
                  className={styles.socialLink}
                  href={SOCIAL_MEDIA_SITES.INSTAGRAM}
                  rel="noreferrer"
                  target="_blank"
                >
                  <VisuallyHidden>Instagram</VisuallyHidden>
                  <InstagramIcon size={24} color="black" />
                </a>
                <a
                  className={styles.socialLink}
                  href={SOCIAL_MEDIA_SITES.TIKTOK}
                  rel="noreferrer"
                  target="_blank"
                >
                  <VisuallyHidden>TikTok</VisuallyHidden>
                  <TiktokIcon size={24} color="black" />
                </a>
              </div>
            </div>
          </div>
          <div className="be-ix-link-block" />
        </div>
        <div className={styles.footerLowerContainer}>
          <div className={styles.footerLowerContent}>
            <div className={styles.footerLocalisationContainer}>
              <SelectLocation
                className={styles.selectLocation}
                label={t('Nav.SelectLocation')}
                ref={locationSelectRef}
                onChange={calculateLocationWidth}
                style={{
                  '--width': locationWidth ? `${locationWidth + 64}px` : '100%',
                }}
              />
            </div>
            <div className={styles.footerLowerDesktopColumn}>
              {FooterLowerConfig.map(({ text, linkAddress }) => (
                <div className={styles.footerLinkWrapper} key={text}>
                  <ExtendedLink
                    className={styles.footerLink}
                    href={linkAddress}
                  >
                    <Text>{t(text) || text}</Text>
                  </ExtendedLink>
                </div>
              ))}
              <button
                className={styles.footerCookieButton}
                aria-haspopup="dialog"
                aria-controls="cookies-modal"
                onClick={handleCookiesClick}
              >
                <Text>{t('Nav.Cookies')}</Text>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
