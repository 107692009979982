import { EmailAvailabilityResponse } from './types';

import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import { User } from '@/modules/user/types';
import {
  AUTH_IDENTIFY_ENDPOINT,
  EMAIL_AVAILABILITY_ENDPOINT,
  REVOKE_TOKEN_ENDPOINT,
} from '@/constants/endpoints';

export function identify(): Promise<AxiosCompatibleResponse<User>> {
  return http.get(AUTH_IDENTIFY_ENDPOINT, { withAuth: true });
}

export function checkEmailAvailability(
  email: string
): Promise<AxiosCompatibleResponse<EmailAvailabilityResponse>> {
  return http.post(EMAIL_AVAILABILITY_ENDPOINT, { email });
}

export function revokeAccessToken(token: string) {
  if (!token) {
    return;
  }
  return http
    .post<void>(REVOKE_TOKEN_ENDPOINT, { token })
    .catch(function discardError(_) {
      return;
    });
}
