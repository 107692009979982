import { URLSearchParams } from 'url';

import { pascalCase } from 'text-pascal-case';
import { ReadonlyURLSearchParams } from 'next/navigation';

import { SearchFilters } from '@/modules/search/useSearchFilters';
import {
  BrandByIdResponse,
  BrandsBatchBySlugResponse,
} from '@/modules/brands/types';
import { getBrandSlug } from '@/modules/brands/utils';
import {
  formatQueryParams,
  getCategoriesFromPath,
} from '@/modules/search/helpers';
import { TSortByValues } from '@/modules/search/types';
import { CategoriesByPath } from '@/modules/categories/types';
import { brandRegex, categoryRegex } from '@/modules/browse/constants';

export function decodeCompositeSizeId(compositeId: string) {
  const [categoryId, compositeVariant, country] = compositeId.split('-');
  const [variantSetId, variantId] = compositeVariant.split('.');

  return {
    categoryId: Number(categoryId),
    variantId: Number(variantId),
    variantSetId: Number(variantSetId),
    country: country || undefined,
  };
}

export function decodeCompositeSizeIds(compositeIds: string[]) {
  return (compositeIds || []).map(decodeCompositeSizeId);
}

export function normaliseCompositeSizeId(compositeId: string) {
  const { categoryId, variantSetId, variantId } =
    decodeCompositeSizeId(compositeId);

  return `${categoryId}-${variantSetId}.${variantId}`;
}

export function normaliseCompositeSizeIds(compositeIds?: string[]) {
  return (compositeIds || []).map(normaliseCompositeSizeId);
}

export function getNormalisedSearchInput({
  searchInput,
  subcategoryNames,
}: {
  searchInput?: string;
  subcategoryNames: string[];
}) {
  if (subcategoryNames.length) {
    const subcategoriesString = subcategoryNames.join(' ');
    return `${searchInput} ${subcategoriesString}`.trim();
  }

  return searchInput;
}

export function transformFiltersToActivityTrackerPayload(
  searchFilters: SearchFilters
) {
  const sizeIds = decodeCompositeSizeIds(searchFilters.sizes || []);

  /**
   * We are setting default values on unset fields to match the legacy website.
   * It is not known whether this is strictly necessary.
   */
  return {
    brandFilter: searchFilters.brands ?? [],
    categoryFilter: searchFilters.category ? [searchFilters.category] : [],
    subcategoryFilter: searchFilters.subcategories ?? [],
    onSaleFilter: searchFilters.isDiscounted,
    colourFilter: searchFilters.colours ?? [],
    conditionFilter: searchFilters.conditions ?? [],
    sizeFilterVariantSetId: sizeIds.map(({ variantSetId }) => variantSetId),
    sizeFilterVariantId: sizeIds.map(({ variantId }) => variantId),
    sizeFilterCategory: sizeIds.map(({ categoryId }) => categoryId),
    sizeFilterCountry: sizeIds.map(({ country }) => country),
    maxPriceFilter: searchFilters.priceMax,
    minPriceFilter: searchFilters.priceMin,
    /* We don't have saved sizes toggle on web */
    saveSizesToggle: false,
    /* Free shipping filter no longer exists */
    freeShippingFilter: false,
  };
}

export function hasActiveFilters(searchFilters: SearchFilters) {
  const {
    isDiscounted,
    brands,
    category,
    colours,
    conditions,
    priceMax,
    priceMin,
    sizes,
  } = searchFilters;

  const hasOnSaleFilter = isDiscounted;
  const hasBrandFilters = Boolean(brands?.length);
  const hasCategoryFilter = Boolean(category);
  const hasColourFilter = Boolean(colours?.length);
  const hasConditionFilter = Boolean(conditions?.length);
  const hasPriceFilter = Boolean(priceMin || priceMax);
  const hasSizeFilter = Boolean(sizes?.length);

  return (
    hasOnSaleFilter ||
    hasBrandFilters ||
    hasCategoryFilter ||
    hasColourFilter ||
    hasConditionFilter ||
    hasPriceFilter ||
    hasSizeFilter
  );
}

export function translateCategory(
  translateFunction: (arg: string) => string,
  string: string
) {
  return translateFunction(pascalCase(string));
}

export function getCategoryPathFromId(
  categoriesByPath: CategoriesByPath,
  id: string
) {
  const [categoryPath] =
    Object.entries(categoriesByPath).find(
      ([_, categoryId]) => categoryId === parseInt(id)
    ) || [];

  return categoryPath;
}

export function parseFilterParams(params: URLSearchParams): SearchFilters {
  return {
    /** when migration is complete, we should rename the categories query param to be singular */
    category: params.get('categories') || undefined,
    subcategories: params.get('subcategories')?.split(','),
    brands: params.get('brands')?.split(','),
    isDiscounted: Boolean(params.get('isDiscounted')),
    priceMin: Number(params.get('priceMin')) || undefined,
    priceMax: Number(params.get('priceMax')) || undefined,
    sizes: params.get('sizes')?.split(','),
    colours: params.get('colours')?.split(','),
    conditions: params.get('conditions')?.split(','),
    sort: (params.get('sort') as TSortByValues) || undefined,
    discountTypes: params.get('discount_types') || undefined,
    minDiscount: params.get('min_discount') || undefined,
    maxDiscount: params.get('max_discount') || undefined,
    shippingId: params.get('shipping_id') || undefined,
    groups: params.get('groups') || undefined,
    productTypes: params.get('productTypes')?.split(',') || undefined,
    isKids: Boolean(params.get('isKids')),
    gender: params.get('gender') || undefined,
  };
}

/**
 * @PB TODO modify this function for use in the search/brand/category page files as well
 * to avoid duplicating the logic
 */
export function parseFiltersFromUrl({
  params,
  pathname,
  isPathParamsMode,
  categoriesByPath = {},
  brandData = {},
}: {
  params: ReadonlyURLSearchParams | URLSearchParams;
  pathname: string;
  isPathParamsMode: boolean;
  categoriesByPath?: CategoriesByPath;
  brandData?: BrandsBatchBySlugResponse;
}): SearchFilters {
  const searchFiltersFromParams = parseFilterParams(params);

  if (!isPathParamsMode) {
    return searchFiltersFromParams;
  }

  const [_, brandSlug] = pathname.match(brandRegex) ?? [];
  const [__, categoryPath] = pathname.match(categoryRegex) ?? [];

  /** @TECHDEBT PB - 12/6/24
   * we should update the backend to return {category, subcategory} instead of just a single category ID
   * then we can remove this logic from the client
   */
  const { categoryId, subcategoryId } = getCategoriesFromPath(
    categoryPath,
    categoriesByPath
  );

  const brand = Object.values(brandData).find(
    (brand) => brand.slug === brandSlug
  );
  const brandId = brand?.id ? String(brand.id) : undefined;

  return {
    ...searchFiltersFromParams,
    ...(categoryId && { category: categoryId }),
    ...(subcategoryId && { subcategories: [subcategoryId] }),
    ...(brandId && { brands: [brandId] }),
  };
}

export function buildPathParamsUrl({
  queryParams,
  brands,
  category,
  subcategories,
  categoriesByPath,
  brandsById,
}: {
  queryParams: URLSearchParams;
  brands?: string[];
  category?: string;
  subcategories?: string[];
  categoriesByPath?: CategoriesByPath;
  brandsById?: BrandByIdResponse;
}) {
  /**
   * If there are multiple brands, multiple subcategories, or none at all
   * use /explore/ pathname with filters as query params
   *
   * e.g. https://www.depop.com/explore/?categories=2&subcategories=43&brands=151%2C270
   *
   * Otherwise, construct a URL with:
   * - /brands/{brandSlug} if a brand is present
   * - /category/{categoryPath} if a category is present
   * - /c/ instead of /category/ if there are too many URL segments
   *
   * e.g. https://www.depop.com/ie/brands/nike/c/mens/tops/t-shirts/
   */
  const categoryPath = getCategoryPathFromId(
    categoriesByPath ?? {},
    subcategories?.[0] ?? category ?? ''
  );
  const hasMultipleSubcategories = subcategories && subcategories.length > 1;

  const brandSlug = getBrandSlug(brandsById, brands);
  const hasMultipleBrands = brands && brands.length > 1;

  if (!hasMultipleBrands && !hasMultipleSubcategories) {
    if (categoryPath && brandSlug) {
      const path = `/brands/${brandSlug}/c/${categoryPath}`;

      queryParams.delete('brands');
      queryParams.delete('categories');
      queryParams.delete('subcategories');

      return `${path}/${formatQueryParams(queryParams)}`;
    } else if (brandSlug) {
      queryParams.delete('brands');

      return `/brands/${brandSlug}/${formatQueryParams(queryParams)}`;
    } else if (categoryPath) {
      queryParams.delete('categories');
      queryParams.delete('subcategories');

      return `/category/${categoryPath}/${formatQueryParams(queryParams)}`;
    }
  }

  return `/explore/${
    queryParams.toString().length > 0 ? `?${queryParams.toString()}` : ''
  }`;
}
