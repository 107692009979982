import React, { Ref } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { SearchSuggestionList } from '../SearchSuggestionList';

import styles from './styles.module.css';

import { Suggestion } from '@/modules/search/helpers';
import { SearchSuggestionType } from '@/modules/search/types';

interface SearchSuggestionsWithRecentProps {
  recentSuggestionsRef: Ref<HTMLUListElement>;
  popularSuggestionsRef: Ref<HTMLUListElement>;
  clearSuggestionsRef: Ref<HTMLButtonElement>;
  searchSuggestions: Suggestion[];
  onClick: (
    suggestion: Suggestion,
    suggestionType: SearchSuggestionType
  ) => void;
  hasValue: boolean;
  recentSearchItems: Suggestion[];
  clearRecentSearchHistory: () => void;
  popularSearchSuggestions: Suggestion[];
  isRefined: boolean;
  searchSuggestionsRef: Ref<HTMLUListElement>;
  isFullViewPort?: boolean;
  trendingSearchRef: Ref<HTMLUListElement>;
  trendingSearchSuggestions: Suggestion[];
}

export function SearchSuggestions({
  searchSuggestions,
  hasValue,
  onClick,
  recentSearchItems,
  clearRecentSearchHistory,
  popularSearchSuggestions,
  recentSuggestionsRef,
  popularSuggestionsRef,
  clearSuggestionsRef,
  isRefined,
  searchSuggestionsRef,
  isFullViewPort,
  trendingSearchRef,
  trendingSearchSuggestions,
}: SearchSuggestionsWithRecentProps) {
  const t = useTranslations('common');
  const hasSearchSuggestions = Boolean(searchSuggestions.length);
  const hasRecentSearches = Boolean(recentSearchItems.length);
  const hasPopularSuggestions = Boolean(popularSearchSuggestions.length);
  const hasTrendingSearchSuggestions = Boolean(
    trendingSearchSuggestions.length
  );

  function hasVerticalPadding() {
    if (!isRefined) {
      return true;
    }
    if (hasValue) {
      return hasSearchSuggestions;
    }
    return false;
  }

  return (
    <div
      data-testid="searchBar__suggestions"
      id="search_suggestions"
      role="dialog"
      className={clsx(styles.searchSuggestionsContainer, {
        [styles['searchSuggestionsContainer--fullViewPort']]: isFullViewPort,
        [styles['searchSuggestionsContainer--verticalPadding']]:
          hasVerticalPadding(),
      })}
    >
      {!hasSearchSuggestions && hasRecentSearches && !isRefined && (
        <>
          <div className={clsx(styles.suggestionListHeader)}>
            <Text as="span" className={clsx(styles.suggestionTitle)}>
              {t('SearchSuggestions.Recent')}
            </Text>
            <Text
              className={clsx(styles.suggestionClearButton)}
              onClick={clearRecentSearchHistory}
              data-testid="recentSearch__clear"
              value="clear"
              ref={clearSuggestionsRef}
              as="button"
            >
              {t('Clear')}
            </Text>
          </div>
          <SearchSuggestionList
            suggestionsRef={recentSuggestionsRef}
            suggestions={recentSearchItems}
            type="recent"
            handleClick={onClick}
            suggestionType={SearchSuggestionType.RECENT}
          />
        </>
      )}
      {!hasSearchSuggestions && hasPopularSuggestions && !isRefined && (
        <>
          <div className={clsx(styles.suggestionListHeader)}>
            <Text as="span" className={clsx(styles.suggestionTitle)}>
              {t('SearchSuggestions.Popular')}
            </Text>
          </div>
          <SearchSuggestionList
            suggestionsRef={popularSuggestionsRef}
            suggestions={popularSearchSuggestions}
            type="popular"
            handleClick={onClick}
            suggestionType={SearchSuggestionType.POPULAR}
          />
        </>
      )}
      {hasValue && isRefined && hasSearchSuggestions && (
        <>
          <SearchSuggestionList
            suggestionsRef={searchSuggestionsRef}
            suggestions={searchSuggestions}
            type="suggested"
            handleClick={onClick}
            suggestionType={SearchSuggestionType.POPULAR}
          />
        </>
      )}
      {!hasSearchSuggestions && hasTrendingSearchSuggestions && !isRefined && (
        <>
          <div className={clsx(styles.suggestionListHeader)}>
            <Text as="span" className={clsx(styles.suggestionTitle)}>
              {t('SearchSuggestions.Trending')}
            </Text>
          </div>
          <SearchSuggestionList
            suggestionsRef={trendingSearchRef}
            suggestions={trendingSearchSuggestions}
            type="trending"
            handleClick={onClick}
            suggestionType={SearchSuggestionType.TRENDING}
          />
        </>
      )}
    </div>
  );
}
