import { useEffect } from 'react';
import { Consent, TRACKING_PROVIDER_ID } from '@depop/web-ui-kit/CookieBanner';

import { hasActioned, getConsentChoices } from './cookies';
import { Facebook, FacebookProviderOptions } from './Facebook';
import { Google, GoogleProviderOptions } from './Google';
import { Depop } from './Depop';

import {
  getSessionStorageItem,
  setSessionStorageItem,
  removeSessionStorageItem,
} from '@/modules/storage';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { safeJsonParse } from '@/modules/storage/helpers';

const providerMap = {
  [TRACKING_PROVIDER_ID.FACEBOOK]: Facebook,
  [TRACKING_PROVIDER_ID.GOOGLE]: Google,
  [TRACKING_PROVIDER_ID.DEPOP]: Depop,
};

type ProviderClass = Facebook | Google | Depop;

type EnabledProviders = {
  [key in TRACKING_PROVIDER_ID]?:
    | FacebookProviderOptions
    | GoogleProviderOptions
    | null;
};

const SESSION_STORAGE_KEY = 'gdpr__providers';

export function useTrackingProviders() {
  let enabledProviderInstances: ProviderClass[] = [];
  const { location } = useCurrentLocation();

  useEffect(() => {
    function beforeUnloadHandler() {
      removeSessionStorageItem(SESSION_STORAGE_KEY);
    }

    window.addEventListener('beforeunload', beforeUnloadHandler);
    window.addEventListener('pagehide', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      window.removeEventListener('pagehide', beforeUnloadHandler);
    };
  }, []);

  function createEnabledProviderInstances(
    enabledProviders: EnabledProviders,
    instantiatedProviderIds?: TRACKING_PROVIDER_ID[]
  ) {
    enabledProviderInstances = (
      Object.keys(enabledProviders) as Array<keyof EnabledProviders>
    ).map((enabledProviderId) => {
      const options = enabledProviders[
        enabledProviderId
      ] as FacebookProviderOptions & GoogleProviderOptions;
      const provider = new providerMap[enabledProviderId]({
        ...options,
        location,
      });
      // instantiatedProviderIds are only supplied when hydrating from session storage
      if (instantiatedProviderIds) {
        if (instantiatedProviderIds.includes(provider.id)) {
          provider.hasInitialised = true;
        }
      } else {
        provider.onPageLoad();
      }
      return provider;
    });
  }

  function updateSessionStorage(enabledProviders: EnabledProviders) {
    setSessionStorageItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        enabledProviders,
        instantiatedProviderIds: enabledProviderInstances
          .filter((instance) => instance.hasInitialised)
          .map((instance) => instance.id),
      })
    );
  }

  function restoreEnabledProviderInstances() {
    // enabledProviderInstances will reset between hook calls so need to rehydrate
    if (enabledProviderInstances.length === 0) {
      const sessionStorageData = safeJsonParse(
        getSessionStorageItem(SESSION_STORAGE_KEY),
        {}
      );

      if (!sessionStorageData?.instantiatedProviderIds) {
        /* @TODO: Throw Error once updates are made to logic */
        return;
      }
      createEnabledProviderInstances(
        sessionStorageData.enabledProviders,
        sessionStorageData.instantiatedProviderIds
      );
    }
  }

  function isActionNeeded() {
    restoreEnabledProviderInstances();

    return enabledProviderInstances.some((provider) =>
      provider.types.some(
        (providerType) => !hasActioned(provider.id, providerType)
      )
    );
  }

  return {
    init: (enabledProviders: EnabledProviders): boolean => {
      if (getSessionStorageItem(SESSION_STORAGE_KEY)) {
        console.warn('useTrackingProviders.init has already been called');
        return isActionNeeded();
      }
      createEnabledProviderInstances(enabledProviders);
      updateSessionStorage(enabledProviders);
      return isActionNeeded();
    },
    consentChanged: (consent: Consent) => {
      restoreEnabledProviderInstances();

      enabledProviderInstances.forEach((provider) => {
        Object.keys(consent).forEach((_consentType) => {
          const consentType = _consentType as keyof Consent;
          const hasType = provider.types.includes(consentType);
          if (!hasType) {
            return;
          }
          const hasConsented = Boolean(consent[consentType]);
          if (hasConsented) {
            provider.onConsentAccepted(consentType);
          } else {
            provider.onConsentRejected(consentType);
          }
        });
      });

      const sessionStorageItem = getSessionStorageItem(SESSION_STORAGE_KEY);
      if (sessionStorageItem) {
        const { enabledProviders } = safeJsonParse(sessionStorageItem, {});
        updateSessionStorage(enabledProviders);
      }
    },
    isActionNeeded,
    getCurrentConsentChoices: getConsentChoices,
  };
}
