import React, { FormEvent } from 'react';
import { useClickAway } from 'react-use';
import { useTranslations } from 'next-intl';
import { tabbable } from 'tabbable';
import clsx from 'clsx';
import { LensIcon } from '@depop/web-ui-kit/Icons/LensIcon';
import { ArrowForwardIcon } from '@depop/web-ui-kit/Icons/ArrowForwardIcon';

import sharedStyles from './sharedStyles.module.css';
import { useSearchBarUtilities } from './SearchBar.hooks';
import { SearchSuggestions } from './SearchSuggestions';
import { SearchBarInput } from './SearchBarInput';

type Props = {
  isAutoFocused?: boolean;
  isPlaceholderAnimated?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  desktopOnly?: boolean;
  onFocus?: () => void;
  placeholder?: string;
  sendATEventOnFocus?: boolean;
  shouldShrink?: boolean;
};

const SEARCH_SUGGESTIONS = [
  'pink ralph lauren shirt',
  'grey nike sweatpants',
  'black dickies cargos',
  'white linen trousers',
  'blue carhartt jeans',
  'black josie top',
  'red corteiz cargos',
  'white lululemon skirt',
  'black converse',
  'green corduroy shirt',
];

export function SearchBar({
  isAutoFocused,
  isPlaceholderAnimated,
  setIsOpen,
  desktopOnly,
  onFocus = () => null,
  placeholder,
  sendATEventOnFocus,
  shouldShrink,
}: Props) {
  const {
    inputValue,
    isFocused,
    menuIsOpen,
    searchSuggestions,
    popularSearchSuggestions,
    recentSearchItems,
    rootRef,
    recentSuggestionsRef,
    popularSuggestionsRef,
    clearSuggestionsRef,
    handleSubmit,
    onFocus: onSearchBarFocus,
    onBlur,
    setInput,
    setSuggestionAsSearch,
    clearRecentSearchHistory,
    handleClearButtonClick,
    isRefined,
    searchSuggestionsRef,
    handleOutsideClick,
    trendingSuggestionsRef,
    trendingSearchSuggestions,
  } = useSearchBarUtilities();

  const hasValue = Boolean(inputValue);
  const t = useTranslations('common');

  function handleSubmitSearch(e: FormEvent<HTMLFormElement>) {
    const tabbableElms = rootRef.current ? tabbable(rootRef.current) : [];
    tabbableElms[0]?.blur();
    handleSubmit(e);
  }

  function handleSearchBarFocus() {
    onSearchBarFocus(sendATEventOnFocus);
    onFocus();
    setIsOpen && setIsOpen(true);
  }

  const showSearchBarButtons = hasValue;

  useClickAway(rootRef, () => {
    handleOutsideClick();
  });

  return (
    <div
      className={clsx(sharedStyles.searchBarContainer, {
        [sharedStyles['searchBarContainer--desktopOnly']]: desktopOnly,
      })}
      ref={rootRef}
    >
      <form
        className={clsx(sharedStyles.wrapper, {
          [sharedStyles['wrapper--shouldShrink']]: shouldShrink,
        })}
        onSubmit={handleSubmitSearch}
        autoComplete="off"
      >
        <LensIcon
          className={clsx(sharedStyles.searchBarIcon, {
            [sharedStyles['searchBarIcon--focused']]: isFocused || hasValue,
          })}
          strokeWidth="1"
          width={desktopOnly ? 20 : 24}
        />
        <SearchBarInput
          id="searchBar__input"
          placeholder={placeholder}
          onFocus={handleSearchBarFocus}
          onBlur={onBlur}
          value={inputValue}
          type="text"
          onChange={setInput}
          name="q"
          maxLength={2048}
          aria-label={t('SearchBar.Placeholder')}
          aria-haspopup="dialog"
          aria-controls="search-suggestions"
          isSuggestionsVisible={menuIsOpen}
          isPlaceholderAnimated={isPlaceholderAnimated}
          suggestions={SEARCH_SUGGESTIONS}
          {...(isAutoFocused ? { autoFocus: true } : {})}
        />
        {showSearchBarButtons && (
          <div className={clsx(sharedStyles.searchBarButtons)}>
            <button
              className={clsx(sharedStyles.clearButton)}
              data-testid="searchBar__clear-btn"
              onClick={handleClearButtonClick}
              type="button"
              id="searchBar__clear-btn"
            >
              {t('Clear')}
            </button>
            <button
              className={clsx(sharedStyles.submitButton)}
              data-testid="searchBar__submit-btn"
              color="black"
            >
              <ArrowForwardIcon strokeWidth="1" title="Search" />
            </button>
          </div>
        )}
        {menuIsOpen && (
          <SearchSuggestions
            searchSuggestions={searchSuggestions}
            hasValue={hasValue}
            onClick={setSuggestionAsSearch}
            recentSearchItems={recentSearchItems}
            clearRecentSearchHistory={clearRecentSearchHistory}
            popularSearchSuggestions={popularSearchSuggestions}
            popularSuggestionsRef={popularSuggestionsRef}
            recentSuggestionsRef={recentSuggestionsRef}
            clearSuggestionsRef={clearSuggestionsRef}
            isRefined={isRefined}
            searchSuggestionsRef={searchSuggestionsRef}
            trendingSearchRef={trendingSuggestionsRef}
            trendingSearchSuggestions={trendingSearchSuggestions}
          />
        )}
      </form>
    </div>
  );
}
