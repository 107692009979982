import { useQueryClient } from '@tanstack/react-query';

import { useBagBasic } from './useBagBasic';
import {
  BagBasicQueryResult,
  BagBasicProduct,
  UpdateBagRequest,
} from './types';

import { RQ_BAG_BASIC_KEY } from '@/modules/ReactQuery/cacheKeys';

export function useUpdateBagBasic() {
  useBagBasic();
  const queryClient = useQueryClient();

  async function removeProducts(mutationData: UpdateBagRequest) {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({
      queryKey: [RQ_BAG_BASIC_KEY],
    });

    // Snapshot the previous value
    const previousData = queryClient.getQueryData<BagBasicQueryResult>([
      RQ_BAG_BASIC_KEY,
    ]);

    if (previousData) {
      // Filter out the removed variants from list of bag items
      const newProducts = Object.entries(previousData.products).reduce(
        (acc, currentValue) => {
          const [productId, productVariantObjects] = currentValue;
          if (Number(productId) !== mutationData[0].product_id) {
            acc[productId] = productVariantObjects;
          } else if (productVariantObjects.length > 1) {
            acc[productId] = productVariantObjects.filter(
              (productVariantObj) => {
                return (
                  productVariantObj.variant_id !== mutationData[0].variant_id
                );
              }
            );
          }
          return acc;
        },
        {} as Record<string, BagBasicProduct[]>
      );

      // Optimistically update to the new value
      queryClient.setQueryData<BagBasicQueryResult>([RQ_BAG_BASIC_KEY], {
        ...previousData,
        count: previousData?.count ? Number(previousData.count) - 1 : 0,
        products: newProducts,
      });
    }

    return previousData;
  }

  return { removeProducts };
}
