import React from 'react';
import { useTranslations } from 'next-intl';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { H2 } from '@depop/web-ui-kit/Typography/H2';

import styles from './styles.module.css';

function BagError() {
  const t = useTranslations('bag');

  return (
    <div className={styles.container}>
      <H2 as="h1" className={styles.title}>
        {t('Bag.Error.Title')}
      </H2>
      <H3 as="p">{t('Bag.Error.Subtitle')}</H3>
    </div>
  );
}

export { BagError };
