import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { H3 } from '@depop/web-ui-kit/Typography/H3';

import styles from './styles.module.css';

import { secondaryNavigation } from '@/constants/secondaryNavigation';
import { ExtendedLink } from '@/components/ExtendedLink';

type Props = {
  onCookiesClick: () => void;
};

export function SecondaryNav({ onCookiesClick }: Props) {
  const t = useTranslations('common');

  return (
    <>
      <H3 className={styles.navTitle}>{t('Nav.SecondaryNavTitle')}</H3>
      <ul className={styles.navSecondaryList}>
        {secondaryNavigation.map((item, index) => (
          <Text
            as="li"
            type="caption1"
            className={styles.navListItem}
            key={index}
          >
            <ExtendedLink
              className={styles.navLink}
              href={item.link}
              prefetch={false}
              target={item.external ? '_blank' : undefined}
              rel={item.rel ? item.rel : undefined}
            >
              {item.translation ? t(item.translation) : item.title}
            </ExtendedLink>
          </Text>
        ))}
        <Text
          as="li"
          type="caption1"
          className={styles.navListItem}
          key="efd99692-f349-4da5-8087-bded7ce9431f"
        >
          <button className={styles.cookiesButton} onClick={onCookiesClick}>
            {t('Nav.Cookies')}
          </button>
        </Text>
      </ul>
    </>
  );
}
