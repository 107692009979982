export const FooterUpperConfig = [
  {
    title: 'Nav.Depop',
    children: [
      {
        text: 'Nav.AboutUs',
        linkAddress: 'https://news.depop.com/who-we-are/about/',
      },
      {
        text: 'Nav.Careers',
        linkAddress: 'https://depopcareers.com/',
      },
      {
        text: 'Nav.Blog',
        linkAddress: '/blog/',
      },
      {
        text: 'Nav.News',
        linkAddress: 'https://news.depop.com/',
      },
      {
        text: 'Nav.Impact',
        linkAddress: 'https://news.depop.com/impact/',
      },
    ],
  },
  {
    title: 'Nav.Sell',
    children: [
      {
        text: 'Nav.SellOnDepop',
        linkAddress: '/sell/',
      },
      {
        text: 'Nav.DepopAmplified',
        linkAddress: 'https://amplified.depop-creators.com/connect#welcome',
      },
    ],
  },
  {
    title: 'Nav.Help',
    children: [
      {
        text: 'Nav.HelpCentre',
        linkAddress: 'https://depophelp.zendesk.com/hc/en-gb',
      },
      {
        text: 'Nav.DepopStatus',
        linkAddress: 'https://depopstatus.com/',
      },
    ],
  },
];

export const FooterLowerConfig = [
  {
    text: 'Nav.Sitemaps',
    linkAddress: '/site-map/categories/',
  },
  {
    text: 'Nav.TermsAndConditions',
    linkAddress:
      'https://depophelp.zendesk.com/hc/en-gb/articles/360001773148-Terms-of-Service',
  },
  {
    text: 'Nav.Privacy',
    linkAddress:
      'https://depophelp.zendesk.com/hc/en-gb/articles/360001792147-Privacy-Policy',
  },
];
