'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { LikeIcon } from '@depop/web-ui-kit/Icons/LikeIcon';
import { usePathname } from 'next/navigation';

import { NavigationListItem } from '../NavigationListItem';
import { NavigationLink } from '../NavigationLink';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';

export function NavigationLikesButton() {
  const t = useTranslations('common');
  const [isAuthenticated, currentUser] = useCurrentUser();
  const pathname = usePathname();
  const isActive = pathname?.endsWith(`/${currentUser?.username}/likes/`);
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const displayBreakpoint = !isAuthenticated ? 'smUp' : undefined;

  const linkProps = {
    href: `/${currentUser?.username}/likes/?isMine=true`,
    as: `/${currentUser?.username}/likes/`,
  };

  function handleLikesClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.NAVBAR_LIKES_ACTION);
  }

  return (
    <NavigationListItem
      handleNavigationListItemClick={handleLikesClick}
      displayBreakpoint={displayBreakpoint}
    >
      <NavigationLink isActive={isActive} {...linkProps}>
        <LikeIcon size={28} title={t('Nav.Likes')} />
      </NavigationLink>
    </NavigationListItem>
  );
}
