export function getNameVariations(firstName?: string, lastName?: string) {
  const firstNameString = firstName || '';
  const lastNameString = lastName || '';
  const initials = `${firstNameString.charAt(0)}${lastNameString.charAt(0)}`;
  const fullName = `${firstNameString} ${lastNameString}`.trim();

  return {
    initials,
    fullName,
  };
}
