import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { FocusableElement, tabbable } from 'tabbable';
import { usePathname, useSearchParams } from 'next/navigation';

import { SearchSuggestion, SearchSuggestionType } from '@/modules/search/types';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { getRouteView } from '@/modules/activityTracker/referers';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { getSearchSuggestions } from '@/modules/search/api';
import {
  getSearchHistory,
  getTrendingSearchSuggestions,
  setSearchHistory,
  Suggestion,
} from '@/modules/search/helpers';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useSessionId } from '@/modules/storage/useSessionId';
import { useExtendedRouter } from '@/modules/routing/useExtendedRouter';
import { SearchUrlController } from '@/modules/search/SearchUrlController';
import { recordMetric } from '@/modules/observability/metrics';
import { Stdv1Stat } from '@/modules/observability/constants';
import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';
import { SupportedLocation } from '@/modules/location/constants';

function getChildLink(element: FocusableElement) {
  return element.querySelector('a') as HTMLElement;
}

function getLabelValue(val: string) {
  return {
    label: val,
    value: val.replace(/\*\*/g, ''),
  };
}

export const DEBOUNCE_SEARCH_DELAY = 200;
export const SUGGESTION_TYPE_QUERY_KEY = '_suggestion-type';

export function useSearchBarUtilities() {
  const router = useExtendedRouter();
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const { location } = useCurrentLocation();
  const queryParams = useSearchParams();
  const urlQuery = queryParams.get('q');
  const path = usePathname();
  const [isFocused, setIsFocused] = useState(false);
  const [isUserInput, setIsUserInput] = useState(true);
  const [originalUserSearch, setOriginalUserSearch] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<Suggestion[]>([]);
  const [{ web_6429_trending_search_suggestions_in_search }, bucket] =
    useExperiments(['web_6429_trending_search_suggestions_in_search'], {
      deferred: true,
    });
  const isTrendingSearchSuggestionsVariant =
    web_6429_trending_search_suggestions_in_search === VARIANT_IDENTIFIER;

  const [isRefined, setIsRefined] = useState(false);
  const lastActiveElement = useRef<{ element: FocusableElement | null }>({
    element: null,
  });
  const rootRef = useRef<HTMLDivElement>(null);
  const urlController = new SearchUrlController();
  const recentSuggestionsRef = useRef<HTMLUListElement>(null);
  const popularSuggestionsRef = useRef<HTMLUListElement>(null);
  const clearSuggestionsRef = useRef<HTMLButtonElement>(null);
  const searchSuggestionsRef = useRef<HTMLUListElement>(null);
  const trendingSuggestionsRef = useRef<HTMLUListElement>(null);
  // we were getting memory leak warnings from state updates
  // after component was un-mounted. This eliminated them
  // from tests and hopefully will improve performance a bit
  const isMounted = useRef(true);

  const [searchBarValue, setSearchBarValue] = useState('');
  const [popularSearchSuggestions, setPopularSearchSuggestions] = useState<
    Suggestion[]
  >([]);
  const [recentSearchItems, setRecentSearchItems] = useState<Suggestion[]>(
    getSearchHistory() || []
  );

  function resetSearchSuggestions() {
    setSearchSuggestions([]);
  }

  function formatSuggestionsForSelect(suggestions: SearchSuggestion[]) {
    if (isMounted.current) {
      const formattedSuggestions = suggestions.map((suggestion) => {
        return {
          ...suggestion,
          ...getLabelValue(suggestion.value),
        };
      });
      setSearchSuggestions(formattedSuggestions);
    }
  }

  useEffect(() => {
    if (searchSuggestions.length && !searchBarValue) {
      resetSearchSuggestions();
    }
  }, [searchBarValue, searchSuggestions]);

  const sessionId = useSessionId() || '';
  const fetchSearchSuggestions = useCallback(
    (newValue?: string) =>
      getSearchSuggestions({ sessionId }, newValue, location),
    [sessionId, location]
  );
  const fetchSelectOptions = React.useCallback(
    debounce((value) => {
      fetchSearchSuggestions(value)
        .then((resp) => {
          formatSuggestionsForSelect(resp.data);
        })
        .catch(() => {
          resetSearchSuggestions();
        });
    }, DEBOUNCE_SEARCH_DELAY),
    [location, fetchSearchSuggestions]
  );

  function handleResetSuggestions() {
    setIsRefined(false);
    resetSearchSuggestions();
  }

  function handleSearchPageLoad(url: string) {
    const isSearchResultsView = getRouteView(url) === 'searchResults.view';
    if (isSearchResultsView) {
      const input = rootRef.current?.querySelector('input');
      input?.blur();
    }
  }

  useEffect(() => {
    handleSearchPageLoad(path);
  }, [path]);

  useEffect(() => {
    const searchQuery = Array.isArray(urlQuery)
      ? urlQuery.join(' ')
      : urlQuery || '';
    if (searchBarValue !== searchQuery) {
      setSearchBarValue(searchQuery);
    }
  }, [urlQuery]);

  function sendClearSearchBarActionEvent() {
    return sendActivityTrackerEvent(
      ActivityTrackerEventType.CLEAR_SEARCH_BAR_ACTION
    );
  }

  function handleClearButtonClick() {
    sendClearSearchBarActionEvent();
    setSearchBarValue('');
    setIsUserInput(true);
    const input = rootRef.current?.querySelector('input');
    input?.focus();
  }

  function setInput(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event;
    if (value === '') {
      sendClearSearchBarActionEvent();
      setIsRefined(false);
    }
    setIsUserInput(true);
    setIsRefined(true);
    setSearchBarValue(value);
  }

  function updateFocus() {
    setIsFocused((isFocused: boolean) => !isFocused);
  }

  function onBlur() {
    updateFocus();
  }

  function onFocus(sendATEvent = true) {
    setMenuIsOpen(true);
    updateFocus();

    if (sendATEvent) {
      sendActivityTrackerEvent(ActivityTrackerEventType.SEARCH_BAR_VIEW);
    }
  }

  function detectUserMention(searchTerm: string) {
    const userMention = new RegExp('^@([a-zA-Z0-9_]+)');
    return userMention.test(searchTerm);
  }

  /* Recent Search Logic */
  function clearRecentSearchHistory() {
    if (lastActiveElement.current) {
      lastActiveElement.current.element = null;
    }
    setRecentSearchItems([]);
    setSearchHistory([]);
  }

  function formatSearchValue(value: string | Suggestion, path = '') {
    if (typeof value === 'string') {
      return { ...getLabelValue(value), path };
    }
    return {
      ...value,
      ...getLabelValue(value.value),
      path,
    };
  }

  function updateRecentSearchHistory(suggestionValue?: string, path = '') {
    const searchHist = getSearchHistory() || [];
    const searchHistory = [...searchHist];
    const formattedRecentSearchHistory = formatSearchValue(
      suggestionValue || searchBarValue,
      path
    );
    searchHistory.unshift(formattedRecentSearchHistory);
    const uniqueRecentSearches = searchHistory.filter(
      (suggestion: Suggestion, index: number, self) =>
        index ===
        self.findIndex(
          (comparedSuggestion) => comparedSuggestion.label === suggestion.label
        )
    );
    if (uniqueRecentSearches.length > 5) {
      uniqueRecentSearches.pop();
    }
    setRecentSearchItems(uniqueRecentSearches);
    setSearchHistory(uniqueRecentSearches);
  }

  function updateRoute(
    searchSelection: Pick<Suggestion, 'value' | 'categoryId'> & {
      suggestionType?: SearchSuggestionType;
    }
  ) {
    handleResetSuggestions();
    setRecentSearchItems(getSearchHistory());
    const { value, categoryId, suggestionType } = searchSelection;
    if (value) {
      const filterValues = urlController.fromUrl(
        Object.fromEntries(queryParams.entries())
      );
      const { searchParams } = urlController.toUrl(filterValues, value, true);
      if (suggestionType) {
        searchParams.set(SUGGESTION_TYPE_QUERY_KEY, suggestionType);
      } else {
        searchParams.delete(SUGGESTION_TYPE_QUERY_KEY);
      }
      const newSearchParams = new URLSearchParams(searchParams);
      updateRecentSearchHistory(value, newSearchParams.toString());
      setMenuIsOpen(false);

      const query = {
        q: value,
        ...(categoryId ? { categories: categoryId.toString() } : {}),
        ...(suggestionType && { [SUGGESTION_TYPE_QUERY_KEY]: suggestionType }),
      };
      const qs = new URLSearchParams(query).toString();
      const url = `/search/?${qs}`;

      return router.push(url);
    }
    return router.push('/search/');
  }

  function handleSubmit(event?: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    event?.stopPropagation();
    setMenuIsOpen(false);
    setIsFocused(false);
    /*
          To avoid duplicate searches on searches that had sanitized slugs,
          e.g. emojis
          compare current searchInput against the unsanitized slug
          instead of comparing it against the searched value which has already been sanitized
          */
    if (!searchBarValue || urlQuery === searchBarValue.trim()) {
      return false;
    }
    if (detectUserMention(searchBarValue)) {
      return router.push(`/${searchBarValue.slice(1)}/`);
    }
    return updateRoute({ value: searchBarValue });
  }

  function formatSuggestions(suggestions: SearchSuggestion[]) {
    return suggestions.map((suggestion) => formatSearchValue(suggestion.value));
  }

  /* Popular Search Logic - performs an empty search suggestions request */
  useEffect(() => {
    recordMetric({
      type: 'increment',
      stat: Stdv1Stat['canary.metric.client.success'],
    });

    // Due to react 18 strict mode behaviour we need to reset isMounted to true
    isMounted.current = true;

    fetchSearchSuggestions()
      .then((res) => {
        const formattedPopularSuggestions = formatSuggestions(res.data);
        if (isMounted.current && formattedPopularSuggestions.length) {
          setPopularSearchSuggestions(
            /**
             * We need to reduce the size of this array to make room for the
             * additional search suggestions with filters
             */
            formattedPopularSuggestions.slice(2, 7)
          );
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setPopularSearchSuggestions([]);
        }
      });
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (searchSuggestions.length && !searchBarValue) {
      resetSearchSuggestions();
    }
  }, [searchBarValue, searchSuggestions]);

  useEffect(() => {
    if ((!isUserInput || urlQuery === searchBarValue) && isRefined) {
      return;
    }
    if (!searchBarValue.length || !isRefined) {
      resetSearchSuggestions();
      setIsRefined(false);
      return;
    }
    fetchSelectOptions(searchBarValue);
    return;
  }, [searchBarValue, fetchSelectOptions]);

  function setSuggestionAsSearch(
    suggestion: Suggestion,
    suggestionType: SearchSuggestionType
  ) {
    const { categoryId, value } = suggestion;
    setSearchBarValue(value);
    setIsRefined(false);
    updateRoute({ value, categoryId, suggestionType });
  }

  function handleOutsideClick() {
    setMenuIsOpen(false);
  }

  useEffect(() => {
    if (isUserInput) {
      setOriginalUserSearch(searchBarValue);
    }

    function handleKeydown(event: KeyboardEvent) {
      if (
        !rootRef.current ||
        (!popularSuggestionsRef.current &&
          !searchSuggestionsRef.current &&
          !trendingSuggestionsRef.current)
      ) {
        return;
      }
      //lock down the last user input
      setIsUserInput(false);
      const tabbableElms = tabbable(rootRef.current);
      const recentSuggestionElems = recentSuggestionsRef.current
        ? tabbable(recentSuggestionsRef.current)
        : [];
      const popularSuggestionElems = popularSuggestionsRef.current
        ? tabbable(popularSuggestionsRef.current)
        : [];
      const searchSuggestionElems = searchSuggestionsRef.current
        ? tabbable(searchSuggestionsRef.current)
        : [];
      const trendingSuggestionElems = trendingSuggestionsRef.current
        ? tabbable(trendingSuggestionsRef.current)
        : [];
      const clearSuggestionButton =
        (clearSuggestionsRef?.current as FocusableElement) || [];
      const suggestionElms: FocusableElement[] = [
        ...recentSuggestionElems,
        ...popularSuggestionElems,
        ...searchSuggestionElems,
        ...trendingSuggestionElems,
      ];
      if (!suggestionElms) {
        return;
      }
      const activeElm = document.activeElement as FocusableElement;
      const activeElmIsFirstSuggestion =
        suggestionElms.indexOf(activeElm) === 0;

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        //if the user is in the first item of the suggestion list we only want to key up to the input field
        if (
          activeElmIsFirstSuggestion ||
          !activeElm ||
          tabbableElms.indexOf(activeElm) === -1
        ) {
          setSearchBarValue(originalUserSearch);
          tabbableElms[0].focus();
          return;
        }
        const prev = tabbableElms[tabbableElms.indexOf(activeElm) - 1];
        if (prev) {
          if (getChildLink(prev)) {
            setSearchBarValue(getChildLink(prev).dataset.value || '');
          }
          prev.focus();
        }
        return;
      }

      if (event.key === 'Escape') {
        setMenuIsOpen(false);
        tabbableElms[0].blur();
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const activeElmIsSearchInput = tabbableElms.indexOf(activeElm) === 0;
        const activeElmIsNotASuggestion =
          suggestionElms.indexOf(activeElm) === suggestionElms.length - 1;
        const next = tabbableElms[tabbableElms.indexOf(activeElm) + 1];
        //if the user is on the last item of the suggestions send them back to the input field
        if (suggestionElms.length && activeElmIsNotASuggestion) {
          setSearchBarValue(originalUserSearch);
          tabbableElms[0].focus();
          return;
        }
        //if the user is in the input field we only want to key down into suggestions
        if (
          suggestionElms.length &&
          (activeElmIsSearchInput || activeElmIsNotASuggestion)
        ) {
          if (getChildLink(suggestionElms[0])) {
            setSearchBarValue(
              getChildLink(suggestionElms[0]).dataset.value || ''
            );
          }
          suggestionElms[0].focus();
          return;
        }
        if (!activeElm || tabbableElms.indexOf(activeElm) === -1) {
          tabbableElms[0].focus();
          return;
        }
        if (suggestionElms.length && next) {
          if (getChildLink(next)) {
            setSearchBarValue(getChildLink(next).dataset.value || '');
          }
          next.focus();
        }
      }

      if (!event.shiftKey && event.key === 'Tab') {
        // Preventing tabbing through the suggestions list.
        // Accessing suggestions can be done with the arrow buttons.
        // Tabbing should navigate further down the website
        const navEl = document.getElementById('mainNavigation');
        const clearButton = document.getElementById('searchBar__clear-btn');
        // focuses on the clear button if there is a value in the search bar
        // otherwise focuses on first nav item
        if (searchBarValue && activeElm !== clearButton) {
          event.preventDefault();
          clearButton?.focus();
          setMenuIsOpen(false);
        } else if (navEl) {
          event.preventDefault();
          setMenuIsOpen(false);
          const links: FocusableElement[] | null = tabbable(navEl);
          links[0]?.focus();
        }
      }

      if (event.shiftKey && event.key === 'Tab') {
        // Preventing tabbing back through the suggestions list.
        event.preventDefault();
        const depopLogo = document.querySelector('a[href="/"]') as HTMLElement;
        depopLogo?.focus();
        setMenuIsOpen(false);
      }
      // To fire when pressing enter on a suggestion
      if (searchBarValue && event.key === 'Enter') {
        if (document.activeElement === clearSuggestionButton) {
          clearRecentSearchHistory();
        } else {
          getChildLink(activeElm)?.click();
        }
      }
    }

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [rootRef, searchBarValue]);

  useEffect(() => {
    if (menuIsOpen) {
      bucket();
    }
  }, [menuIsOpen]);

  return {
    inputValue: searchBarValue,
    isFetchingNewSearch: false, // TODO
    isFocused,
    menuIsOpen,
    searchSuggestions,
    popularSearchSuggestions,
    recentSearchItems,
    rootRef,
    recentSuggestionsRef,
    popularSuggestionsRef,
    clearSuggestionsRef,
    fetchSearchSuggestions,
    formatSuggestionsForSelect,
    handleSubmit,
    onBlur,
    onFocus,
    setInput,
    isUserInput,
    setIsUserInput,
    originalUserSearch,
    setOriginalUserSearch,
    setValue: setSearchBarValue,
    setIsRefined,
    setSuggestionAsSearch,
    clearRecentSearchHistory,
    updateFocus,
    handleClearButtonClick,
    isRefined,
    searchSuggestionsRef,
    handleOutsideClick,
    trendingSuggestionsRef,
    trendingSearchSuggestions: isTrendingSearchSuggestionsVariant
      ? getTrendingSearchSuggestions(location as SupportedLocation)
      : [],
  };
}
