import React from 'react';
import { clsx } from 'clsx';

import styles from './styles.module.css';

import { ExtendedLink } from '@/components/ExtendedLink';

type Props = {
  children: React.ReactNode;
  isActive?: boolean;
  href: string;
  as?: string;
  rel?: string;
  className?: string;
};

export function NavigationLink({
  children,
  isActive = false,
  href,
  rel,
  as,
  className,
}: Props) {
  const conditionalStyles = {
    [styles['navigationLink--active']]: isActive,
  };

  return (
    <ExtendedLink
      className={clsx(styles.navigationLink, conditionalStyles, className)}
      href={href}
      as={as}
      rel={rel}
    >
      {children}
    </ExtendedLink>
  );
}
