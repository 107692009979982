'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { MessageIcon } from '@depop/web-ui-kit/Icons/MessageIcon';

import { NavigationListItem } from '../NavigationListItem';
import { NavigationLink } from '../NavigationLink';

import styles from './styles.module.css';

import { useUserCounter } from '@/modules/user/useUserCounter';
import { NotificationIndicator } from '@/components/NotificationIndicator';

export function NavigationMessagesButton() {
  const t = useTranslations('common');
  const pathname = usePathname();
  const isActive = pathname?.includes(`messages`);

  const { data } = useUserCounter();

  const shouldShowDot =
    data && (data.new_messages_count > 0 || data.buyer_offers_to_action > 0);

  return (
    <NavigationListItem>
      <NavigationLink isActive={isActive} href="/messages/">
        <div className={styles.messageIconWrapper}>
          {shouldShowDot && (
            <NotificationIndicator description={t('Nav.Messages')} />
          )}
          <MessageIcon
            className={styles.messageIcon}
            size={28}
            title={t('Nav.Messages')}
          />
        </div>
      </NavigationLink>
    </NavigationListItem>
  );
}
