'use client';

import React from 'react';
import { usePathname } from 'next/navigation';

import { SearchBar } from '../index';

export function SearchBarDesktop() {
  const pathname = usePathname();
  const isLoginPage = pathname.includes('login');
  const shouldShowSearchBar = !isLoginPage;

  return shouldShowSearchBar && <SearchBar desktopOnly shouldShrink />;
}
