import React from 'react';
import { useTranslations } from 'next-intl';
import { H1 } from '@depop/web-ui-kit/Typography/H1';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { useBagDetailed } from '@/modules/bag/useBagDetailed';
import { getDetailedBagItemCount } from '@/modules/bag/helpers';

function QuickAccessBagHeader() {
  const {
    query: { data, isLoading, isFetching },
  } = useBagDetailed({ enabled: true, includeOffers: true });
  const t = useTranslations('bag');

  const itemCount = data ? getDetailedBagItemCount(data) : 0;

  function renderStatus() {
    switch (true) {
      case isLoading === true:
      case isFetching === true:
        return (
          <Text className={styles.status}>
            {t('Bag.QuickAccess.HeaderLoading')}
          </Text>
        );
      case itemCount > 0:
        return (
          <Text className={styles.status}>
            {t('Bag.QuickAccess.HeaderItemsCount', { count: itemCount })}
          </Text>
        );
      default:
        return null;
    }
  }

  return (
    <div className={styles.wrapper}>
      <H1 className={styles.heading}>{t('Bag.QuickAccess.HeaderTitle')}</H1>
      {renderStatus()}
    </div>
  );
}

export { QuickAccessBagHeader };
