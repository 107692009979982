import { ActiveFilterValues, URLFilterParams, UrlParams } from './types';
import { SearchUrlHelpers } from './SearchUrlController.helpers';

export interface ISearchUrlController {
  fromUrl(queryParams: URLFilterParams): ActiveFilterValues;
  toUrl(
    filters: Partial<ActiveFilterValues>,
    searchQuery?: string,
    showQueryParam?: boolean
  ): UrlParams;
  fromQueryString?(queryString: string): ActiveFilterValues;
}
type SearchUrlControllerArgs = {
  path: string;
};
export class SearchUrlController implements ISearchUrlController {
  private helpers: SearchUrlHelpers;
  private path: string;

  constructor(args: SearchUrlControllerArgs = { path: '/search/' }) {
    this.helpers = new SearchUrlHelpers();
    this.path = args.path;
  }

  public fromUrl(queryParams: URLFilterParams): ActiveFilterValues {
    return this.helpers.getFiltersFromQueryParams(queryParams);
  }

  public toUrl(
    filterValues: Partial<ActiveFilterValues>,
    searchQuery: string,
    showQueryParam?: boolean
  ): UrlParams {
    const searchParams = new URLSearchParams();

    if (showQueryParam) {
      searchParams.set('q', searchQuery);
    }

    return {
      searchParams: this.helpers.getQueryParamsFromFilters(
        searchParams,
        filterValues
      ),
      pathname: this.path,
    };
  }

  public fromQueryString(queryString: string): ActiveFilterValues {
    const searchParams = new URLSearchParams(queryString);
    const queryParams = Object.fromEntries(searchParams);

    return this.fromUrl(queryParams);
  }
}
