import React from 'react';
import { clsx } from 'clsx';

import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
  displayBreakpoint?: 'lgUp' | 'mdUp' | 'mdDown' | 'smUp';
  handleNavigationListItemClick?: () => void;
  className?: string;
};

export function NavigationListItem({
  children,
  displayBreakpoint,
  handleNavigationListItemClick,
  className,
  ...rest
}: Props) {
  const conditionalListItemStyles = {
    [styles['navigationListItem--lgUp']]: displayBreakpoint === 'lgUp',
    [styles['navigationListItem--mdUp']]: displayBreakpoint === 'mdUp',
    [styles['navigationListItem--mdDown']]: displayBreakpoint === 'mdDown',
    [styles['navigationListItem--smUp']]: displayBreakpoint === 'smUp',
  };
  const conditionalProps = {
    ...(handleNavigationListItemClick && {
      onClick: handleNavigationListItemClick,
    }),
  };

  return (
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    <li
      className={clsx(
        styles.navigationListItem,
        conditionalListItemStyles,
        className
      )}
      {...conditionalProps}
      {...rest}
    >
      {children}
    </li>
  );
}
