import { setCookie } from 'cookies-next';

import { getCurrencyCodeFromCountryCode } from '../currencies/helpers';
import { TOP_LEVEL_CATEGORIES } from '../filters/constants';

import { ITEMS_PER_PAGE, TRENDING_SEARCHES_SUGGESTIONS } from './constants';
import { SearchFrom, SearchSuggestion } from './types';
import { SearchFilters } from './useSearchFilters';

import { getLocalStorageItem, setLocalStorageItem } from '@/modules/storage';
import {
  BOOSTED_GROUP_SIZE,
  BOOSTED_SPACING,
} from '@/modules/boostedListings/constants';
import {
  CategoriesByPath,
  Category,
  LegacyCategory,
} from '@/modules/categories/types';
import {
  getNormalisedSearchInput,
  normaliseCompositeSizeIds,
} from '@/modules/filters/helpers';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types.ts';
import { CATEGORIES_ENDPOINT } from '@/constants/endpoints.ts';
import { SearchUrlController } from '@/modules/search/SearchUrlController';
import { RECENT_SEARCH_VAL } from '@/constants/cookies.ts';
import { safeJsonParse } from '@/modules/storage/helpers';
import { SupportedLocation } from '@/modules/location/constants';
import { ShopProductsV3Request } from '@/modules/shop/types';
import { SHOP_PAGE_SIZE } from '@/modules/shop/constants';

export type Suggestion = SearchSuggestion & {
  label: string;
};

const RECENT_SEARCH_KEY = 'recentSearchHistory';

export function getSearchHistory() {
  const recentSearch = getLocalStorageItem(RECENT_SEARCH_KEY);
  return safeJsonParse(recentSearch, []);
}

export function setSearchHistory(history: Suggestion[]) {
  setCookie(RECENT_SEARCH_VAL, history[0]?.value);
  return setLocalStorageItem(RECENT_SEARCH_KEY, JSON.stringify(history));
}

export function buildSearchQueryParams({
  searchInput,
  searchFilters: filters,
  searchTerm,
  location,
  cursor,
  userId,
  subcategoryNames = [],
  includeBoostedListings = false,
  forceFeeCalculation = false,
  itemsPerPage = ITEMS_PER_PAGE,
  from = SearchFrom.InCountrySearch,
}: {
  searchFilters: SearchFilters;
  searchTerm?: string;
  searchInput?: string;
  location: string;
  cursor?: string;
  userId?: number;
  includeBoostedListings?: boolean;
  subcategoryNames?: string[];
  forceFeeCalculation?: boolean;
  itemsPerPage?: number;
  from?: SearchFrom;
}) {
  const what = getNormalisedSearchInput({
    searchInput,
    subcategoryNames,
  });

  return {
    what,
    cursor,
    items_per_page: itemsPerPage,
    country: location,
    searchTerm,
    user_id: userId,
    categories: filters.category,
    price_min: filters.priceMin ? filters.priceMin : undefined,
    price_max: filters.priceMax ? filters.priceMax : undefined,
    brands: filters.brands?.join(','),
    sizes: filters.sizes?.length
      ? normaliseCompositeSizeIds(filters.sizes).join(',')
      : undefined,
    conditions: filters.conditions?.join(','),
    colours: filters.colours?.join(','),
    sort: filters.sort,
    currency: getCurrencyCodeFromCountryCode(location),
    is_discounted: filters.isDiscounted,
    discount_types: filters.discountTypes,
    min_discount: filters.minDiscount,
    max_discount: filters.maxDiscount,
    shippingId: filters.shippingId,
    groups: filters.groups,
    product_types: filters.productTypes,
    is_kids: filters.isKids,
    gender: filters.gender,
    force_fee_calculation: forceFeeCalculation,
    from,
    ...(includeBoostedListings &&
      userId && {
        boosted_spacing: BOOSTED_SPACING,
        boosted_group_size: BOOSTED_GROUP_SIZE,
      }),
  };
}

export function buildShopSearchQueryParams({
  after,
  location,
  searchFilters: filters,
  searchInput,
  subcategoryNames = [],
  forceFeeCalculation = false,
  limit = SHOP_PAGE_SIZE,
}: {
  after?: string;
  limit?: number;
  location: string;
  searchFilters: SearchFilters;
  searchInput?: string;
  subcategoryNames?: string[];
  forceFeeCalculation?: boolean;
}): ShopProductsV3Request['params'] {
  const what = getNormalisedSearchInput({
    searchInput,
    subcategoryNames,
  });
  const hasPrice = Boolean(filters.priceMin || filters.priceMax);

  return {
    limit,
    after: after ? after : undefined,
    what: what ? what : undefined,
    brands: filters.brands?.join(','),
    condition: filters.conditions?.join(','),
    colours: filters.colours?.join(','),
    categories: filters.category,
    discounted: filters.isDiscounted ? filters.isDiscounted : undefined,
    price_min: filters.priceMin ? filters.priceMin : undefined,
    price_max: filters.priceMax ? filters.priceMax : undefined,
    currency: hasPrice ? getCurrencyCodeFromCountryCode(location) : undefined,
    ranking: filters.sort,
    force_fee_calculation: forceFeeCalculation,
    variants: filters.sizes?.length
      ? normaliseCompositeSizeIds(filters.sizes).join(',')
      : undefined,
  };
}

export function buildShopFilterAggregatesSearchQueryParams({
  searchInput,
  searchFilters: filters,
  location,
  userId,
  subcategoryNames = [],
  includeBoostedListings = false,
}: {
  searchInput: string;
  searchFilters: SearchFilters;
  location: string;
  userId?: number;
  includeBoostedListings?: boolean;
  subcategoryNames?: string[];
}) {
  const what = getNormalisedSearchInput({
    searchInput,
    subcategoryNames,
  });

  return {
    what,
    categories: filters.category,
    price_min: filters.priceMin ? filters.priceMin : undefined,
    price_max: filters.priceMax ? filters.priceMax : undefined,
    brands: filters.brands?.join(','),
    condition: filters.conditions?.join(','),
    colours: filters.colours?.join(','),
    sort: filters.sort,
    currency: getCurrencyCodeFromCountryCode(location),
    discounted: filters.isDiscounted,
    variants: filters.sizes?.length
      ? normaliseCompositeSizeIds(filters.sizes).join(',')
      : undefined,
    ...(includeBoostedListings &&
      userId && {
        boosted_spacing: BOOSTED_SPACING,
      }),
  };
}

export function sanitiseSearchQuery(query: string) {
  return encodeURIComponent(query.replace(/-/g, ' '));
}

export function getActiveFiltersCount(filters: SearchFilters) {
  const { sort, ...restFilters } = filters;

  return Object.values(restFilters)
    .filter(Boolean)
    .reduce<number>((sum, value) => {
      if (Array.isArray(value)) {
        return sum + value.length;
      }
      return sum + 1;
    }, 0);
}

/**
 * Convert query params object to either a string with a leading `?`
 * or an empty string
 */
export function formatQueryParams(queryParams: URLSearchParams) {
  const queryParamsString = queryParams.toString();
  if (!queryParamsString.length) {
    return '';
  }
  return `?${queryParamsString}`;
}

export function getCategoriesFromPath(
  categoryPath: string,
  categoriesByPath?: CategoriesByPath
): {
  categoryId?: string;
  subcategoryId?: string;
  activeCategoryId?: string;
} {
  if (!categoryPath || !categoriesByPath) {
    return {};
  }

  const segments = categoryPath.split('/').filter(Boolean);
  const activeCategoryId = categoriesByPath[categoryPath]
    ? String(categoriesByPath[categoryPath])
    : undefined;

  if (segments.length > 2) {
    return {
      subcategoryId: activeCategoryId,
      categoryId: String(categoriesByPath[segments[0] + '/' + segments[1]]),
      activeCategoryId,
    };
  }

  if (segments.length > 1 && !TOP_LEVEL_CATEGORIES.has(segments[0])) {
    return {
      subcategoryId: activeCategoryId,
      categoryId: String(categoriesByPath[segments[0]]),
      activeCategoryId,
    };
  }

  return {
    categoryId: activeCategoryId,
    activeCategoryId,
  };
}

const brandAndCategoryRegex =
  /(\/brands\/([^\/]+)\/)|(\/(?:category|c)\/(.*)\/)|(\/explore\/($|\?.*))/;

export function getIsPathParamsMode(pathname: string) {
  return brandAndCategoryRegex.test(pathname);
}

export function fetchFullCategoryTree(): Promise<
  AxiosCompatibleResponse<LegacyCategory[]>
> {
  return http.get(CATEGORIES_ENDPOINT, { cache: 'force-cache' });
}

/**
 * Used to create a list of unique brand ids from a list of branded category ids
 * e.g. ["100:1", "200:2", "100:3"] => ["100", "200"]
 * @param brandCategoryList
 */
export function getUniqueBrandIds(brandCategoryList: string[]) {
  const uniqueBrandIdsSet = brandCategoryList.reduce(
    (uniqueIds, brandedCategoryId) => {
      return uniqueIds.add(brandedCategoryId.split(':')[0]);
    },
    new Set<string>()
  );
  return Array.from(uniqueBrandIdsSet);
}

export function createBrandCategoryPillHref({
  brandId,
  category,
  categoryId,
  searchQuery,
}: {
  brandId: number;
  category: Category;
  categoryId: number;
  searchQuery: string;
}) {
  const { children, parentId } = category;
  const urlController = new SearchUrlController();
  const isSubcategory = Boolean(!children?.length && parentId);
  const { searchParams } = urlController.toUrl(
    {
      activeCategoryId: isSubcategory ? parentId : categoryId,
      activeSubcategoryIds: isSubcategory ? [categoryId] : undefined,
      activeBrandIds: [brandId],
    },
    searchQuery,
    true
  );
  const searchParamsString = new URLSearchParams(searchParams).toString();

  return `/search/?${searchParamsString}`;
}

export function getTrendingSearchSuggestions(
  location: SupportedLocation
): Suggestion[] {
  return (
    TRENDING_SEARCHES_SUGGESTIONS[location] ||
    TRENDING_SEARCHES_SUGGESTIONS[SupportedLocation.US]
  );
}
