import { useTranslations } from 'next-intl';
import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BinClosedIcon } from '@depop/web-ui-kit/Icons/BinClosedIcon';

import { BagDetailedProduct, UpdateBagRequest } from '@/modules/bag/types';
import { useBagDetailed } from '@/modules/bag/useBagDetailed';
import { useUpdateBagBasic } from '@/modules/bag/useUpdateBagBasic';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { updateBag } from '@/modules/bag/helpers';
import {
  RQ_BAG_BASIC_KEY,
  RQ_BAG_DETAILED_KEY,
} from '@/modules/ReactQuery/cacheKeys';
import { ButtonMinimal } from '@/components/ButtonMinimal';
import { ErrorMessage } from '@/components/ErrorMessage';
import { Spinner } from '@/components/Spinner';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';

enum MutationState {
  Off = 'off',
  Initialised = 'initialised',
  Fetching = 'fetching',
}

type Props = {
  product: BagDetailedProduct;
  sellerId: number;
};

function BagDeleteProductButton({ product, sellerId }: Props) {
  const t = useTranslations('bag');
  const { query: detailedQuery } = useBagDetailed({
    enabled: false,
    includeOffers: false,
  });
  const [authenticated] = useCurrentUser();
  const { removeProducts } = useUpdateBagBasic();
  const queryClient = useQueryClient();
  const [mutationState, setMutationState] = useState(MutationState.Off);
  const [errorText, setErrorText] = useState('');
  const { language } = useCurrentLanguage();

  useEffect(() => {
    if (
      mutationState === MutationState.Initialised &&
      detailedQuery.isRefetching
    ) {
      setMutationState(MutationState.Fetching);
    }
    if (
      mutationState === MutationState.Fetching &&
      !detailedQuery.isRefetching
    ) {
      setMutationState(MutationState.Off);
    }
  }, [mutationState, detailedQuery.isRefetching]);

  /**
   * @TECHDEBT CP 8/1/24
   * updateBag currently includes a local storage update for unauth'd bag handling
   * This should be separated out from our mutation logic. See WEB-4270.
   */
  const updateBagMutation = useMutation({
    mutationFn: (data: UpdateBagRequest) => updateBag(authenticated, data),

    onMutate: async (mutationData) => {
      const previousBasicData = await removeProducts(mutationData);

      // Return a context object with the snapshotted value
      return { previousBasicData };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _newTodo, context) => {
      setErrorText(t('Error.DeleteProduct'));
      if (context?.previousBasicData) {
        queryClient.setQueryData([RQ_BAG_BASIC_KEY], context.previousBasicData);
      }
      setMutationState(MutationState.Off);
    },

    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [RQ_BAG_BASIC_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [RQ_BAG_DETAILED_KEY, language],
      });
    },
  });

  function handleDeleteClick() {
    setErrorText('');
    setMutationState(MutationState.Initialised);
    updateBagMutation.mutate([
      {
        product_id: product.product_id,
        variant_id: product.variant_id || undefined,
        quantity: 0,
        seller_id: sellerId,
      },
    ]);
  }

  const showSpinner = mutationState !== MutationState.Off;

  return showSpinner ? (
    <Spinner />
  ) : (
    <>
      <ButtonMinimal onClick={handleDeleteClick}>
        {/* Svg has padding on it so have to play with the viewBox to get it to align */}
        <BinClosedIcon
          title={t('Bag.DeleteProduct.Title')}
          viewBox="3 3 24 24"
        />
      </ButtonMinimal>
      {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
    </>
  );
}

export { BagDeleteProductButton };
