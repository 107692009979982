import { AggregateMap } from '../search/types';

import { UNKNOWN_BRAND_ID } from './constants';
import { Brand, BrandByIdResponse } from './types';

function filterUnknownBrand(brand: Brand) {
  return brand.id !== UNKNOWN_BRAND_ID;
}

export function buildPopularBrands(
  brands: BrandByIdResponse,
  brandAggregates: AggregateMap,
  maxCount?: number
) {
  const sortedAggregates = Object.entries(brandAggregates)
    .map(([key, { count }]) => ({ id: key, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, maxCount);

  return sortedAggregates
    .map(({ id }) => brands[id] ?? null)
    .filter(Boolean)
    .filter(filterUnknownBrand);
}

export function getBrandSlug(brands: BrandByIdResponse = {}, id?: string[]) {
  if (!(id && id.length)) {
    return undefined;
  }

  return brands[id[0]]?.slug;
}

export function getBrandBySlug(brands: BrandByIdResponse = {}, slug?: string) {
  return Object.values(brands).find((brand) => brand.slug === slug);
}
