import React, { useState } from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { ArrowTopIcon } from '@depop/web-ui-kit/Icons/ArrowTopIcon';
import { ArrowDownIcon } from '@depop/web-ui-kit/Icons/ArrowDownIcon';

import styles from './styles.module.css';

type Props = {
  title: string;
  children: React.ReactNode[];
};

export function FooterAccordian({ title, children }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const IconComponent = isOpen ? ArrowTopIcon : ArrowDownIcon;

  return (
    <>
      <button
        className={styles.footerAccordianButton}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text bold={isOpen} as="span">
          {title}
        </Text>
        <IconComponent size={20} />
      </button>
      {isOpen && children}
    </>
  );
}
