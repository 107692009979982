import { useEffect, useMemo, useState } from 'react';

import { getSessionStorageItem } from '.';

import { sessionStorageSessionIdKey } from '@/modules/activityTracker/constants';
import { CustomEvents } from '@/constants/customEvents';

export function useSessionId() {
  // prevent additional expensive reads to session storage
  const initialSessionId = useMemo(
    () => getSessionStorageItem(sessionStorageSessionIdKey),
    []
  );
  const [sessionId, setSessionId] = useState(initialSessionId);

  function handleSessionIdChange() {
    const updatedSessionId = getSessionStorageItem(sessionStorageSessionIdKey);

    setSessionId(updatedSessionId);
  }

  useEffect(() => {
    window.addEventListener(CustomEvents.SessionId, handleSessionIdChange);

    return () => {
      window.removeEventListener(CustomEvents.SessionId, handleSessionIdChange);
    };
  }, []);

  return sessionId;
}
