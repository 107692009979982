'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { Button } from '@depop/web-ui-kit/Button';

import { NavigationListItem } from '../NavigationListItem';
import { NavigationLink } from '../NavigationLink';

import styles from './styles.module.css';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import {
  ActivityTrackerEventType,
  NavCtaActionTypes,
} from '@/modules/activityTracker/constants';

type Props = {
  eventType?: ActivityTrackerEventType;
  ctaType?: NavCtaActionTypes;
  href: string;
  as?: string;
  ctaText: string;
};

export function NavigationTextButton({
  ctaType,
  href,
  as,
  ctaText,
  eventType,
}: Props) {
  const t = useTranslations('common');
  const [_, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  const isLoginBtn = ctaType === NavCtaActionTypes.NAV_LOGIN_CTA;
  const isSignUpBtn = ctaType === NavCtaActionTypes.NAV_SIGNUP_CTA;

  const conditionalStyles = {
    [styles['textButton--login']]: isLoginBtn,
    [styles['textButton--signup']]: isSignUpBtn,
  };

  function handleButtonClick() {
    if (ctaType) {
      sendActivityTrackerEvent(ActivityTrackerEventType.CTA_ACTION, {
        type: ctaType,
      });
    }
    if (eventType) {
      sendActivityTrackerEvent(eventType);
    }
  }

  return (
    <NavigationListItem
      displayBreakpoint={isLoginBtn ? 'mdUp' : undefined}
      handleNavigationListItemClick={handleButtonClick}
    >
      <NavigationLink href={href} as={as}>
        <Button className={clsx(conditionalStyles)} size="md">
          {t(`Nav.${ctaText}`)}
        </Button>
      </NavigationLink>
    </NavigationListItem>
  );
}
