import React, { useState, useEffect, useRef } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import sharedStyles from '../sharedStyles.module.css';

import styles from './styles.module.css';

import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { Language } from '@/modules/language/constants';

type RotationStatus = 'idle' | 'running' | 'stopped';

type Props = {
  suggestions: string[];
  isSuggestionsVisible?: boolean;
  isPlaceholderAnimated?: boolean;
  placeholder?: string;
  showSearchSuggestions?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const START_ROTATION_DELAY_MS = 4000;
const ROTATE_EVERY_MS = 4000;

export function SearchBarInput({
  isSuggestionsVisible = false,
  isPlaceholderAnimated = true,
  onBlur = () => null,
  onFocus = () => null,
  placeholder,
  suggestions,
  value,
  ...rest
}: Props) {
  const t = useTranslations('common');

  const [activeSuggestionIdx, setActiveSuggestionIdx] = useState(0);
  const [rotationStatus, setRotationStatus] = useState<RotationStatus>('idle');
  const [isFocused, setIsFocused] = useState(false);
  const [isLabelVisible, setIsLabelVisible] = useState(false);
  const rotateSuggestionsIntervalRef = useRef<ReturnType<
    typeof setInterval
  > | null>(null);
  const { language } = useCurrentLanguage();

  const testId = 'searchBar__input';
  const suggestionsLength = suggestions.length;

  function stopRotating() {
    if (rotateSuggestionsIntervalRef.current) {
      window.clearInterval(rotateSuggestionsIntervalRef.current);
    }
    setRotationStatus('stopped');
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
    setIsFocused(true);
    onFocus(e);
  }

  function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
    // show the label when the input is empty
    if (!value) {
      setIsLabelVisible(true);
    }
    setIsFocused(false);
    onBlur(e);
  }

  useEffect(() => {
    // do not start animating if the animation has already been stopped
    if (rotationStatus === 'stopped') {
      return;
    }
    // do not start animating if the input has a value
    if (value !== '') {
      return;
    }
    const rotationDelayTimeout = setTimeout(() => {
      setRotationStatus('running');
      setActiveSuggestionIdx((val) => val + 1);
      rotateSuggestionsIntervalRef.current = setInterval(() => {
        setActiveSuggestionIdx((val) => {
          // set the active index back to 0 when all suggestions have been rotated
          if (val === suggestionsLength - 1) {
            return 0;
          }
          return val + 1;
        });
      }, ROTATE_EVERY_MS);
    }, START_ROTATION_DELAY_MS);
    return () => {
      if (rotateSuggestionsIntervalRef.current) {
        window.clearInterval(rotateSuggestionsIntervalRef.current);
      }
      window.clearTimeout(rotationDelayTimeout);
    };
  }, [rotationStatus, suggestionsLength, value]);

  useEffect(() => {
    // hide the label when the input has a value
    if (value !== '') {
      setIsLabelVisible(false);
      stopRotating();
    } else {
      setIsLabelVisible(true);
    }
  }, [value]);

  function renderLabel() {
    if (!isLabelVisible) {
      return null;
    }
    if (rotationStatus === 'idle') {
      return (
        <Text as="label" className={clsx(styles.inputLabel)} htmlFor={rest.id}>
          {t('SearchBar.PlaceholderPrefix')}
          <span
            className={clsx(styles.initialLabelText, {
              [styles['initialLabelText--caretVisible']]: !isFocused,
            })}
          >{`"${suggestions[activeSuggestionIdx]}"`}</span>
        </Text>
      );
    }
    if (rotationStatus === 'running') {
      return (
        <Text as="label" className={clsx(styles.inputLabel)} htmlFor={rest.id}>
          {t('SearchBar.PlaceholderPrefix')}
          {suggestions.map((query, index) => (
            <span
              className={clsx(styles.rotatingLabelText, {
                [styles['rotatingLabelText--visible']]:
                  index === activeSuggestionIdx,
                [styles['rotatingLabelText--caretVisible']]: !isFocused,
              })}
              key={query}
            >
              {`"${query}"`}
            </span>
          ))}
        </Text>
      );
    }
    return (
      <Text as="label" className={clsx(styles.inputLabel)} htmlFor={rest.id}>
        <span className={clsx(styles.labelText)}>
          {t('SearchBar.Placeholder')}
        </span>
      </Text>
    );
  }

  if (language === Language.en && isPlaceholderAnimated) {
    return (
      <div className={clsx(styles.searchBarInputWrapper)}>
        {renderLabel()}
        <input
          className={clsx(sharedStyles.input, {
            [styles['input--suggestionsVisible']]: isSuggestionsVisible,
          })}
          {...rest}
          data-testid={testId}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder=""
          value={value}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.searchBarInputWrapper)}>
      <input
        className={clsx(sharedStyles.input, {
          [styles['input--suggestionsVisible']]: isSuggestionsVisible,
        })}
        {...rest}
        data-testid={testId}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder || t('SearchBar.Placeholder')}
        value={value}
      />
    </div>
  );
}
