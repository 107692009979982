import React from 'react';
import { useTranslations } from 'next-intl';
import { Button } from '@depop/web-ui-kit/Button';

import styles from './styles.module.css';

import { NavigationListItem } from '@/components/Navigation/NavigationListItem';
import { NavigationLink } from '@/components/Navigation/NavigationLink';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useSellNowHref } from '@/modules/signup/hooks/useSellNowHref';

export function NavigationSellButton() {
  const t = useTranslations('common');
  const [isAuthenticated] = useCurrentUser();
  const { href } = useSellNowHref();
  const { sendActivityTrackerEvent } = useActivityTracker();

  function handleButtonClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.START_SELLER_HUB_ACTION);
  }

  return (
    <NavigationListItem
      // When logged in, display the button from tablet upwards
      // When logged out, display the button on desktop
      displayBreakpoint={isAuthenticated ? 'mdUp' : 'lgUp'}
      handleNavigationListItemClick={handleButtonClick}
    >
      <div className={styles.sellLinkWrapper}>
        <NavigationLink href={href}>
          <Button size="md">{t(`Nav.SellNow`)}</Button>
        </NavigationLink>
      </div>
    </NavigationListItem>
  );
}
