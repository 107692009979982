export enum TrackingType {
  about = 'homepage_getstarted_cta',
  buyer = 'homepage_shopnow_cta',
  seller = 'homepage_sellondepop_cta',
  banner = 'homepage_banner_shopnow_cta',
  skinnyBanner = 'homepage_skinny_banner_shopnow_cta',
  compactBanner = 'homepage_compact_banner_sellnow_cta',
  meganav = 'meganav',
}

export type ShopByPriceTileType = {
  id?: string;
  price: string;
  title: string;
  link: string;
  backgroundStyle: string;
};
