import { FOOTER_LINKS, ZENDESK_ARTICLES } from './externalUrls';

export const secondaryNavigation = [
  {
    title: 'Blog',
    link: FOOTER_LINKS.BLOG_LINK,
    external: true,
    translation: 'Nav.Blog',
    rel: 'noopener',
  },
  {
    title: 'About',
    link: FOOTER_LINKS.ABOUT_LINK,
    external: true,
    translation: 'Nav.About',
  },
  {
    title: 'Sell on Depop',
    link: '/sell/',
    external: false,
    translation: 'Nav.SellOnDepop',
  },
  {
    title: 'Depop Amplified',
    link: FOOTER_LINKS.AMPLIFIED_LINK,
    external: true,
    rel: 'noopener nofollow',
  },
  {
    title: 'Jobs',
    link: FOOTER_LINKS.CAREERS_LINK,
    external: true,
    translation: 'Nav.Jobs',
    rel: 'noopener',
  },
  {
    title: 'News',
    link: FOOTER_LINKS.NEWS_LINK,
    external: true,
    translation: 'Nav.News',
    rel: 'noopener',
  },
  {
    title: 'Support',
    link: FOOTER_LINKS.SUPPORT_LINK,
    external: true,
    translation: 'Nav.Support',
    rel: 'noopener',
  },
  {
    title: 'Terms',
    link: ZENDESK_ARTICLES.TERMS_OF_SERVICE,
    external: true,
    translation: 'Nav.Terms',
    rel: 'noopener',
  },
  {
    title: 'Privacy',
    link: ZENDESK_ARTICLES.PRIVACY_POLICY,
    external: true,
    translation: 'Nav.Privacy',
    rel: 'noopener',
  },
  {
    title: 'Safety',
    link: FOOTER_LINKS.SAFETY_LINK,
    external: true,
    translation: 'Nav.Safety',
    rel: 'noopener',
  },
  {
    title: 'Sitemap',
    link: '/site-map/categories/',
    external: false,
    translation: 'Nav.Sitemaps',
  },
];
