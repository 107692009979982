import { usePathname } from 'next/navigation';

export const EXCLUDE_MEGANAV_ROUTES =
  '^/(sellinghub|login|messages|products/create|products/edit|signup|start-selling|purchases|pay|browse|notifications|picture-upload)/';

export function useHideMegaNav(inAppView: boolean) {
  const pathname = usePathname();
  const excludedMegaNavRoutesReg = new RegExp(EXCLUDE_MEGANAV_ROUTES);

  return inAppView || excludedMegaNavRoutesReg.test(pathname);
}
