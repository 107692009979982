'use client';

import React from 'react';
import clsx from 'clsx';
import { usePathname, useSearchParams } from 'next/navigation';

import { NavigationMessagesButton } from './NavigationMessagesButton';
import { NavigationLikesButton } from './NavigationLikesButton';
import { NavigationTextButton } from './NavigationTextButton';
import { NavigationSellButton } from './NavigationSellButton';
import { NavigationQuickAccessBag } from './NavigationQuickAccessBag';
import { NavigationProfileDropdown } from './NavigationProfileDropdown';
import { NavigationMobileSearchBarButton } from './NavigationMobileSearchBarButton';
import styles from './styles.module.css';

import { NavCtaActionTypes } from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useSignup } from '@/modules/signup/hooks/useSignup';
import { getLoginRedirectParams } from '@/modules/login/helpers';
import { useShowHomepageSearchBar } from '@/modules/home/hooks/useShowHomepageSearchBar';

export function Navigation() {
  const [isAuthenticated] = useCurrentUser();
  const { url: signupUrl } = useSignup();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isHomepageSearchBarVisible = useShowHomepageSearchBar();
  const currentPath = searchParams.size
    ? `${pathname}?${searchParams}`
    : pathname;
  const hideSearchIcon =
    isHomepageSearchBarVisible ||
    Boolean(pathname.match(/^\/(?:([a-z]{2})\/)?sell/));

  return (
    <nav className={styles.nav}>
      <ul
        className={clsx(styles.navigationButtons, {
          [styles['navigationButtons--loggedIn']]: isAuthenticated,
        })}
      >
        {!hideSearchIcon && <NavigationMobileSearchBarButton />}

        {isAuthenticated && (
          <>
            <NavigationSellButton />
            <NavigationMessagesButton />
          </>
        )}
        <NavigationLikesButton />
        <NavigationQuickAccessBag />

        {isAuthenticated && <NavigationProfileDropdown />}
        {!isAuthenticated && (
          <>
            <NavigationSellButton />
            <NavigationTextButton
              ctaType={NavCtaActionTypes.NAV_SIGNUP_CTA}
              href={signupUrl}
              ctaText="SignUp"
            />
            <NavigationTextButton
              ctaType={NavCtaActionTypes.NAV_LOGIN_CTA}
              href={`/login/${getLoginRedirectParams(
                searchParams.get('redirect') || currentPath
              )}`}
              ctaText="Login"
            />
          </>
        )}
      </ul>
    </nav>
  );
}
