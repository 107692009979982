import {
  ActiveCategoryId,
  ActiveBrandIds,
  ActiveColours,
  ActiveConditions,
  ActiveFilterValues,
  ActiveSizeIds,
  URLFilterParams,
} from './types';
import { INITIAL_FILTER_STATE } from './constants';

export class SearchUrlHelpers {
  public getFiltersFromQueryParams = (urlParams: URLFilterParams) => {
    const {
      categories,
      subcategories,
      sizes,
      brands,
      isDiscounted,
      priceMin,
      priceMax,
      colours,
      conditions,
      sort,
    } = urlParams;

    return {
      ...this.getCategoriesFromQuery(
        INITIAL_FILTER_STATE.activeCategoryId,
        INITIAL_FILTER_STATE.activeSubcategoryIds,
        categories,
        subcategories
      ),
      ...this.getSizesFromQuery(INITIAL_FILTER_STATE.activeSizeIds, sizes),
      ...this.getBrandsFromQuery(INITIAL_FILTER_STATE.activeBrandIds, brands),
      ...this.getColoursFromQuery(INITIAL_FILTER_STATE.activeColours, colours),
      ...this.getConditionsFromQuery(
        INITIAL_FILTER_STATE.activeConditions,
        conditions
      ),
      isOnSale: Boolean(isDiscounted),
      activePrice: {
        min: priceMin ? Number(priceMin) : null,
        max: priceMax ? Number(priceMax) : null,
      },
      sortBy: sort || INITIAL_FILTER_STATE.sortBy,
    };
  };

  public getQueryParamsFromFilters = (
    searchParams: URLSearchParams,
    filterValues: Partial<ActiveFilterValues>
  ): URLSearchParams => {
    const {
      activeCategoryId,
      activeSubcategoryIds,
      activeSizeIds,
      activeBrandIds,
      isOnSale,
      activePrice,
      activeColours,
      activeConditions,
      sortBy,
    } = filterValues;

    if (activeCategoryId) {
      searchParams.set('categories', String(activeCategoryId));
    }

    if (activeSubcategoryIds && activeSubcategoryIds.length > 0) {
      searchParams.set('subcategories', activeSubcategoryIds.join(','));
    }

    if (activeBrandIds && activeBrandIds.length > 0) {
      searchParams.set('brands', activeBrandIds.join(','));
    }

    if (activeSizeIds && activeSizeIds.length > 0) {
      const sizes = activeSizeIds.join(',');
      searchParams.set('sizes', sizes);
    }

    if (activePrice && activePrice.min) {
      searchParams.set('priceMin', String(activePrice.min));
    }

    if (activePrice && activePrice.max) {
      searchParams.set('priceMax', String(activePrice.max));
    }

    if (isOnSale) {
      searchParams.set('isDiscounted', String(isOnSale));
    }

    if (activeColours && activeColours.length > 0) {
      searchParams.set('colours', activeColours.join(','));
    }

    if (activeConditions && activeConditions.length > 0) {
      searchParams.set('conditions', activeConditions.join(','));
    }

    if (sortBy) {
      searchParams.set('sort', String(sortBy));
    }

    return searchParams;
  };

  private getCategoriesFromQuery = (
    initialActiveCategoryId: ActiveCategoryId,
    initialSubcategoryIds: number[],
    activeCategoryId?: string,
    activeSubcategoryIds?: string
  ) => {
    if (!activeCategoryId && !activeSubcategoryIds) {
      return {
        activeCategoryId: initialActiveCategoryId,
        activeSubcategoryIds: initialSubcategoryIds,
      };
    }
    const activesubcategoryIdsQueryOrReset: number[] = [];

    if (activeSubcategoryIds) {
      activeSubcategoryIds
        .split(',')
        .forEach((subcat) =>
          activesubcategoryIdsQueryOrReset.push(parseInt(subcat))
        );
    }
    return {
      activeCategoryId: activeCategoryId
        ? parseInt(activeCategoryId)
        : initialActiveCategoryId,
      activeSubcategoryIds: activesubcategoryIdsQueryOrReset,
    };
  };

  private getBrandsFromQuery = (
    initialActiveBrands: ActiveBrandIds,
    activeBrandIds?: string
  ) => {
    if (!activeBrandIds) {
      return {
        activeBrandIds: initialActiveBrands,
      };
    }
    const brandsQuery: number[] = [];
    activeBrandIds.split(',').forEach((id) => brandsQuery.push(Number(id)));
    return {
      activeBrandIds: brandsQuery,
    };
  };

  private getSizesFromQuery = (
    initialActiveSizeIds: ActiveSizeIds,
    sizes?: string
  ): { activeSizeIds: ActiveSizeIds } => {
    if (!sizes) {
      return { activeSizeIds: initialActiveSizeIds };
    }

    return { activeSizeIds: sizes.split(',') };
  };

  private getColoursFromQuery = (
    initialColours: ActiveColours,
    colours?: string
  ): { activeColours: ActiveColours } => {
    if (!colours) {
      return {
        activeColours: initialColours,
      };
    }

    return {
      activeColours: colours.split(','),
    };
  };

  private getConditionsFromQuery = (
    initialConditions: ActiveConditions,
    conditions?: string
  ): { activeConditions: ActiveConditions } => {
    if (!conditions) {
      return {
        activeConditions: initialConditions,
      };
    }

    return {
      activeConditions: conditions.split(','),
    };
  };
}
