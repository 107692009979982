import { FREE_FEE_LOCALES } from '@/modules/location/constants';
import { isSupportedLocation } from '@/modules/location/helpers';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { appendParams } from '@/modules/routing/appendParams/appendParams';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

export function useSellNowHref(): { href: string } {
  const { location } = useCurrentLocation();
  const [isAuthenticated] = useCurrentUser();
  const path = `/signup/?redirect=${encodeURIComponent('/products/create/')}`;

  if (isAuthenticated) {
    return {
      href: '/products/create/',
    };
  }

  const isFreeFeeLocation =
    isSupportedLocation(location) && FREE_FEE_LOCALES.includes(location);
  if (isFreeFeeLocation) {
    return {
      href: appendParams(path, ['titleKey', 'FreeFees.SignUpToSell']),
    };
  }

  return {
    href: appendParams(path, ['titleKey', 'SignUpToSell']),
  };
}
