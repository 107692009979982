import { usePathname } from 'next/navigation';

import { SIGNUP_FORM_PATHS } from '../signup/constants';

import { ROUTES_WITH_CHECKOUT_HEADER } from '@/modules/checkout/constants';

const EXCLUDE_HEADER_ROUTES = [
  ...ROUTES_WITH_CHECKOUT_HEADER,
  ...SIGNUP_FORM_PATHS,
  '/picture-upload/',
  '/youtubelanding/',
  '/signup/email/magic-link/',
];

const EXCLUDE_HEADER_ROUTES_REGEX = [
  /\/login\/magic-link\/verify\//,
  /\/signup\/magic-link\/verify\//,
  /^\/magic-link\/.*/,
];

function excludeHeaderExactMatch(routes: string[], pathname: string) {
  return routes.includes(pathname);
}

function excludeHeaderPartialMatch(routes: RegExp[], pathname: string) {
  return routes.some((regex) => regex.test(pathname));
}

export function useHideHeader(inAppView: boolean) {
  const pathname = usePathname();

  return (
    inAppView ||
    excludeHeaderExactMatch(EXCLUDE_HEADER_ROUTES, pathname) ||
    excludeHeaderPartialMatch(EXCLUDE_HEADER_ROUTES_REGEX, pathname)
  );
}
