/** The way that the providers works is evolving into a type vs a singular provider.
 * During this period we will assume that GA is the type of "analytics" and if GA is
 * allowed to be initialised then we will allow other analytics providers to initialise
 */
import {
  COOKIE_TYPE,
  TRACKING_PROVIDER_ID,
} from '@depop/web-ui-kit/CookieBanner';

import { TrackingProvider } from './types';
import {
  hasConsent,
  setConsent,
  removeTrackingCookies,
  resetSegmentCookies,
} from './cookies';
import { getDefaultGAConsent, getGAConsent } from './helpers';

import { CustomEvents } from '@/constants/customEvents';

export type GoogleProviderOptions = {
  trackingCode?: string;
  segmentWriteKey?: string;
  datadogRumAppId?: string;
  datadogRumService?: string;
  datadogRumClientToken?: string;
  releaseVersion?: string;
  location: string;
};

type CustomWindow = Window & {
  [key: string]: boolean;
};

type Analytics = {
  load: (key: string, options: unknown) => void;
};

export class Google implements TrackingProvider {
  public id = TRACKING_PROVIDER_ID.GOOGLE;
  public types = [COOKIE_TYPE.ANALYTICS];
  private trackingCode?: string;
  private segmentWriteKey?: string;
  private location: string;

  public hasInitialised = false;

  private cookies = {
    [COOKIE_TYPE.ANALYTICS]: ['_ga', '_gid', '_gat', '_dd_s'],
  };
  private botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|botify|AdsBot-Google|pinterest|Branch Metrics|PetalBot|AhrefsBot|Brightbot|Detectify|AppEngine-Google|MicrosoftPreview)';

  constructor(options: GoogleProviderOptions) {
    this.trackingCode = options.trackingCode;
    this.segmentWriteKey = options.segmentWriteKey;
    this.location = options.location;
  }

  public onPageLoad() {
    const shouldInit = hasConsent(this.id, this.types[0]);
    if (shouldInit) {
      this.init();
      return;
    }
    if (navigator.doNotTrack !== '1') {
      // we now load the google script before the user gives cookie consent and then send the consent object to google
      // to tell them what data they can collect. When we init, we will update this with their cookie preferences
      this.setGoogleObjectsOnWindow();
      if (window.gtag) {
        // TS still requires the check
        window.gtag('consent', 'default', getDefaultGAConsent(this.location));
      }
    }
  }
  public onConsentAccepted(type: COOKIE_TYPE) {
    setConsent(this.id, type, true);
    if (!this.hasInitialised) {
      this.init();
    }
    // May not have initialised still if "do not track" is enabled
    if (this.hasInitialised) {
      this.toggleOptOut(false);
    }
    // this won't do anything if 'do not track' is enabled
    // as there won't be a gtag function on the window
    if (window.gtag) {
      window.gtag('consent', 'update', getGAConsent());
    }
    window.dispatchEvent(new Event(CustomEvents.CookieAcceptance));
  }
  public onConsentRejected(type: COOKIE_TYPE) {
    setConsent(this.id, type, false);
    if (this.hasInitialised) {
      this.toggleOptOut(true);
    }
    // @todo: Is there a way to not have to explicitly check the type here?
    if (type === COOKIE_TYPE.ANALYTICS) {
      removeTrackingCookies(this.cookies[type]);
      if (this.hasInitialised) {
        resetSegmentCookies();
        // RS reloading the window is necessary to prevent any subsequent calls to window.analytics (segment) which triggers regeneration of cookies https://depopmarket.atlassian.net/browse/MT-1641
        window.location.reload();
      }
    }
    if (window.gtag) {
      window.gtag('consent', 'update', getGAConsent());
    }
  }

  private setGoogleObjectsOnWindow() {
    window.dataLayer = window.dataLayer || [];
    window.gtag =
      window.gtag ||
      function () {
        if (window.dataLayer) {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        }
      };
  }

  private init() {
    if (navigator.doNotTrack !== '1') {
      this.setGoogleObjectsOnWindow();
      // TS still requires the check
      if (window.gtag) {
        // we have to set the default again here because this can also get called on a full page refresh
        // if we do not send the default first, then GTM never initialises consent and the update
        // does not take effect
        window.gtag('consent', 'default', getDefaultGAConsent(this.location));
        window.gtag('consent', 'update', getGAConsent());
      }
      if (this.segmentWriteKey) {
        this.initialiseSegment();
      }
      this.hasInitialised = true;
    }
  }

  private loadAnalytics(analytics: Analytics, writeKey?: string) {
    if (!writeKey) {
      return;
    }
    // need to be able to tell segment whether to load the facebook pixel
    const socialConsent = hasConsent(
      TRACKING_PROVIDER_ID.FACEBOOK,
      COOKIE_TYPE.SOCIAL
    );

    const coreIntegrations = {
      All: false,
      'Segment.io': true,
      'Google Analytics 4 Web': true,
      AdWords: true,
      'AWS S3': true,
      'Bing Ads': true,
    };

    if (socialConsent) {
      analytics.load(writeKey, {
        integrations: {
          ...coreIntegrations,
          'Facebook Pixel': true,
          'Pinterest Tag': true,
          'Snap Conversions Api': true,
          'Tiktok Conversions': true,
        },
        context: {
          consent: {
            categoryPreferences: {
              Advertising: true,
              Analytics: true,
              Functional: true,
              DataSharing: true,
            },
          },
        },
      });
    } else {
      analytics.load(writeKey, {
        integrations: coreIntegrations,
        context: {
          consent: {
            categoryPreferences: {
              Advertising: false,
              Analytics: true,
              Functional: false,
              DataSharing: false,
            },
          },
        },
      });
    }
  }

  private initialiseSegment() {
    /* eslint-disable */
    // This function has been copied from https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/ and lightly modified to satisfy the TS compiler.
    (function (writeKey, loadAnalytics) {
      var analytics = ((window as any).analytics =
        (window as any).analytics || []);
      if (analytics.initialize) return;
      if (analytics.invoked) {
        if (window.console && console.error) {
          console.error('Segment snippet included twice.');
        }
        return;
      }
      analytics.invoked = true;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (method: string) {
        return function () {
          var args = Array.prototype.slice.call(arguments);
          args.unshift(method);
          analytics.push(args);
          return analytics;
        };
      };
      for (var i = 0; i < analytics.methods.length; i++) {
        var key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key: any, options: any) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js';

        var first = document.getElementsByTagName('script')[0];
        first && first.parentNode!.insertBefore(script, first);
        analytics._loadOptions = options;
      };
      analytics._writeKey = writeKey;
      analytics.SNIPPET_VERSION = '4.15.2';
      loadAnalytics(analytics, writeKey);
      analytics.page();
    })(this.segmentWriteKey, this.loadAnalytics);
    /* eslint-enable */
  }

  private toggleOptOut(shouldDisable: boolean) {
    (window as unknown as CustomWindow)[`ga-disable-${this.trackingCode}`] =
      shouldDisable;
  }
}
