import { ProductNudges } from './types';

import { NUDGES_ENDPOINT } from '@/constants/endpoints';
import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';

export function getNudgesForProduct(
  productIds: string
): Promise<AxiosCompatibleResponse<ProductNudges>> {
  return http.get(NUDGES_ENDPOINT.replace(':productIds', productIds), {
    withAuth: true,
  });
}
