import React from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import clsx from 'clsx';

import styles from './styles.module.css';

type Props = {
  title: string;
  text: string;
  cta?: React.ReactNode;
  className?: string;
};

export function EmptyMessage({ title, text, cta, className }: Props) {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <H3 className={styles.title}>{title}</H3>
      <Text type="caption1">{text}</Text>
      {cta && <div className={styles.cta}>{cta}</div>}
    </div>
  );
}
