import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from '../user/useCurrentUser';
import { RQ_BAG_DETAILED_KEY } from '../ReactQuery/cacheKeys';
import { useCurrentLanguage } from '../language/useCurrentLanguage';
import { useExperiments } from '../experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '../experiments/config';

import { getBagDetailed } from './helpers';
import { BagDetailedCache } from './types';

const BAG_DETAILED_STALE_TIME = 120000; // two minutes in milliseconds

export function useBagDetailed(
  { enabled, includeOffers } = {
    enabled: true,
    includeOffers: false,
  }
) {
  const { language } = useCurrentLanguage();
  const [isAuth] = useCurrentUser();
  const { web_upfront_fees } = useExperiments(['web_upfront_fees']);
  const [{ web_5780_hide_shipping_cost_on_bag_and_qab }] = useExperiments(
    ['web_5780_hide_shipping_cost_on_bag_and_qab'],
    {
      deferred: true,
    }
  );
  const includeShippingInTotals =
    web_5780_hide_shipping_cost_on_bag_and_qab !== VARIANT_IDENTIFIER;
  const query = useQuery<BagDetailedCache>({
    queryKey: [RQ_BAG_DETAILED_KEY, language, includeOffers],
    enabled,
    staleTime: BAG_DETAILED_STALE_TIME,
    queryFn: async () => {
      const res = await getBagDetailed({
        isAuth,
        language,
        includeOffers,
        includeShippingInTotals,
        forceFeeCalculation: web_upfront_fees === VARIANT_IDENTIFIER,
      });
      return res;
    },
  });

  return { query };
}
