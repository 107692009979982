'use client';
import React, { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import {
  CookieBanner as CookieBannerUIKit,
  Consent,
} from '@depop/web-ui-kit/CookieBanner';

import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { getReferer } from '@/modules/activityTracker/referers';
import { useSetWebConsent } from '@/modules/trackingProviders/useSetWebConsent';
import { useTrackingProviders } from '@/modules/trackingProviders/useTrackingProviders';
import { ExtendedLink } from '@/components/ExtendedLink';
import { ZENDESK_ARTICLES } from '@/constants/externalUrls';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';

type Props = {
  referer: string | null;
  showCookieBanner: (shouldShow: boolean) => void;
  showModalOnly: boolean;
  blocker?: boolean;
};

export function CookieBanner({
  blocker,
  referer,
  showModalOnly,
  showCookieBanner,
}: Props) {
  const t = useTranslations('common');
  const [, currentUser] = useCurrentUser();
  const trackingProviders = useTrackingProviders();
  const [currentConsentChoices, setCurrentConsentChoices] = useState<
    Consent | undefined
  >(undefined);
  const { postWebConsent } = useSetWebConsent();
  const { sendActivityTrackerEvent } = useActivityTracker();

  function handleCookiePreferencesSave(consent: Consent) {
    postWebConsent(consent);
    trackingProviders.consentChanged(consent);
    showCookieBanner(false);
  }

  useEffect(() => {
    setCurrentConsentChoices(trackingProviders.getCurrentConsentChoices());
  }, []);

  function handleCloseModal() {
    if (showModalOnly) {
      showCookieBanner(false);
    }
  }

  if (!currentConsentChoices) {
    return null;
  }

  return (
    <CookieBannerUIKit
      blocker={blocker}
      currentConsentChoices={currentConsentChoices}
      showModalOnly={showModalOnly}
      onCloseModal={handleCloseModal}
      transitionFrom={getReferer(referer)}
      userId={currentUser?.id}
      sendActivityTrackerEvent={sendActivityTrackerEvent}
      translations={{
        ['CookieBanner.Title']: t('Gdpr.CookieBannerTitle'),
        ['CookieBanner.Information']: t.rich('Gdpr.CookieBannerInformation', {
          policy: (chunks) => (
            <ExtendedLink
              href={ZENDESK_ARTICLES.COOKIES_AND_SIMILAR_TECHNOLOGIES_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </ExtendedLink>
          ),
        }),
        ['CookieBanner.AcceptAllButton']: t('Gdpr.CookieBannerAcceptAllButton'),
        ['CookieBanner.ManageCookiesButton']: t(
          'Gdpr.CookieBannerManageCookiesButton'
        ),
        ['CookieModal.Title']: t('Gdpr.CookieModalTitle'),
        ['CookieModal.Intro']: t.rich('Gdpr.CookieModalIntro', {
          policy: (chunks) => (
            <ExtendedLink
              href={ZENDESK_ARTICLES.COOKIES_AND_SIMILAR_TECHNOLOGIES_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </ExtendedLink>
          ),
        }),
        ['CookieModal.CancelButton']: t('Gdpr.CookieModalCancelButton'),
        ['CookieModal.AcceptButton']: t('Gdpr.CookieModalAcceptButton'),
        ['CookieModal.NecessaryTitle']: t('Gdpr.CookieModalNecessaryTitle'),
        ['CookieModal.NecessaryContent']: t('Gdpr.CookieModalNecessaryContent'),
        ['CookieModal.SocialTitle']: t('Gdpr.CookieModalSocialTitle'),
        ['CookieModal.SocialContent']: t('Gdpr.CookieModalSocialContent'),
        ['CookieModal.AnalyticsTitle']: t('Gdpr.CookieModalAnalyticsTitle'),
        ['CookieModal.AnalyticsContent']: t('Gdpr.CookieModalAnalyticsContent'),
        ['CookieModal.FunctionalTitle']: t('Gdpr.CookieModalFunctionalTitle'),
        ['CookieModal.FunctionalContent']: t(
          'Gdpr.CookieModalFunctionalContent'
        ),
        ['CookieModal.InputOn']: t('Gdpr.CookieModalInputOn'),
        ['CookieModal.InputOff']: t('Gdpr.CookieModalInputOff'),
      }}
      onCookiePreferencesSave={handleCookiePreferencesSave}
    />
  );
}
