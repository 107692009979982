import { useEffect, useState } from 'react';

import { useProductNudge } from '@/modules/nudges/hooks/useProductNudge.ts';
import { NudgeType, ProductNudge } from '@/modules/nudges/types.ts';

export function useProductNudgeByType(productId: number, type: NudgeType) {
  const [nudge, setNudge] = useState<ProductNudge>();
  const { data } = useProductNudge({ productIds: [productId], enabled: true });

  useEffect(() => {
    if (data) {
      setNudge(data[productId]?.find((n) => n.type === type));
    }
  }, [data, productId]);

  return { nudge };
}
