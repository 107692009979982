import React from 'react';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';
import { Modal } from '@depop/web-ui-kit/Modal';
import clsx from 'clsx';
import { LensIcon } from '@depop/web-ui-kit/Icons/LensIcon';

import { SearchBar } from '../index';

import styles from './styles.module.css';

import { ButtonMinimal } from '@/components/ButtonMinimal';
import { useSearchBarModal } from '@/modules/search/useSearchBarModal';

export function SearchBarModal() {
  const pathname = usePathname();
  const isSearchPage = pathname === '/search';
  const { isOpen, setIsOpen, modalTrigger } = useSearchBarModal();
  const t = useTranslations('common');

  const title = 'Search';

  return (
    <>
      <ButtonMinimal
        className={styles.searchButton}
        onClick={() => setIsOpen(true)}
        aria-haspopup="dialog"
        aria-controls="search-bar-modal"
        ref={modalTrigger}
        aria-label={t('Search.Placeholder')}
      >
        <LensIcon
          className={clsx(styles.searchIcon, {
            [styles[`searchIcon--isActive`]]: isSearchPage,
          })}
          type="lens"
        />
      </ButtonMinimal>
      <Modal
        isOpen={isOpen}
        setOpen={setIsOpen}
        ignoreModalFocus
        content={() => <SearchBar isAutoFocused />}
        title={title}
        id="search-bar-modal"
        overlayAriaLabel={title}
        triggerRef={modalTrigger}
      />
    </>
  );
}
