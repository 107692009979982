import { usePathname } from 'next/navigation';

const EXCLUDE_FOOTER_ROUTES =
  /\/sellinghub|(^(?!.*(success)).*pay)|messages|products\/create|drafts|receipts|signup|start-selling|purchases|login|youtubelanding|magic-link/;

export function useHideFooter(inAppView: boolean) {
  const pathname = usePathname();

  return inAppView || Boolean(pathname.match(EXCLUDE_FOOTER_ROUTES));
}
