import React, { useState, useEffect } from 'react';

/**
 * This hook is used to calculate the width of a <select> element based on the selected option.
 */
export function useDynamicSelectWidth({
  ref,
}: {
  ref: React.MutableRefObject<HTMLElement | null>;
}) {
  const [width, setSelectWidth] = useState(0);

  function calculateWidth() {
    if (!ref.current) {
      return;
    }

    const textLength =
      ref.current.querySelector('option:checked')?.textContent?.length;

    if (textLength) {
      // 8.3 is the average width of a character in pixels
      setSelectWidth(Math.round(textLength * 8.3));
    }
  }

  useEffect(() => {
    calculateWidth();
  }, []);

  return { width, calculateWidth };
}
