import {
  LegacyProductStatus,
  Offer,
  ProductStatus,
} from '@/modules/product/types';
import { CurrencyCode } from '@/modules/currencies/constants';
import { Picture } from '@/modules/pictures/types';
import {
  TransformedPriceEstimateDictionary,
  PriceBreakdown,
} from '@/modules/price/types';

export type BasicProduct = {
  productId: number;
  variantId?: number;
};

export type BagBasicProduct = {
  product_id: number;
  quantity: number;
  variant_id?: number;
};

export type UpdateBagProduct = {
  seller_id: number;
} & BagBasicProduct;

export type FetchBagBasicResponse = {
  products: Record<string, BagBasicProduct[]>;
};

export type BagDetailedProduct = {
  product_id: number;
  quantity?: number;
  variant_id?: number;
  pictures?: Picture[][];
  description: string;
  pricing: TransformedPriceEstimateDictionary;
  offer?: Offer;
  status: ProductStatus | LegacyProductStatus;
  size?: string;
  slug: string;
  country_code: string;
  category_id: number;
  brand_name?: string;
  product_type: string;
};

export type OptimisticBagDetailedProduct = Partial<BagDetailedProduct> & {
  is_optimistic: boolean;
};

export interface VacationModePreferences {
  on_vacation: boolean;
}

export interface BundleShipping {
  multiple_items_shipping_price: string;
}

export interface SellerSettings {
  vacation_mode_preferences: VacationModePreferences;
  bundle_shipping: BundleShipping;
}

export type BagDetailedSellerInfo = {
  verified: boolean;
  initials: string;
  id: number;
  picture?: Picture[];
  username: string;
  first_name: string;
  last_name: string;
  reviews_total: number;
  reviews_rating: number;
  settings?: SellerSettings;
};

export type OptimisticBagDetailedSellerInfo = Partial<BagDetailedSellerInfo> & {
  id: number;
  is_optimistic: boolean;
};

export type BagDetailedSummary = {
  items_amount: string;
  original_items_amount?: string;
  shipping_amount?: string;
  currency: CurrencyCode;
  total_amount: string;
  original_items_base_amount?: string;
  items_amount_breakdown?: PriceBreakdown;
};

export type OptimisticBagDetailedSummary = Partial<BagDetailedSummary> & {
  is_optimistic: boolean;
};

type BagDetailedSeller = {
  seller: BagDetailedSellerInfo;
  products: BagDetailedProduct[];
  summary: BagDetailedSummary;
};

export type BagDetailedSellerCache = {
  seller: BagDetailedSellerInfo | OptimisticBagDetailedSellerInfo;
  products: (BagDetailedProduct | OptimisticBagDetailedProduct)[];
  summary: BagDetailedSummary | OptimisticBagDetailedSummary;
};

export type FetchBagDetailedResponse = {
  sellers: BagDetailedSeller[];
};

export type BagDetailedCache = {
  sellers: BagDetailedSellerCache[];
};

export type UpdateBagRequest = UpdateBagProduct[];

export function isOptimisticSeller(
  seller: BagDetailedSellerCache['seller']
): seller is OptimisticBagDetailedSellerInfo {
  return 'is_optimistic' in seller && seller.is_optimistic;
}

export function isOptimisticProduct(
  product: BagDetailedProduct | OptimisticBagDetailedProduct
): product is OptimisticBagDetailedProduct {
  return 'is_optimistic' in product && product.is_optimistic;
}

export function hasOptimisticProducts(
  products: (BagDetailedProduct | OptimisticBagDetailedProduct)[]
) {
  return products.some((product) => isOptimisticProduct(product));
}

export function isOptimisticSummary(
  summary: BagDetailedSellerCache['summary']
): summary is OptimisticBagDetailedSummary {
  return 'is_optimistic' in summary && summary.is_optimistic;
}

export type BagTrackingTypes = 'bag' | 'quickAccessBag';

export type BagViewArgs = {
  productIds: number[];
  soldProductIds: number[];
};

export type BagBasicQueryResult = {
  count: number;
  products: FetchBagBasicResponse['products'];
};
