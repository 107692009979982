import React from 'react';
import { AVATAR_SIZE } from '@depop/web-ui-kit/Avatar';

import { BagSoldOutItems } from '../BagSoldOutItems';

import styles from './styles.module.css';

import {
  BagDetailedSellerCache,
  isOptimisticProduct,
  isOptimisticSeller,
} from '@/modules/bag/types';
import { ExtendedLink } from '@/components/ExtendedLink';
import {
  filterRequiredPictures,
  getNearestSizePictureFormat,
} from '@/modules/pictures/helpers';
import { BagProduct } from '@/components/Bag/BagProduct';
import { BagProductPlaceholder } from '@/components/Bag/BagProductPlaceholder';
import { UserDetailsPlaceholder } from '@/components/UserDetails/UserDetailsPlaceholder';
import { UserDetails } from '@/components/UserDetails';
import { isSold } from '@/modules/product/helpers/isSold';
import { getNonoptimisticProducts } from '@/modules/bag/helpers';
import { QuickAccessBagPricingAndCheckout } from '@/components/Bag/QuickAccessBagPricingAndCheckout';
import { useExperiments } from '@/modules/experiments/useExperiments.ts';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config.ts';

type Props = {
  seller: BagDetailedSellerCache['seller'];
  products: BagDetailedSellerCache['products'];
  summary: BagDetailedSellerCache['summary'];
  showAcceptedOfferPrice?: boolean;
} & React.ComponentPropsWithRef<'div'>;

function QuickAccessBagSeller({ seller, products, summary, ...rest }: Props) {
  const sellerPictureUrl =
    seller.picture &&
    getNearestSizePictureFormat(filterRequiredPictures(seller.picture), 32);

  const unsoldProducts = products.filter(
    (product) => !isSold(product.status || '')
  );
  const soldProducts = products.filter((product) =>
    isSold(product.status || '')
  );
  const nonoptimisticSoldProducts = getNonoptimisticProducts(soldProducts);
  const [{ web_5780_hide_shipping_cost_on_bag_and_qab }] = useExperiments(
    ['web_5780_hide_shipping_cost_on_bag_and_qab'],
    {
      deferred: true,
    }
  );
  const showEstimatedShipping =
    web_5780_hide_shipping_cost_on_bag_and_qab !== VARIANT_IDENTIFIER;

  return (
    <div {...rest}>
      {isOptimisticSeller(seller) ? (
        <UserDetailsPlaceholder />
      ) : (
        <ExtendedLink
          href={`/${seller.username}`}
          className={styles.userDetailsAnchor}
        >
          <UserDetails
            pictureUrl={sellerPictureUrl?.url}
            firstName={seller.first_name}
            lastName={seller.last_name}
            username={seller.username}
            verified={seller.verified}
            avatarSize={AVATAR_SIZE.EXTRA_SMALL}
          />
        </ExtendedLink>
      )}
      <div className={styles.wrapper}>
        {unsoldProducts.length > 0 && (
          <>
            <div className={styles.products}>
              {unsoldProducts.map((product) => {
                if (isOptimisticProduct(product)) {
                  return (
                    <BagProductPlaceholder
                      key={`${product.product_id}-${product.variant_id}`}
                    />
                  );
                }
                return (
                  <BagProduct
                    key={`${product.product_id}-${product.variant_id}`}
                    product={product}
                    sellerId={seller.id}
                    showEstimatedShipping={showEstimatedShipping}
                    showInBagNudge
                  />
                );
              })}
            </div>
            <QuickAccessBagPricingAndCheckout
              summary={summary}
              unsoldProducts={unsoldProducts}
              showEstimatedShipping={showEstimatedShipping}
              seller={seller}
            />
          </>
        )}
        {soldProducts.length > 0 && (
          <div className={styles.soldOutWrapper}>
            <BagSoldOutItems
              products={nonoptimisticSoldProducts}
              sellerId={seller.id}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export { QuickAccessBagSeller };
