import React, { useEffect } from 'react';
import { LoadingBall } from '@depop/web-ui-kit/LoadingBall';
import { useTranslations } from 'next-intl';
import { Button } from '@depop/web-ui-kit/Button';

import { QuickAccessBagSeller } from '../QuickAccessBagSeller';
import { BagError } from '../BagError';

import styles from './styles.module.css';

import { useBagDetailed } from '@/modules/bag/useBagDetailed';
import { ExtendedLink } from '@/components/ExtendedLink';
import { EmptyMessage } from '@/components/EmptyMessage';
import { useBagTracking } from '@/modules/bag/useBagTracking';

function QuickAccessBag() {
  const t = useTranslations('bag');
  const {
    query: { data, isLoading, isError },
  } = useBagDetailed({ enabled: true, includeOffers: true });

  const bagTracking = useBagTracking();

  const { sellers } = data || {};

  function renderContent() {
    if (isLoading) {
      return <LoadingBall />;
    }
    if (isError) {
      return <BagError />;
    }
    if (sellers?.length !== 0) {
      return (
        <ol>
          {sellers?.map((data, index) => {
            const isLastItem = index + 1 === sellers.length;
            return (
              <li
                className={styles.listItem}
                key={data.summary.currency + '-' + data.seller.id}
              >
                {/* data is grouped per seller per currency so we should use this as the key */}
                <QuickAccessBagSeller
                  seller={data.seller}
                  products={data.products}
                  summary={data.summary}
                />
                {!isLastItem && <div className={styles.line} />}
              </li>
            );
          })}
        </ol>
      );
    }
    if (sellers?.length === 0) {
      return (
        <EmptyMessage
          title={t('Bag.QuickAccess.Empty.Title')}
          text={t('Bag.QuickAccess.Empty.Subtitle')}
          cta={
            <ExtendedLink href="/explore">
              <Button>{t('Bag.QuickAccess.Empty.Cta')}</Button>
            </ExtendedLink>
          }
        />
      );
    }
    return null;
  }

  useEffect(() => {
    bagTracking.sendQuickAccessBagViewEvent();
  }, []);

  function handleViewBagClick() {
    bagTracking.sendBagViewAction('quickAccessBag');
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>{renderContent()}</div>
      <div className={styles.ctaWrapper}>
        <ExtendedLink href="/bag">
          <Button outline block onClick={handleViewBagClick}>
            {t('Bag.QuickAccess.ViewBag')}
          </Button>
        </ExtendedLink>
      </div>
    </div>
  );
}

export { QuickAccessBag };
