import Cookies from 'universal-cookie';
import {
  Consent,
  COOKIE_TYPE,
  TRACKING_PROVIDER_ID,
} from '@depop/web-ui-kit/CookieBanner';

export const defaultConsentChoices: Consent = {
  [COOKIE_TYPE.ANALYTICS]: false,
  [COOKIE_TYPE.FUNCTIONAL]: false,
  [COOKIE_TYPE.SOCIAL]: false,
};

type Choices = Record<COOKIE_TYPE, boolean>;

type Window = {
  analytics: {
    reset: () => void;
  };
};

function getCookieConsentKey(
  providerId: TRACKING_PROVIDER_ID,
  providerType: COOKIE_TYPE
) {
  return `gdpr__${providerId}__${providerType}`;
}

export function hasConsent(
  providerId: TRACKING_PROVIDER_ID,
  cookieType: COOKIE_TYPE
) {
  const cookies = new Cookies();
  return cookies.get(getCookieConsentKey(providerId, cookieType)) === true;
}

export function hasActioned(
  providerId: TRACKING_PROVIDER_ID,
  cookieType: COOKIE_TYPE
) {
  const cookies = new Cookies();
  return cookies.get(getCookieConsentKey(providerId, cookieType)) !== undefined;
}

export function setConsent(
  providerId: TRACKING_PROVIDER_ID,
  cookieType: COOKIE_TYPE,
  accepted: boolean
) {
  const cookies = new Cookies();
  cookies.set(getCookieConsentKey(providerId, cookieType), accepted, {
    path: '/',
    secure: true,
    // consent is max 1 year as per gdpr guidelines
    maxAge: 365 * 86400,
  });
}

export function removeTrackingCookies(cookiesToRemove: string[]) {
  const cookies = new Cookies();
  cookiesToRemove.forEach((cookie) => {
    cookies.remove(cookie, { path: '/' });
  });
}

export function getConsentChoices(): Consent {
  const cookies = new Cookies();
  const all = cookies.getAll();
  const choices: Choices = {};
  Object.values(COOKIE_TYPE).forEach((_cookieType) => {
    const cookieType = _cookieType as COOKIE_TYPE;
    if (cookieType !== COOKIE_TYPE.NECESSARY) {
      const matches = Object.entries(all).filter(([cookieKey]) => {
        const pattern = `^gdpr__[a-z]+__${cookieType}$`;
        const regExp = new RegExp(pattern, 'g');
        return cookieKey.match(regExp);
      });
      if (matches.length > 0) {
        choices[cookieType] = matches[0][1] === true;
      } else {
        choices[cookieType] = defaultConsentChoices[cookieType];
      }
    }
  });
  return choices;
}

export function resetSegmentCookies() {
  return (window as unknown as Window).analytics.reset();
}
