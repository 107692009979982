import React from 'react';

import styles from './styles.module.css';

import { Placeholder } from '@/components/Placeholder';

type Props = {
  className?: string;
};

function UserDetailsPlaceholder({ className }: Props) {
  return (
    <div className={`${className} ${styles.wrapper}`}>
      <div className={styles.avatarWrapper}>
        <Placeholder shape="circle" width="32px" height="32px" />
      </div>
      <div>
        <Placeholder shape="rectangle" width="90px" height="18px" />
      </div>
    </div>
  );
}

export { UserDetailsPlaceholder };
