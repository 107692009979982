import React from 'react';
import theme from '@depop/web-ui-kit/legacy/styledComponents/styles/theme';

import styles from './styles.module.css';

type Props = {
  size?: string;
  color?: keyof typeof theme.colors;
  'data-testid'?: string;
  className?: string;
  children?: React.ReactNode;
};

export function Spinner({
  className = '',
  size,
  color,
  'data-testid': dataTestId,
  children,
}: Props) {
  const customStyles = {
    '--color': color,
    '--size': size,
  } as React.CSSProperties;

  return (
    <span
      className={`${className} ${styles.spinner}`}
      style={customStyles}
      data-testid={dataTestId}
    >
      {children}
    </span>
  );
}
