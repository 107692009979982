import React from 'react';

import styles from './styles.module.css';

import { ImageLinkList } from '@/modules/meganav/types';
import { FeaturedImage } from '@/components/MegaNav/FeatureImage';
import { isNotNull } from '@/modules/cms/helpers/isNotNull';

export function PopularTiles({ itemsCollection: { items } }: ImageLinkList) {
  return (
    <div className={styles.popularTilesContainer}>
      {items.filter(isNotNull).map((item) => (
        <FeaturedImage key={item.label} {...item} />
      ))}
    </div>
  );
}
