import { SearchListProduct } from '../product/types';

import { Language } from '@/modules/language/constants';

export type SearchSuggestion = {
  categoryId?: number;
  suggestedFilterLabel?: string;
  path?: string;
  value: string;
};

export type SearchSuggestionResponse = SearchSuggestion[];

export enum SearchSuggestionType {
  POPULAR = 'popular',
  RECENT = 'recent',
  POPULAR_WITH_CATEGORY = 'popularWithCategory',
  POPULAR_WITH_BRAND = 'popularWithBrand',
  NONE = 'none',
  TRENDING = 'trending',
}

export type ActiveCategoryId = number | null;
export type ActiveSubcategoryIds = number[];
export type ActiveBrandIds = number[];
export type ActiveSizeIds = string[];
export type IsOnSale = boolean;
export type ActiveColours = string[];
export type ActiveConditions = string[];
export type ActivePrice = {
  min: number | null;
  max: number | null;
};

export type ActiveFilterValues = {
  activeCategoryId: ActiveCategoryId;
  activeSubcategoryIds: ActiveSubcategoryIds;
  activeBrandIds: ActiveBrandIds;
  activeSizeIds: ActiveSizeIds;
  activePrice: ActivePrice;
  activeColours: ActiveColours;
  activeConditions: ActiveConditions;
  isOnSale: IsOnSale;
  sortBy: TSortByValues;
};

export type URLFilterParams = {
  categories?: string;
  subcategories?: string;
  sizes?: string;
  brands?: string;
  isDiscounted?: string;
  priceMin?: string;
  priceMax?: string;
  colours?: string;
  conditions?: string;
  brandSlug?: string;
  categoryPath?: string[];
  sort?: TSortByValues;
};

export type UrlParams = {
  searchParams: URLSearchParams;
  pathname: string;
};

// Sort options used on shop
export enum ShopSortByValues {
  Product = 'product',
  ProductPriceDesc = 'product_price_desc',
  ProductPriceAsc = 'product_price_asc',
  Recent = 'recent',
  SellersRecommendations = 'sellers_recommendations', // This is not a domain concept but instead an app-level type
}

// Sort options used on browse pages
export enum SearchSortByValues {
  Relevance = 'relevance',
  PriceLowToHigh = 'priceAscending',
  PriceHighToLow = 'priceDescending',
  MostPopular = 'popular',
  NewlyListed = 'newlyListed',
}

// Runtime object which we can use to access the enum values e.g. SortByValues.Relevance
export const SortByValues = {
  ...ShopSortByValues,
  ...SearchSortByValues,
} as const;

// Type alias for the enum values which we can use for type-safety e.g. { sort: SortByValues }
export type TSortByValues = (typeof SortByValues)[keyof typeof SortByValues];

export type DepopRequestMeta = {
  sessionId?: string;
};

export type SearchParamsReq = {
  what?: string;
  country?: string;
  currency?: string;
  excludedCountry?: string;
  brands?: string;
  categories?: string;
  sizes?: string;
  colours?: string;
  conditions?: string;
  priceMin?: number;
  priceMax?: number;
  isDiscounted?: boolean;
  getSellerAndReviewData?: string;
  boostedSpacing?: string;
};

export type FetchProductsRequestParams = SearchParamsReq & {
  itemsPerPage?: number;
  cursor?: string;
  userId?: number;
  limit?: string | number;
  offset_id?: string;
  sort?: TSortByValues;
  isDiscounted: boolean;
};

export type FetchProductsRequest = {
  params: FetchProductsRequestParams;
  meta: DepopRequestMeta & {
    searchId?: string;
    isNoResultsFallback?: boolean;
  };
  language: Language;
};

export type FetchFilterAggregatesRequest = {
  params: SearchParamsReq;
  meta: DepopRequestMeta;
};

export type Meta = {
  result_count: number;
  cursor?: string;
  has_more: boolean;
  total_count?: number;
};

type Aggregate = { count: number };
export type AggregateMap = Record<string, Aggregate>;

export type CategoryAggregates = Record<
  string,
  Aggregate & {
    variants: AggregateMap;
  }
>;
type SizeAggregates = Record<string, number>;

export type FetchFilterAggregatesResponse = {
  total: number;
  categories: CategoryAggregates;
  brands: AggregateMap;
  colours: AggregateMap;
  conditions: AggregateMap;
  sizes: SizeAggregates;
};

export type ProductAttribute = {
  id: string;
  status: string;
};

export type ConditionAttribute = ProductAttribute & {
  nameI18N: string;
  descriptionI18N: string;
};

export type ColourAttribute = ProductAttribute & {
  nameI18N: string;
  hexCode: string;
};

export type StyleOption = ProductAttribute & {
  name: string;
};

export type StyleAttribute = {
  nameI18N: string;
  descriptionI18N: string;
  status: string;
  maxSelection: number;
  options: StyleOption[];
};

export type StyleAttributeById = StyleAttribute & {
  optionsById: Record<string, StyleOption>;
};

export type FetchProductAttributesResponse = {
  settings: {
    maxColours: number;
  };
  condition: ConditionAttribute[];
  colour: ColourAttribute[];
  style: StyleAttribute;
  age: StyleAttribute;
  source: StyleAttribute;
};

export type FetchFilterProductAttributesResponse = {
  condition: ConditionAttribute[];
  colour: ColourAttribute[];
};

export type FetchProductsResponse = {
  meta: Meta;
  products: SearchListProduct[];
};

export type BrandCategoryPillAttributes = {
  label: string;
  href: string;
};

export enum SearchFrom {
  InCountrySearch = 'in_country_search',
  RecentSearch = 'RecentSearch',
}
