import React from 'react';
import { useTranslations } from 'next-intl';
import { H2 } from '@depop/web-ui-kit/Typography/H2';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { ArrowBackIcon } from '@depop/web-ui-kit/Icons/ArrowBackIcon';
import { CancelIcon } from '@depop/web-ui-kit/Icons/CancelIcon';
import { datadogRum } from '@datadog/browser-rum';

import { CategoryList } from './CategoryList';
import { PopularTiles } from './PopularTiles';
import styles from './styles.module.css';

import {
  CategoryList as MeganavCategoryList,
  ImageLinkList,
  MegaNavContentType,
  MegaNavItem,
} from '@/modules/meganav/types';
import { isNotNull } from '@/modules/cms/helpers/isNotNull';

type Props = {
  isOpen: boolean;
  closeMenu: () => void;
  items: MegaNavItem['contentCollection']['items'];
  onBackClick: () => void;
  title: string;
};

export function SubMenu({
  isOpen,
  closeMenu,
  items,
  onBackClick,
  title,
}: Props) {
  const t = useTranslations('common');
  const tabIndex = isOpen ? 0 : -1;

  function renderContent(item: MeganavCategoryList | ImageLinkList) {
    switch (item.__typename) {
      case MegaNavContentType.CategoryList:
        return <CategoryList isOpen={isOpen} {...item} />;
      case MegaNavContentType.ImageLinkList:
        return <PopularTiles {...item} />;
      default:
        return null;
    }
  }

  try {
    return (
      <div
        className={isOpen ? styles['container--open'] : styles.container}
        tabIndex={tabIndex}
      >
        <div className={styles.header}>
          <div className={styles.actionButtons}>
            <Text
              as="button"
              type="button"
              className={styles.actionButton}
              onClick={onBackClick}
              tabIndex={tabIndex}
              aria-label={t('Back')}
            >
              <ArrowBackIcon />
            </Text>
            <Text
              as="button"
              type="button"
              className={styles.actionButton}
              onClick={closeMenu}
              tabIndex={tabIndex}
              aria-label={t('Close')}
            >
              <CancelIcon />
            </Text>
          </div>
          <H2>{title}</H2>
        </div>
        {items.filter(isNotNull).map((item) => (
          <React.Fragment key={JSON.stringify(item)}>
            {renderContent(item)}
          </React.Fragment>
        ))}
      </div>
    );
  } catch (error) {
    // log error to Datadog
    const renderError = new Error((error as Error).message);
    renderError.name = 'NavigationV2_RENDER_ERROR';
    renderError.cause = error;
    datadogRum.addError(renderError);
    return null;
  }
}
