import React from 'react';

import { NavigationListItem } from '../NavigationListItem';

import { SearchBarModal } from '@/components/SearchBar/SearchBarModal';

export function NavigationMobileSearchBarButton() {
  return (
    <NavigationListItem displayBreakpoint="mdDown">
      <SearchBarModal />
    </NavigationListItem>
  );
}
