import {
  COOKIE_TYPE,
  TRACKING_PROVIDER_ID,
} from '@depop/web-ui-kit/CookieBanner';

import { TrackingProvider } from './types';
import { hasConsent, setConsent, removeTrackingCookies } from './cookies';

export const OVERRIDE_PIXEL_ID = 'donotuse';

export type FacebookProviderOptions = {
  pixelId: string;
};

type Window = {
  fbq: (a: string, b: string) => void;
};

type FBInterface = {
  fbq?: () => void;
  _fbq?: () => void;
  queue?: unknown[];
};

export class Facebook implements TrackingProvider {
  public id = TRACKING_PROVIDER_ID.FACEBOOK;
  public types = [COOKIE_TYPE.SOCIAL];
  private pixelId: string;
  public hasInitialised = false;

  private cookies = {
    [COOKIE_TYPE.SOCIAL]: ['_fbp'],
  };

  constructor(options: FacebookProviderOptions) {
    this.pixelId = options.pixelId;
  }

  public onPageLoad = () => {
    const shouldInit = hasConsent(this.id, this.types[0]);
    /*
    DS 19/12/2023: ATM we do not want to initialise the Facebook pixel
    because martech are doing it with Segment. We are keeping this code
    so we can restore if need be. Meantime, this sets the cookie which
    they will use to work out whether to initialise the pixel or not based
    on user consent.
    */
    if (shouldInit && this.pixelId !== OVERRIDE_PIXEL_ID) {
      this.init();
    }
  };
  public onConsentAccepted = (type: COOKIE_TYPE) => {
    setConsent(this.id, type, true);
    /*
    DS 19/12/2023: ATM we do not want to initialise the Facebook pixel
    because martech are doing it with Segment. We are keeping this code
    so we can restore if need be. Meantime, this sets the cookie which
    they will use to work out whether to initialise the pixel or not based
    on user consent.
    */
    if (!this.hasInitialised && this.pixelId !== OVERRIDE_PIXEL_ID) {
      this.init();
    }
    // May not have initialised still if "do not track" is enabled
    if (this.hasInitialised) {
      (window as unknown as Window).fbq('consent', 'grant');
    }
  };
  public onConsentRejected = (type: COOKIE_TYPE) => {
    setConsent(this.id, type, false);
    if (this.hasInitialised) {
      (window as unknown as Window).fbq('consent', 'revoke');
    }
    // @todo: Is there a way to not have to explicitly check the type here?
    if (type === COOKIE_TYPE.SOCIAL) {
      removeTrackingCookies(this.cookies[type]);
    }
  };

  private init = () => {
    if (navigator.doNotTrack !== '1') {
      (function (
        f: FBInterface,
        b,
        e,
        v,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        n: any = {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        t: any = {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        s: any = {}
      ) {
        /* eslint-disable */
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments as any)
            : n.queue?.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
        /* eslint-enable */
      })(
        window as FBInterface,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
      (window as unknown as Window).fbq('init', this.pixelId);
      (window as unknown as Window).fbq('track', 'PageView');
      this.hasInitialised = true;
    }
  };
}
