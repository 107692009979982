import React from 'react';
import { useTranslations } from 'next-intl';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useQueryClient } from '@tanstack/react-query';

import styles from './styles.module.css';

import { InBagsNudge } from '@/components/InBagNudge';
import { BagDetailedProduct } from '@/modules/bag/types';
import { getEstimatedShippingPrice } from '@/modules/bag/helpers';
import {
  filterRequiredPictures,
  getNearestSizePictureFormat,
} from '@/modules/pictures/helpers';
import { ExtendedLink } from '@/components/ExtendedLink';
import { LocaleCurrencyFormat } from '@/components/LocaleCurrencyFormat';
import { BagDeleteProductButton } from '@/components/Bag/BagDeleteProductButton';
import { Price } from '@/components/Price';
import { useProductNudgeByType } from '@/modules/nudges/hooks/useProductNudgeByType';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { RQ_X_COUNTRY_CODE } from '@/modules/ReactQuery/cacheKeys';

type Props = {
  product: BagDetailedProduct;
  sellerId: number;
  className?: string;
  showEstimatedShipping?: boolean;
  showInBagNudge?: boolean;
};

function BagProduct({
  product,
  sellerId,
  className = '',
  showEstimatedShipping = false,
}: Props) {
  const queryClient = useQueryClient();
  const [, currentUser] = useCurrentUser();
  const buyerCountry = currentUser
    ? currentUser.country
    : queryClient.getQueryData<string>([RQ_X_COUNTRY_CODE]);
  const t = useTranslations('bag');
  const estimatedShippingCost = getEstimatedShippingPrice({
    sellerLocation: product.country_code,
    buyerLocation: buyerCountry,
    nationalShippingCost: product.pricing.national_shipping_cost?.total_price,
    internationalShippingCost:
      product.pricing.international_shipping_cost?.total_price,
  });
  const { nudge: inBagsNudge } = useProductNudgeByType(
    product.product_id,
    'in_bags'
  );

  // @TODO this fixes an issue where a video only product ends up with a broken image
  // for a tumbnail. Longer term we should return the video array for the product
  // and then we can grab the video thumbnail if there are no images.
  // This only came up because mobile are getting ready to roll out the bag. It's not
  // possible to have a video only product on the web.
  const imgUrl = getNearestSizePictureFormat(
    filterRequiredPictures(product.pictures?.[0] || []),
    120
  )?.url;
  return (
    <div className={`${className} ${styles.container}`}>
      {imgUrl && (
        <ExtendedLink href={`/products/${product.slug}`}>
          <img
            className={styles.productImage}
            src={imgUrl}
            alt={product.description}
          />
        </ExtendedLink>
      )}
      {!imgUrl && (
        <ExtendedLink href={`/products/${product.slug}`}>
          <div
            title={product.description}
            className={(styles.productImage, styles.productPlaceholder)}
          />
        </ExtendedLink>
      )}
      <div className={styles.productDetails}>
        <InBagsNudge
          className={styles.nudges}
          count={inBagsNudge?.data?.count}
        />
        <Text className={styles.description}>{product.description}</Text>
        <Price
          className={styles.text}
          inPreview
          currencyCode={product.pricing.currency_name}
          price={product.pricing.original_price.total_price}
          discountedPrice={
            product.pricing.is_reduced
              ? product.pricing[product.pricing.final_price_key]?.total_price
              : undefined
          }
        />
        {showEstimatedShipping && estimatedShippingCost && (
          <Text className={styles.shippingPriceCaption}>
            {t.rich('Bag.QuickAccess.Shipping', {
              localeCurrency: () => (
                <LocaleCurrencyFormat
                  currencyCode={product.pricing.currency_name}
                  numberToFormat={estimatedShippingCost}
                />
              ),
            })}
          </Text>
        )}
        {product.size && (
          <Text className={styles.text} type="caption1">
            {product.size}
          </Text>
        )}
        <BagDeleteProductButton product={product} sellerId={sellerId} />
      </div>
    </div>
  );
}

export { BagProduct };
