'use client';

import React from 'react';
import { IconDepopIcon } from '@depop/web-ui-kit/Icons/IconDepopIcon';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';

import styles from './styles.module.css';

import { Navigation } from '@/components/Navigation/';
import { SearchBarDesktop } from '@/components/SearchBar/SearchBarDesktop';
import { ExtendedLink } from '@/components/ExtendedLink';
import { MobileNavigation } from '@/components/MobileNavigation';
import { MegaNav } from '@/components/MegaNav';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import {
  ActivityTrackerEventType,
  NavCtaActionTypes,
} from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useHideHeader } from '@/modules/sharedLayout/useHideHeader';
import { useHideFooter } from '@/modules/sharedLayout/useHideFooter';
import { useHideMegaNav } from '@/modules/sharedLayout/useHideMegaNav';
import { useShowHomepageSearchBar } from '@/modules/home/hooks/useShowHomepageSearchBar';

export function Header({ isInAppView }: { isInAppView?: boolean }) {
  const t = useTranslations('common');
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const pathname = usePathname();
  const isHeaderHidden = useHideHeader(Boolean(isInAppView));
  const isFooterHidden = useHideFooter(Boolean(isInAppView));
  const isMegaNavHidden = useHideMegaNav(Boolean(isInAppView));
  const isHomepageSearchBarVisible = useShowHomepageSearchBar();

  const hideBorderBottom =
    isHomepageSearchBarVisible ||
    Boolean(pathname.match(/^\/(?:([a-z]{2})\/)?sell/));

  const SKIP_LINKS = [
    { href: '#main', text: t('SkipToContent.main'), hideOnMobile: false },
    ...(isFooterHidden
      ? []
      : [
          {
            href: '#footer',
            text: t('SkipToContent.footer'),
            hideOnMobile: true,
          },
        ]),
  ];

  function handleLogoClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CTA_ACTION, {
      type: NavCtaActionTypes.DEPOP_LOGO_CTA,
    });
  }

  if (isHeaderHidden) {
    return null;
  }

  return (
    <header className={styles.headerWrapper} role="banner" id="globalHeader">
      <div
        className={clsx(styles.headerContainer, {
          [styles['headerContainer--noBorderBottom']]: hideBorderBottom,
        })}
        data-testid="header"
      >
        <MobileNavigation />
        <div className={styles.skipLinkContainer}>
          {SKIP_LINKS.map(({ href, text, hideOnMobile }) => (
            <a
              className={clsx(styles.skipToLink, {
                [styles.hideSkipLink]: hideOnMobile,
              })}
              href={href}
              key={href}
            >
              {text}
            </a>
          ))}
        </div>
        <ExtendedLink
          href="/"
          onClick={handleLogoClick}
          aria-label="Depop Logo"
        >
          <IconDepopIcon className={styles.logo} />
        </ExtendedLink>
        <SearchBarDesktop />
        <Navigation />
      </div>
      {!isMegaNavHidden && <MegaNav />}
    </header>
  );
}
