'use client';

import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { Badge } from '@depop/web-ui-kit/Badge';
import { BagIcon } from '@depop/web-ui-kit/Icons/BagIcon';

import { NavigationListItem } from '../NavigationListItem';
import { NavigationLink } from '../NavigationLink';

import styles from './styles.module.css';

import { useBagBasic } from '@/modules/bag/useBagBasic';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useBagTracking } from '@/modules/bag/useBagTracking';
import { QuickAccessBagPopover } from '@/components/Bag/QuickAccessBagPopover';

export function NavigationQuickAccessBag() {
  const t = useTranslations('common');
  const pathname = usePathname();
  const { query: bagQuery } = useBagBasic();
  const [quickAccessBagAnchor, setQuickAccessBagAnchor] =
    useState<Element | null>(null);
  const [quickAccessBagTrigger, setQuickAccessBagTrigger] = useState(false);
  const [authenticated] = useCurrentUser();

  const bagTracking = useBagTracking();

  const isActive = pathname?.endsWith(`/bag/`);
  const count = bagQuery.data?.count || 0;

  function handleUnauthBagClick() {
    bagTracking.sendBagViewAction('nav');
  }

  function handleAuthBagClick() {
    bagTracking.sendBagViewAction('nav');
  }

  function handleBagNavClick() {
    if (authenticated) {
      handleAuthBagClick();
    } else {
      handleUnauthBagClick();
    }
  }

  function handleBagNavKeyPress(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      handleBagNavClick();
    }
  }

  return (
    <>
      <NavigationListItem>
        <div
          ref={setQuickAccessBagAnchor}
          onMouseEnter={() => setQuickAccessBagTrigger(true)}
          onMouseLeave={() => setQuickAccessBagTrigger(false)}
          onFocus={() => setQuickAccessBagTrigger(true)}
          onBlur={() => setQuickAccessBagTrigger(false)}
          onClick={handleBagNavClick}
          onKeyDown={handleBagNavKeyPress}
          role="button"
          tabIndex={0}
        >
          <NavigationLink
            aria-label={t('Nav.Bag', { count })}
            isActive={isActive}
            as="/bag"
            href="/bag"
            rel="nofollow"
          >
            <>
              <BagIcon
                size={28}
                className={styles.bagIcon}
                title={t('Nav.Bag', { count })}
              />
              {Boolean(count) && (
                <Badge className={styles.badgeIcon} count={Number(count)} />
              )}
            </>
          </NavigationLink>
        </div>
      </NavigationListItem>
      <QuickAccessBagPopover
        anchor={quickAccessBagAnchor}
        anchorTrigger={quickAccessBagTrigger}
        mainAxisOffset={10}
      />
    </>
  );
}
