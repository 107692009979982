import React from 'react';
import { useTranslations } from 'next-intl';
import { Button } from '@depop/web-ui-kit/Button';

import {
  BagDetailedProduct,
  BagDetailedSellerInfo,
  BagTrackingTypes,
} from '@/modules/bag/types';
import { addCheckoutProducts } from '@/modules/checkout/helpers';
import { ExtendedLink } from '@/components/ExtendedLink';
import { CheckoutType, CheckoutProduct } from '@/modules/checkout/types';
import { transformBagProductToCheckoutProduct } from '@/modules/bag/helpers';
import { useBagTracking } from '@/modules/bag/useBagTracking';

type Props = {
  products: BagDetailedProduct[];
  seller: BagDetailedSellerInfo;
  trackingType: BagTrackingTypes;
} & React.ComponentProps<typeof Button>;

function BagCheckoutButton({ products, seller, trackingType, ...rest }: Props) {
  const t = useTranslations('bag');
  const bagTracking = useBagTracking();

  function handleClick() {
    const transformedProducts: Array<CheckoutProduct> = [];
    products.forEach((product) => {
      const quantity = product.quantity || 1;
      for (let i = 0; i < quantity; i++) {
        transformedProducts.push(
          transformBagProductToCheckoutProduct(product, seller)
        );
      }
    });
    addCheckoutProducts({
      type: CheckoutType.Bag,
      products: transformedProducts,
    });

    bagTracking.sendBagCheckoutAction(trackingType);
  }

  return (
    <ExtendedLink href="/pay/" as="/pay/">
      <Button block {...rest} onClick={handleClick}>
        {t('Bag.Summary.CheckoutButton', { count: products.length })}
      </Button>
    </ExtendedLink>
  );
}

export { BagCheckoutButton };
