'use client';

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { datadogRum } from '@datadog/browser-rum';

import { PopularTiles } from './Desktop/PopularTiles';
import { NavigationCategoryList } from './NavigationCategoryList';
import styles from './styles.module.css';

import {
  CategoryList,
  ImageLinkList,
  MegaNavContentType,
} from '@/modules/meganav/types';
import { ROUTE_CHANGE_START_EVENT } from '@/modules/routing/constants';
import { useNavConfig } from '@/modules/meganav/useNavConfig';

type AriaExpandedState = Record<string, boolean>;

let hoverTimer: number;

export function MegaNav() {
  const config = useNavConfig();

  const ariaExpandedInitialState = config.reduce<AriaExpandedState>(
    (prev, curr) => {
      if (curr && curr.label) {
        return { ...prev, [curr?.label]: false };
      }
      return prev;
    },
    {}
  );

  const [ariaExpandedLookup, setAriaExpandedLookup] =
    useState<AriaExpandedState>(ariaExpandedInitialState);

  function renderHoverContent(list: CategoryList | ImageLinkList | null) {
    switch (list?.__typename) {
      case MegaNavContentType.CategoryList:
        return (
          <NavigationCategoryList
            config={list}
            className={clsx({
              [styles.navCategoryListWide]:
                list.itemsCollection.items.length > 8,
              [styles.navCategoryListNarrow]:
                list.itemsCollection.items.length <= 8,
            })}
          />
        );
      case MegaNavContentType.ImageLinkList:
        return <PopularTiles config={list} />;
      default:
        return null;
    }
  }

  function addAriaExpanded(label: string) {
    setAriaExpandedLookup((prev) => ({
      ...prev,
      [label]: true,
    }));
  }

  function removeAriaExpanded(label: string) {
    setAriaExpandedLookup((prev) => ({
      ...prev,
      [label]: false,
    }));
  }

  function handleMouseOver(itemLabel: string) {
    if (hoverTimer) {
      window.clearTimeout(hoverTimer);
    }

    hoverTimer = window.setTimeout(() => addAriaExpanded(itemLabel), 200);
  }

  function handleMouseLeave(itemLabel: string) {
    if (hoverTimer) {
      window.clearTimeout(hoverTimer);
    }

    removeAriaExpanded(itemLabel);
  }

  useEffect(() => {
    function handleRouteChange() {
      setAriaExpandedLookup(ariaExpandedInitialState);
    }

    document.addEventListener(ROUTE_CHANGE_START_EVENT, handleRouteChange);

    return () => {
      document.removeEventListener(ROUTE_CHANGE_START_EVENT, handleRouteChange);
    };
  }, []);

  try {
    return (
      <nav className={styles.container}>
        <ul className={styles.menuList} role="menubar">
          {config.map((item) => (
            <React.Fragment key={item.label}>
              <li
                className={styles.menuListItem}
                style={
                  item.colourOverride
                    ? { background: item.colourOverride, color: 'white' }
                    : {}
                }
                onMouseOver={() => handleMouseOver(item.label)}
                onFocus={() => handleMouseOver(item.label)}
                onMouseLeave={() => handleMouseLeave(item.label)}
              >
                <Text
                  as="button"
                  type="button"
                  style={
                    item.colourOverride
                      ? {
                          '--textColor': item.colourOverride,
                          '--hoverBg': item.colourOverride,
                        }
                      : {}
                  }
                  className={styles.menuListItemButton}
                  onClick={() =>
                    ariaExpandedLookup[item.label]
                      ? removeAriaExpanded(item.label)
                      : addAriaExpanded(item.label)
                  }
                  aria-expanded={ariaExpandedLookup[item.label]}
                >
                  {item.label}
                </Text>
                <div
                  className={clsx(styles.hoverContainer, {
                    [styles.hoverContainerActive]:
                      ariaExpandedLookup[item.label],
                  })}
                >
                  {item.contentCollection.items.map((list, index) => (
                    <React.Fragment key={index}>
                      {renderHoverContent(list)}
                    </React.Fragment>
                  ))}
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </nav>
    );
  } catch (error) {
    // log error to Datadog
    const renderError = new Error((error as Error).message);
    renderError.name = 'NavigationV2_RENDER_ERROR';
    renderError.cause = error;
    datadogRum.addError(renderError);
    return null;
  }
}
