import React from 'react';

import styles from './styles.module.css';

import { Placeholder } from '@/components/Placeholder';

function BagProductPlaceholder() {
  return (
    <div className={styles.container}>
      <Placeholder width="120px" height="120px" shape="rectangle" />
      <div className={styles.wrapper}>
        <div className={styles.placeholderWrapper}>
          <Placeholder shape="rectangle" width="100%" height="18px" />
        </div>
        <div className={styles.placeholderWrapper}>
          <Placeholder shape="rectangle" width="100%" height="36px" />
        </div>
        <Placeholder shape="rectangle" width="52px" height="18px" />
      </div>
    </div>
  );
}

export { BagProductPlaceholder };
