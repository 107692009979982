'use client';

import React from 'react';
import {
  useFloating,
  autoUpdate,
  flip,
  shift,
  useTransitionStyles,
  offset,
} from '@floating-ui/react';

import { PopoverHeader } from './PopoverHeader';
import styles from './styles.module.css';

const TRANSITION_DURATION_MS = 600;

const TRANSITION_STYLE_PROPS = {
  duration: TRANSITION_DURATION_MS,
  initial: {
    transform: 'translateY(-100%)',
  },
  common: {
    transformOrigin: 'top',
    transitionTimingFunction: 'easing',
  },
};

const NO_TRANSITION_STYLE_PROPS = {
  duration: 0,
  initial: {
    opacity: 1,
  },
};

export type CustomOverrides = {
  headerTitle?: React.ReactElement;
};

type Props = {
  children: React.ReactNode;
  title?: string;
  showCloseButton?: boolean;
  customOverrides?: CustomOverrides;
  anchor: Element | null;
  onClose?: () => void;
  withTransition?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  isOpen: boolean;
  mainAxisOffset?: number;
};
function Popover({
  children,
  title,
  showCloseButton,
  customOverrides,
  anchor,
  onClose,
  withTransition = true,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  isOpen,
  mainAxisOffset = 0,
}: Props) {
  const { refs, context, floatingStyles } = useFloating({
    elements: {
      reference: anchor,
    },
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    middleware: [flip(), shift(), offset({ mainAxis: mainAxisOffset })],
    open: isOpen,
  });
  const { styles: transitionStyles, isMounted } = useTransitionStyles(
    context,
    withTransition ? TRANSITION_STYLE_PROPS : NO_TRANSITION_STYLE_PROPS
  );

  return isMounted ? (
    <div
      className={styles.wrapper}
      ref={refs.setFloating}
      style={floatingStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <div className={styles.innerWrapper} style={transitionStyles}>
        <PopoverHeader
          title={title}
          showCloseButton={showCloseButton}
          customOverrides={customOverrides}
          onClose={onClose}
        />
        {children}
      </div>
    </div>
  ) : null;
}

export { Popover };
