import React from 'react';
import clsx from 'clsx';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { ExtendedLink } from '@/components/ExtendedLink';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { CategoryList } from '@/modules/meganav/types';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import {
  ActivityTrackerEventType,
  moduleOriginParamName,
  NavigationComponentTypes,
} from '@/modules/activityTracker/constants';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';
import { TrackingType } from '@/modules/home/types.ts';
import { isNotNull } from '@/modules/cms/helpers/isNotNull';

type Props = {
  config: CategoryList;
  className?: string;
};

export function NavigationCategoryList({ className, config }: Props) {
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });

  function sendTracking(params: {
    href: string;
    label: string;
    componentType: string;
  }) {
    sendActivityTrackerEvent(ActivityTrackerEventType.NAVBAR_CLICK_ACTION, {
      ...params,
      schemaVersion: '1.0',
    });
  }

  const {
    itemsCollection: { items = [] },
  } = config;

  return (
    <div className={clsx(styles.categoryListContainer, className)}>
      <H3 className={styles.categoryListTitle}>{config.title}</H3>
      <ul
        className={clsx({
          [styles.categoryListItemsContainer__largeList]: items.length > 8,
          [styles.categoryListItemsContainer__smallList]: items.length <= 8,
        })}
      >
        {items.filter(isNotNull).map(({ label, path, colourOverride }) => (
          <li className={styles.categoryListitem} key={label}>
            <ExtendedLink
              className={styles.categoryListLink}
              onClick={() =>
                sendTracking({
                  href: path,
                  label,
                  componentType: NavigationComponentTypes.TextLink,
                })
              }
              href={appendParams(path, [
                moduleOriginParamName,
                TrackingType.meganav,
              ])}
            >
              <Text
                className={styles.categoryListItemText}
                style={colourOverride ? { color: colourOverride } : {}}
              >
                {label}
              </Text>
            </ExtendedLink>
          </li>
        ))}
      </ul>
      {config.footer && (
        <ExtendedLink
          className={styles.categoryListLink}
          onClick={() => {
            sendTracking({
              href: config.footer?.path || '',
              label: config.footer?.label || '',
              componentType: NavigationComponentTypes.TextLinkFooter,
            });
          }}
          href={config.footer.path}
        >
          <Text className={styles.categoryListFooter} bold>
            {config.footer.label}
          </Text>
        </ExtendedLink>
      )}
    </div>
  );
}
