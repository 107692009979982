import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setCookie } from 'cookies-next';

import { LOCATION_KEY, NEXT_LOCALE_KEY } from '@/constants/cookies';

export interface LocationState {
  value: string;
}

const initialState: LocationState = {
  value: 'us',
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<string>) => {
      state.value = action.payload.toLowerCase();
      setCookie(LOCATION_KEY, state.value);
      setCookie(NEXT_LOCALE_KEY, state.value);
    },
  },
});

export const { setCountry } = locationSlice.actions;

export default locationSlice.reducer;
