import { useQuery } from '@tanstack/react-query';

import { getNudgesForProduct } from '../api.ts';

import { RQ_NUDGES_KEY } from '@/modules/ReactQuery/cacheKeys.ts';

type UseProductNudge = {
  enabled?: boolean;
  productIds: number[];
};
export function useProductNudge({
  enabled = false,
  productIds,
}: UseProductNudge) {
  const idsToString = productIds?.join(',');

  return useQuery({
    queryKey: [RQ_NUDGES_KEY, productIds],
    queryFn: async () => {
      const res = await getNudgesForProduct(idsToString);
      return res?.data;
    },
    enabled: !!idsToString && enabled,
  });
}
