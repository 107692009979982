'use client';

import React, { useRef } from 'react';
import { Avatar, AVATAR_SIZE } from '@depop/web-ui-kit/Avatar';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { useParams, useSelectedLayoutSegments } from 'next/navigation';
import { VisuallyHidden } from '@depop/web-ui-kit/VisuallyHidden';
import { ArrowDownIcon } from '@depop/web-ui-kit/Icons/ArrowDownIcon';

import styles from './styles.module.css';

import { ActionMenu } from '@/components/ActionMenu';
import {
  TextMenuItemLink,
  TextMenuItemClick,
} from '@/components/ActionMenu/ActionMenuItems';
import { NotificationIndicator } from '@/components/NotificationIndicator';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { constructDynamicRoute } from '@/modules/routing/constructDynamicRoute';
import { useUserCounter } from '@/modules/user/useUserCounter';
import { NavigationListItem } from '@/components/Navigation/NavigationListItem';
import { useAuth } from '@/modules/auth/useAuth';

export function NavigationProfileDropdown() {
  const triggerRef = useRef(null);
  const t = useTranslations('common');
  const { logout } = useAuth();

  const params = useParams();
  const segments = useSelectedLayoutSegments();
  const viewName = constructDynamicRoute(params, segments);

  const isProfilePage = viewName === '/[username]';

  const [_, currentUser] = useCurrentUser();
  const { data: userCountersData } = useUserCounter();
  const hasOffers =
    userCountersData && userCountersData.seller_offers_to_review > 0;

  const sellerHubLink = {
    key: 'nav-sell-sellingHub',
    translation: hasOffers
      ? t('Nav.YourSellingHubWithOffers', {
          count: userCountersData.seller_offers_to_review,
        })
      : t('Nav.YourSellingHubWithoutOffers'),
    link: {
      href: '/sellinghub',
    },
  };

  const navProfileLink = {
    key: 'nav-profileLink',
    translation: t('Nav.Profile'),
    link: {
      href: '/[username]',
      as: `/${currentUser?.username}`,
    },
  };

  const navReceiptsLink = {
    key: 'nav-buyersReceiptsLink',
    translation: t('Nav.BuyersReceipts'),
    link: {
      href: '/purchases/',
    },
  };

  const navDrc = {
    key: 'nav-drc',
    translation: t('Nav.Drc'),
    link: {
      href: '/disputes/',
    },
  };

  const navLogout = {
    key: 'nav-logout',
    translation: t('Nav.Logout'),
    onClick: logout,
  };

  const navSettings = {
    key: 'nav-settings',
    translation: t('Nav.Settings'),
    link: {
      href: '/settings/',
    },
  };

  const actionMenuItems: Array<TextMenuItemLink | TextMenuItemClick> = [
    navProfileLink,
    sellerHubLink,
    navReceiptsLink,
    navSettings,
    navDrc,
    navLogout,
  ];

  return (
    <NavigationListItem className={styles.navItem}>
      <span ref={triggerRef}>
        <ActionMenu
          desktopOnly
          actionMenuId="userNavItem"
          elementRef={triggerRef}
          className={styles.actionMenu}
          trigger={({ toggleActionMenu, showActionMenu }) => (
            <button
              className={clsx(styles.wrapper, { isActive: isProfilePage })}
              aria-haspopup={true}
              onClick={toggleActionMenu}
              data-testid="userNavItem-wrapper"
            >
              <div
                className={clsx(styles.avatarWrapper, {
                  isActive: isProfilePage,
                  isOpen: showActionMenu,
                })}
              >
                <VisuallyHidden>User Menu</VisuallyHidden>
                <Avatar
                  src={currentUser?.pictureUrl}
                  initials={currentUser?.initials as string}
                  verified={currentUser?.verified}
                  size={AVATAR_SIZE.EXTRA_SMALL}
                />
                {hasOffers && (
                  <NotificationIndicator
                    description={t('Nav.NewOffers', {
                      count: userCountersData.seller_offers_to_review,
                    })}
                    data-testid="navigation__profile--unread-offers"
                  />
                )}
              </div>
              <ArrowDownIcon
                className={styles.dropdownArrow}
                strokeWidth={1.5}
              />
            </button>
          )}
          menuItems={actionMenuItems}
          menuItemType="block"
        />
      </span>
    </NavigationListItem>
  );
}
