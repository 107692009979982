import React, { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useTranslations } from 'next-intl';
import { usePathname, useSearchParams } from 'next/navigation';
import { Button } from '@depop/web-ui-kit/Button';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Avatar, AVATAR_SIZE } from '@depop/web-ui-kit/Avatar';
import { IconDepopIcon } from '@depop/web-ui-kit/Icons/IconDepopIcon';
import { CancelIcon } from '@depop/web-ui-kit/Icons/CancelIcon';
import { ArrowForwardIcon } from '@depop/web-ui-kit/Icons/ArrowForwardIcon';
import { InstagramIcon } from '@depop/web-ui-kit/Icons/InstagramIcon';
import { TwitterIcon } from '@depop/web-ui-kit/Icons/TwitterIcon';
import { FacebookIcon } from '@depop/web-ui-kit/Icons/FacebookIcon';
import { TiktokIcon } from '@depop/web-ui-kit/Icons/TiktokIcon';
import clsx from 'clsx';

import styles from './styles.module.css';

import { ROUTE_CHANGE_START_EVENT } from '@/modules/routing/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useSellNowHref } from '@/modules/signup/hooks/useSellNowHref';
import { useSignup } from '@/modules/signup/hooks/useSignup';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useNavConfig } from '@/modules/meganav/useNavConfig';
import {
  ActivityTrackerEventType,
  NavCtaActionTypes,
} from '@/modules/activityTracker/constants';
import { useUserCounter } from '@/modules/user/useUserCounter';
import { getLoginRedirectParams } from '@/modules/login/helpers';
import { useUserAgent } from '@/modules/device/useUserAgent';
import { useBranchDeepLink } from '@/modules/externalTracking/useBranchDeepLink';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage';
import { fallbackLinks } from '@/modules/appStoreLinks/constants';
import { ExtendedLink } from '@/components/ExtendedLink';
import { SubMenu } from '@/components/MobileNavigation/SubMenu';
import { SelectLocationWithInlineLabel } from '@/components/SelectLocation';
import { SOCIAL_MEDIA_SITES } from '@/constants/externalUrls';
import { DeviceType } from '@/modules/device/types';

export type MobileMenuProps = {
  onClose: () => void;
  handleLogout: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
  secondaryNav: React.ReactNode;
};

export function MobileNavigationMenu({
  isOpen,
  onClose,
  secondaryNav,
  handleLogout,
}: MobileMenuProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const firstButtonElement = useRef<HTMLButtonElement>(null);

  const enableMobileMenu = isOpen;

  const t = useTranslations('common');
  const [authenticated, currentUser] = useCurrentUser();
  const { url: signupUrl } = useSignup();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { device } = useUserAgent();
  const { language } = useCurrentLanguage();
  const { href } = useSellNowHref();

  const appleDeepLink = useBranchDeepLink({
    fallback: fallbackLinks[language].AppStore,
    appPath: '/',
    stage: 'app store link',
  });
  const androidDeepLink = useBranchDeepLink({
    fallback: fallbackLinks[language].PlayStore,
    appPath: '/',
    stage: 'play store link',
  });

  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const currentPath = searchParams.size
    ? `${pathname}?${searchParams}`
    : pathname;

  const config = useNavConfig();
  const [activeSubMenu, setActiveSubMenu] = useState('');

  const { data: userCounters } = useUserCounter();

  const offersCount = userCounters?.seller_offers_to_review || 0;

  function handleSignupClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CTA_ACTION, {
      type: NavCtaActionTypes.NAV_SIGNUP_CTA,
    });
  }

  function handleLoginClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CTA_ACTION, {
      type: NavCtaActionTypes.NAV_LOGIN_CTA,
    });
  }

  function handleSubMenuBackClick() {
    setActiveSubMenu('');
  }

  function handleListItemClick(label: string) {
    setActiveSubMenu(label);
  }

  function handleLogoClick() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CTA_ACTION, {
      type: NavCtaActionTypes.DEPOP_LOGO_CTA,
    });
    onClose();
  }

  useEffect(() => {
    if (menuRef && menuRef.current) {
      if (enableMobileMenu) {
        menuRef.current.classList.remove(
          styles['mobileMenuWrapper--slideOut'],
          styles['mobileMenuWrapper--hidden']
        );
        menuRef.current.classList.add(styles['mobileMenuWrapper--slideIn']);
      } else {
        menuRef.current.classList.remove(styles['mobileMenuWrapper--slideIn']);
        menuRef.current.classList.add(styles['mobileMenuWrapper--slideOut']);
        /*
          We need to hide the menu or else it interferes with some elements in the page.
          For example, it becomes impossible to click on the text inputs on the login screen.
          The transition time has been set to 250ms in the styles, so a 300ms timeout for hiding
          allows us time to complete the transition. Otherwise, leaving the menu visible conflicts
          with some of the focus code and messes up tabbing.
        */
        setTimeout(() => {
          if (menuRef && menuRef.current) {
            menuRef.current.classList.add(styles['mobileMenuWrapper--hidden']);
          }
        }, 300);
      }
    }
  });

  useEffect(() => {
    document.addEventListener(ROUTE_CHANGE_START_EVENT, onClose);

    return () => {
      document.removeEventListener(ROUTE_CHANGE_START_EVENT, onClose);
    };
  }, []);

  useClickAway(menuRef, () => {
    if (isOpen) {
      onClose();
    }
  });

  function renderGetTheAppDeeplink() {
    if (authenticated) {
      return null;
    }
    if (device === DeviceType.ANDROID) {
      return (
        <ExtendedLink href={androidDeepLink}>
          <Text className={styles.getTheAppLink}>{t('Nav.Download')}</Text>
        </ExtendedLink>
      );
    }
    return (
      <ExtendedLink href={appleDeepLink}>
        <Text className={styles.getTheAppLink}>{t('Nav.Download')}</Text>
      </ExtendedLink>
    );
  }

  return (
    <div className={styles.mobileMenuWrapper} role="dialog" ref={menuRef}>
      {isOpen && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              <ExtendedLink
                href="/"
                onClick={handleLogoClick}
                aria-label="Depop Logo"
              >
                <IconDepopIcon className={styles.depopLogo} />
              </ExtendedLink>
              <button
                className={styles.closeButton}
                aria-label={t('Close')}
                onClick={onClose}
                ref={firstButtonElement}
              >
                <CancelIcon />
              </button>
            </div>
            {!authenticated && (
              <div className={styles.authButtons}>
                <ExtendedLink href={href}>
                  <Button onClick={handleSignupClick} block>
                    {t('Nav.SellNow')}
                  </Button>
                </ExtendedLink>
                <ExtendedLink href={signupUrl}>
                  <Button onClick={handleSignupClick} block outline>
                    {t('Nav.SignUp')}
                  </Button>
                </ExtendedLink>
                <ExtendedLink
                  href={`/login/${getLoginRedirectParams(
                    searchParams.get('redirect') || currentPath
                  )}`}
                >
                  <Button onClick={handleLoginClick} block outline>
                    {t('Nav.Login')}
                  </Button>
                </ExtendedLink>
              </div>
            )}
          </div>
          <ul
            className={clsx(styles.primaryNav, {
              [styles['primaryNav--showBorderTop']]: !authenticated,
            })}
          >
            {config.map((item) => (
              <li key={item.label} className={styles.navListItem}>
                <Text
                  as="button"
                  className={styles.navListItemButton}
                  type="button"
                  aria-haspopup="true"
                  aria-expanded={
                    item.label === activeSubMenu ? 'true' : 'false'
                  }
                  onClick={() => handleListItemClick(item.label)}
                >
                  <Text
                    className={styles.navListItemLabel}
                    as="span"
                    type="button"
                    bold
                    style={
                      item.colourOverride
                        ? {
                            '--textColor': item.colourOverride,
                          }
                        : {}
                    }
                  >
                    {item.label}
                  </Text>
                  <ArrowForwardIcon />
                </Text>
                <SubMenu
                  closeMenu={onClose}
                  onBackClick={handleSubMenuBackClick}
                  isOpen={item.label === activeSubMenu}
                  items={item.contentCollection.items}
                  title={item.label}
                />
              </li>
            ))}
          </ul>
          {authenticated && (
            <ul className={styles.authenticatedNav}>
              <li key="mobile-authed-profile" className={styles.navListItem}>
                <ExtendedLink
                  href="/[username]/"
                  as={`/${currentUser?.username}/`}
                >
                  <Text as="span">{t('Nav.Profile')}</Text>
                  <Avatar
                    src={currentUser?.pictureUrl}
                    initials={currentUser?.initials || ''}
                    verified={currentUser?.verified}
                    size={AVATAR_SIZE.EXTRA_SMALL}
                  />
                </ExtendedLink>
              </li>
              <li key="mobile-authed-sell" className={styles.navListItem}>
                <ExtendedLink href={href}>
                  <Text as="span">{t('Nav.Sell')}</Text>
                </ExtendedLink>
              </li>
              <li
                key="mobile-authed-selling-hub"
                className={styles.navListItem}
              >
                <ExtendedLink href="/sellinghub/" as="/sellinghub/">
                  <Text as="span">{t('Nav.YourSellingHubWithoutOffers')}</Text>
                  {!!offersCount && (
                    <div className={styles.offerAlertContainer}>
                      <span
                        className={styles.offerAlertNotificationContainer}
                      />
                      <Text className={styles.offerAlertText}>
                        {t('Nav.NewOffers', {
                          count: offersCount,
                        })}
                      </Text>
                    </div>
                  )}
                </ExtendedLink>
              </li>
              <li key="mobile-authed-purchases" className={styles.navListItem}>
                <ExtendedLink href="/purchases/">
                  <Text as="span">{t('Nav.BuyersReceipts')}</Text>
                </ExtendedLink>
              </li>
              <li key="mobile-authed-settings" className={styles.navListItem}>
                <ExtendedLink href="/settings/">
                  <Text as="span">{t('Nav.Settings')}</Text>
                </ExtendedLink>
              </li>
              <li key="mobile-authed-disputes" className={styles.navListItem}>
                <ExtendedLink href="/disputes/">
                  <Text as="span">{t('Nav.Drc')}</Text>
                </ExtendedLink>
              </li>
            </ul>
          )}
          <div className={styles.secondaryNavWrapper}>
            {secondaryNav}
            {renderGetTheAppDeeplink()}
          </div>
          <div className={styles.footer}>
            <div className={styles.socialLinks}>
              <a
                href={SOCIAL_MEDIA_SITES.INSTAGRAM}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.socialLink}
              >
                <InstagramIcon color="#262626" />
              </a>
              <a
                href={SOCIAL_MEDIA_SITES.TWITTER}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.socialLink}
              >
                <TwitterIcon color="#262626" />
              </a>
              <a
                href={SOCIAL_MEDIA_SITES.FACEBOOK}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.socialLink}
              >
                <FacebookIcon color="#262626" />
              </a>
              <a
                href={SOCIAL_MEDIA_SITES.TIKTOK}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.socialLink}
              >
                <TiktokIcon title="tiktok" />
              </a>
            </div>
            <div className={styles.selectWrapper}>
              <SelectLocationWithInlineLabel label={t('Nav.SelectLocation')} />
            </div>
            {authenticated && (
              <Text
                as="button"
                onClick={handleLogout}
                className={styles.logoutButton}
              >
                <H3>{t('Nav.Logout')}</H3>
              </Text>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
