'use client';
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { SelectWithInlineLabel } from '@depop/web-ui-kit/SelectWithInlineLabel';
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { useExtendedRouter } from '@/modules/routing/useExtendedRouter';
import { Select, CustomStyles } from '@/components/Select';
import { setCountry } from '@/modules/redux/slices/locationSlice';
import { locations } from '@/modules/location/constants';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker.ts';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants.ts';
import { useCurrentLanguage } from '@/modules/language/useCurrentLanguage.ts';

type SelectProps = {
  label: string;
  onChange?: () => void;
  className?: string;
  style?: React.SelectHTMLAttributes<HTMLDivElement>['style'] & CustomStyles;
};

export const SelectLocation = forwardRef<HTMLDivElement, SelectProps>(
  function SelectLocation({ label, className, style, onChange }, ref) {
    const t = useTranslations('common');
    const { location } = useCurrentLocation();
    const { sendActivityTrackerEvent } = useActivityTracker();
    const { language } = useCurrentLanguage();
    const dispatch = useDispatch();
    const router = useExtendedRouter();
    const pathname = usePathname();

    function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
      const { value } = e.target;
      if (onChange) {
        onChange();
      }
      sendActivityTrackerEvent(
        ActivityTrackerEventType.LOCATION_SELECT_ACTION,
        {
          language,
          previousLocale: location,
          newLocale: e.target.value,
          schemaVersion: '1.0',
        }
      );
      dispatch(setCountry(value));
      router.replace(pathname);
    }
    const sortedLocations = locations.sort((a, b) =>
      // sort by alphabetical order
      a.name.localeCompare(b.name)
    );

    return (
      <Select
        small
        name="select__location"
        value={location}
        onChange={handleChange}
        label={label}
        ref={ref}
        className={className}
        style={style}
      >
        {sortedLocations.map((location) => (
          <option
            key={location.value}
            value={location.value}
            aria-label={t(location.key)}
          >
            {t(location.key)}
          </option>
        ))}
      </Select>
    );
  }
);

export function SelectLocationWithInlineLabel({ label }: SelectProps) {
  const dispatch = useDispatch();
  const { location } = useCurrentLocation();
  const { sendActivityTrackerEvent } = useActivityTracker();
  const { language } = useCurrentLanguage();
  const router = useExtendedRouter();
  const pathname = usePathname();

  const options = locations.map((location) => ({
    label: location.name,
    value: location.value,
  }));

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.target;
    sendActivityTrackerEvent(ActivityTrackerEventType.LOCATION_SELECT_ACTION, {
      language,
      previousLocale: location,
      newLocale: e.target.value,
      schemaVersion: '1.0',
    });
    dispatch(setCountry(value));
    router.replace(pathname);
  }

  return (
    <SelectWithInlineLabel
      label={label}
      name="inlineSelect__location"
      value={location}
      options={options}
      onChange={handleChange}
      ariaLabelledBy={label}
    />
  );
}
