import { getConsentChoices } from './cookies';

const DENY_GRANT = 'denied';
const ALLOW_GRANT = 'granted';
const ALLOW_LOCATIONS = ['us', 'nz', 'au'];

export function getDefaultGAConsent(location: string) {
  const defaultGrant = ALLOW_LOCATIONS.includes(location)
    ? ALLOW_GRANT
    : DENY_GRANT;
  return {
    ad_storage: defaultGrant,
    ad_user_data: defaultGrant,
    ad_personalization: defaultGrant,
    analytics_storage: defaultGrant,
  };
}

export function getGAConsent() {
  const consentChoices = getConsentChoices();
  return {
    ad_storage: consentChoices.social ? ALLOW_GRANT : DENY_GRANT,
    ad_user_data: consentChoices.social ? ALLOW_GRANT : DENY_GRANT,
    ad_personalization: consentChoices.social ? ALLOW_GRANT : DENY_GRANT,
    analytics_storage: consentChoices.analytics ? ALLOW_GRANT : DENY_GRANT,
  };
}
