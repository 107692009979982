import { useQuery } from '@tanstack/react-query';

import { RQ_USER_COUNTERS_KEY } from '../ReactQuery/cacheKeys';

import { fetchUserCounters } from './api';
import { useCurrentUser } from './useCurrentUser';

export function useUserCounter() {
  const [, user] = useCurrentUser();

  return useQuery({
    enabled: !!user?.id,
    queryKey: [RQ_USER_COUNTERS_KEY],
    queryFn: async () => {
      if (!user?.id) {
        return;
      }

      const res = await fetchUserCounters(user.id);
      return res.data;
    },
    retry: process.env.NODE_ENV === 'test' ? false : 3,
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
    staleTime: 30 * 1000, // Because this hook is used in a bunch of places on the same page, we don't want to send the same request multiple times. This sets a stale time of 30s (less than the refetch interval of 60s) to avoid over-fetching the query.
  });
}
