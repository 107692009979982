import React from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { LazyLoadImage } from '@depop/web-ui-kit/LazyLoadImage';

import styles from './styles.module.css';

import {
  ActivityTrackerEventType,
  moduleOriginParamName,
  NavigationComponentTypes,
} from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { ExtendedLink } from '@/components/ExtendedLink';
import { ImageLinkImage } from '@/modules/meganav/types';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';
import { TrackingType } from '@/modules/home/types.ts';

type FeaturedImageProps = {
  label: string;
  imageDesktop: ImageLinkImage;
  imageMobile: ImageLinkImage;
  path: string | null;
  extendedPath?: string | null;
  hideLabel?: boolean | null;
};

export function FeaturedImage({
  label,
  imageDesktop,
  imageMobile,
  path: _path,
  extendedPath,
  hideLabel = false,
}: FeaturedImageProps) {
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const path = extendedPath || _path || '';

  function sendTracking({ href, label }: { href: string; label: string }) {
    sendActivityTrackerEvent(ActivityTrackerEventType.NAVBAR_CLICK_ACTION, {
      href,
      label: label || '',
      componentType: NavigationComponentTypes.ImageLink,
      schemaVersion: '1.0',
    });
  }

  if (!imageDesktop?.url && !imageMobile?.url) {
    return null;
  }

  return (
    <div className={styles.featuredImageContainer}>
      <ExtendedLink
        onClick={() => sendTracking({ href: path, label })}
        href={appendParams(path, [moduleOriginParamName, TrackingType.meganav])}
      >
        {imageDesktop?.url && (
          <div className={styles.desktopImage}>
            <LazyLoadImage
              alt={imageDesktop?.description || label}
              src={imageDesktop.url}
            />
          </div>
        )}
        {imageMobile?.url && (
          <div className={styles.mobileImage}>
            <LazyLoadImage
              alt={imageMobile?.description || label}
              src={imageMobile.url}
            />
          </div>
        )}
        {!hideLabel && (
          <H3 as="h4" className={styles.descriptionText}>
            {label}
          </H3>
        )}
      </ExtendedLink>
    </div>
  );
}
