import { ActiveFilterValues, SortByValues } from './types';

import { Suggestion } from '@/modules/search/helpers';
import { SupportedLocation } from '@/modules/location/constants';

export const INITIAL_FILTER_STATE: ActiveFilterValues = {
  activeCategoryId: null,
  activeSubcategoryIds: [],
  activeSizeIds: [],
  activeBrandIds: [],
  activePrice: {
    max: null,
    min: null,
  },
  activeColours: [],
  activeConditions: [],
  isOnSale: false,
  sortBy: SortByValues.Relevance,
};

export const ITEMS_PER_PAGE = 24;

export const SEARCH_RESULTS_STALE_TIME = 5 * 60 * 1000;

export const SORT_BY_OPTIONS = [
  {
    id: SortByValues.Relevance,
    name: 'Sorting.Relevance',
  },
  {
    id: SortByValues.PriceLowToHigh,
    name: 'Sorting.PriceAscending',
  },
  {
    id: SortByValues.PriceHighToLow,
    name: 'Sorting.PriceDescending',
  },
  {
    id: SortByValues.NewlyListed,
    name: 'Sorting.NewlyListed',
  },
];

export const TRENDING_SEARCHES_SUGGESTIONS: Record<string, Suggestion[]> = {
  [SupportedLocation.US]: [
    { label: 'lululemon define jacket', value: 'lululemon define jacket' },
    { label: 'baggy jeans', value: 'baggy jeans' },
    { label: 'carhartt jacket', value: 'carhartt jacket' },
    { label: 'essentials hoodie', value: 'essentials hoodie' },
    { label: 'vintage t shirt', value: 'vintage t shirt' },
  ],
  [SupportedLocation.GB]: [
    { label: 'carhartt jacket', value: 'carhartt jacket' },
    { label: 'ralph lauren quarter zip', value: 'ralph lauren quarter zip' },
    { label: 'baggy jeans', value: 'baggy jeans' },
    { label: 'north face puffer', value: 'north face puffer' },
    { label: 'leather jacket', value: 'leather jacket' },
  ],
  [SupportedLocation.AU]: [
    { label: 'kookai', value: 'kookai' },
    { label: 'sabbi', value: 'sabbi' },
    { label: 'dissh', value: 'dissh' },
    { label: 'aje', value: 'aje' },
    { label: 'gorman', value: 'gorman' },
  ],
};
