import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Consent } from '@depop/web-ui-kit/CookieBanner';

import { SetConsentArgs, setWebConsent } from './api';

import { getLogger } from '@/modules/observability/logging';

export function useSetWebConsent() {
  const MAX_ATTEMPTS = 5;
  const [attempts, setAttempts] = useState(0);

  const setWebConsentMutation = useMutation({
    mutationFn: async (args: SetConsentArgs) => await setWebConsent(args),
    retry: MAX_ATTEMPTS,
    retryDelay: 300,
  });

  function postWebConsent(consent: Consent) {
    try {
      setAttempts((prev) => prev + 1);

      if (attempts > MAX_ATTEMPTS) {
        throw new Error('MAX_ATTEMPTS_EXCEEDED');
      }

      /** If user has not consented to analytics, exit */
      if (!consent.analytics || typeof window === 'undefined') {
        return;
      }

      const analyticsLoaded =
        'analytics' in window && typeof window?.analytics?.user === 'function';

      /** If Segment SDK isn't loaded, retry after 500ms */
      if (!analyticsLoaded) {
        setTimeout(() => {
          return postWebConsent(consent);
        }, 500);
        return;
      }

      const id = window.analytics.user().anonymousId();

      setWebConsentMutation.mutate(
        {
          uuid: id,
          functionality_cookies: consent.functional,
          social_media_cookies: consent.social,
          analytics_cookies: consent.analytics,
        },
        {
          onSuccess: () => {
            setAttempts(0);
          },
          onError: () => {
            getLogger().warn(
              `[WEB CONSENT] API call failed after ${MAX_ATTEMPTS} attempts`
            );
          },
        }
      );
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === 'MAX_ATTEMPTS_EXCEEDED') {
          getLogger().warn('[WEB CONSENT] Max attempts exceeded');
        }
      }
    }
  }

  return { postWebConsent };
}
