import React from 'react';
import { VisuallyHidden } from '@depop/web-ui-kit/VisuallyHidden';
import clsx from 'clsx';

import styles from './styles.module.css';

export function NotificationIndicator({
  description,
  className = '',
}: {
  description: string;
  className?: string;
}) {
  return (
    <span
      role="status"
      aria-describedby="notification-description"
      className={clsx(styles.indicator, className)}
    >
      <VisuallyHidden id="notification-description">
        {description}
      </VisuallyHidden>
    </span>
  );
}
