import { useEffect, useRef, useState } from 'react';

import { ROUTE_CHANGE_START_EVENT } from '@/modules/routing/constants';

export function useSearchBarModal() {
  const [isOpen, setIsOpen] = useState(false);
  const modalTrigger = useRef<HTMLButtonElement>(null);

  function closeModal() {
    return setIsOpen(false);
  }

  useEffect(() => {
    document.addEventListener(ROUTE_CHANGE_START_EVENT, closeModal);

    return () => {
      document.removeEventListener(ROUTE_CHANGE_START_EVENT, closeModal);
    };
  }, []);

  return {
    isOpen,
    setIsOpen,
    modalTrigger,
  };
}
