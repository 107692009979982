'use client';

import React from 'react';
import { AVATAR_SIZE, Avatar } from '@depop/web-ui-kit/Avatar';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import clsx from 'clsx';

import styles from './styles.module.css';

import { getNameVariations } from '@/modules/user/helpers';

type Props = {
  pictureUrl?: string;
  firstName: string;
  lastName: string;
  username: string;
  verified?: boolean;
  avatarSize?: AVATAR_SIZE;
  className?: string;
};

function UserDetails({
  pictureUrl,
  verified = false,
  firstName,
  lastName,
  username,
  avatarSize = AVATAR_SIZE.SMALL,
  className,
}: Props) {
  const { initials, fullName } = getNameVariations(firstName, lastName);

  return (
    <div className={clsx(className, styles.wrapper)}>
      <div className={styles.avatarWrapper}>
        <Avatar
          src={pictureUrl}
          verified={verified}
          initials={initials}
          size={avatarSize}
        />
      </div>
      <div className={styles.userInfo}>
        <Text bold className={styles.name}>
          {fullName}
        </Text>
        <Text type="caption1" className={styles.username}>
          @{username}
        </Text>
      </div>
    </div>
  );
}

export { UserDetails };
