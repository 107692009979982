'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';

import { QuickAccessBagHeader } from '../QuickAccessBagHeader';
import { QuickAccessBag } from '../QuickAccessBag';

import { Popover } from '@/components/Popover';
import { ADD_TO_BAG_EVENT_NAME } from '@/modules/bag/constants';
import { getDetailedBagItemCount } from '@/modules/bag/helpers';
import { useBagDetailed } from '@/modules/bag/useBagDetailed';
import { usePrevious } from '@/modules/refs/usePrevious';
import { useExperiments } from '@/modules/experiments/useExperiments';

type Timeout = ReturnType<typeof setTimeout>;
type Props = {
  anchor: Element | null;
  anchorTrigger?: boolean;
  mainAxisOffset?: number;
};

const POPOVER_SLOW_SHOW_TIME_MS = 2000;
const POPOVER_QUICK_SHOW_TIME_MS = 500;

enum FETCH_STEPS {
  Inactive = 'inactive',
  Fetching = 'fetching',
  Complete = 'complete',
}

export function QuickAccessBagPopover({
  anchor,
  anchorTrigger,
  mainAxisOffset,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);
  const { isMinMedium } = useResponsive();
  const timeoutRef = useRef<Timeout | null>(null);
  const isActive = useRef<boolean>(false);
  const previousAnchorTrigger = usePrevious(anchorTrigger);
  const [queuedDelayedClose, setQueuedDelayedClose] = useState(0);
  const [fetchStep, setFetchStep] = useState(FETCH_STEPS.Inactive);
  const [_, bucketHideShippingCostExp] = useExperiments(
    ['web_5780_hide_shipping_cost_on_bag_and_qab'],
    {
      deferred: true,
    }
  );

  const {
    query: { data, isFetching },
  } = useBagDetailed({
    enabled: isMinMedium && !!anchorTrigger,
    includeOffers: true,
  });

  const itemCount = data ? getDetailedBagItemCount(data) : 0;
  const isOpenFromAnchorTrigger =
    Boolean(anchorTrigger) && isMinMedium && itemCount > 0;

  useEffect(() => {
    if (fetchStep === FETCH_STEPS.Inactive && isFetching) {
      setFetchStep(FETCH_STEPS.Fetching);
    }
    if (fetchStep === FETCH_STEPS.Fetching && !isFetching) {
      setFetchStep(FETCH_STEPS.Complete);
    }
  }, [isFetching, fetchStep]);

  useEffect(() => {
    if (fetchStep === FETCH_STEPS.Complete) {
      setFetchStep(FETCH_STEPS.Inactive);
      if (queuedDelayedClose) {
        setQueuedDelayedClose(0);
        if (!isActive.current) {
          timeoutRef.current = setTimeout(
            () => setIsOpen(false),
            queuedDelayedClose
          );
        }
      }
    }
  }, [fetchStep, queuedDelayedClose, isActive.current]);

  function beginDelayedClose(interval: number, waitForFetchCompletion = false) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (waitForFetchCompletion) {
      setQueuedDelayedClose(interval);
    } else {
      timeoutRef.current = setTimeout(() => setIsOpen(false), interval);
    }
  }

  function handleItemAddedToBag() {
    // need to force a state update to avoid stale values in here
    setItemAdded(true);
  }

  useEffect(() => {
    document.addEventListener(ADD_TO_BAG_EVENT_NAME, handleItemAddedToBag);

    return () => {
      document.removeEventListener(ADD_TO_BAG_EVENT_NAME, handleItemAddedToBag);
    };
  }, []);

  useEffect(() => {
    if (isOpenFromAnchorTrigger) {
      if (isMinMedium) {
        if (timeoutRef?.current) {
          clearTimeout(timeoutRef.current);
        }
        setIsOpen(true);
      }
    } else {
      if (!isActive.current && isOpen && previousAnchorTrigger && isMinMedium) {
        beginDelayedClose(POPOVER_QUICK_SHOW_TIME_MS);
      }
    }
  }, [
    isOpenFromAnchorTrigger,
    isActive.current,
    isOpen,
    previousAnchorTrigger,
    isMinMedium,
  ]);

  useEffect(() => {
    if (itemAdded && isMinMedium) {
      setItemAdded(false);
      setIsOpen(true);
      beginDelayedClose(POPOVER_SLOW_SHOW_TIME_MS, true);
    }
  }, [itemAdded, isMinMedium]);

  useEffect(() => {
    if (isOpen) {
      bucketHideShippingCostExp();
    }
  }, [isOpen]);

  function handleClose() {
    setIsOpen(false);
  }

  function handleMouseEnter() {
    isActive.current = true;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  function handleFocus() {
    isActive.current = true;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  function handleMouseLeave() {
    isActive.current = false;
    beginDelayedClose(POPOVER_QUICK_SHOW_TIME_MS);
  }

  function handleBlur() {
    isActive.current = false;
    beginDelayedClose(POPOVER_QUICK_SHOW_TIME_MS);
  }

  if (!isMinMedium) {
    return null;
  }

  return (
    <Popover
      customOverrides={{
        headerTitle: <QuickAccessBagHeader />,
      }}
      anchor={anchor}
      isOpen={isOpen}
      withTransition={false}
      onClose={handleClose}
      onMouseEnter={handleMouseEnter}
      onFocus={handleFocus}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      mainAxisOffset={mainAxisOffset}
    >
      <QuickAccessBag />
    </Popover>
  );
}
