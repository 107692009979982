import { useEffect } from 'react';
import { useResponsive } from '@depop/web-ui-kit/useResponsive';
import { usePathname } from 'next/navigation';

import { useExperiments } from '@/modules/experiments/useExperiments';
import { VARIANT_IDENTIFIER } from '@/modules/experiments/config';

export function useShowHomepageSearchBar() {
  const pathname = usePathname();
  const { isMaxMedium: isMobile } = useResponsive();
  const [{ web_6284_visible_searchbar_on_mobile_homepage }, bucketFn] =
    useExperiments(['web_6284_visible_searchbar_on_mobile_homepage'], {
      deferred: true,
    });

  const isHomepage = pathname === '/';

  useEffect(() => {
    if (isHomepage && isMobile) {
      bucketFn();
    }
  }, [isHomepage, isMobile]);

  return (
    web_6284_visible_searchbar_on_mobile_homepage === VARIANT_IDENTIFIER &&
    isHomepage
  );
}
