import { useEffect, useState } from 'react';
import { TRACKING_PROVIDER_ID } from '@depop/web-ui-kit/CookieBanner';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentSession } from '@/modules/activityTracker/helpers';
import { CustomEvents } from '@/constants/customEvents';
import { useTrackingProviders } from '@/modules/trackingProviders/useTrackingProviders';
import { OVERRIDE_PIXEL_ID } from '@/modules/trackingProviders/Facebook';
import { useCurrentLocation } from '@/modules/location/useCurrentLocation';
import { recordMetric } from '@/modules/observability/metrics';
import { Stdv1Stat } from '@/modules/observability/constants';
import {
  setShowCookieModal,
  setCookieBannerActionNeeded,
  resetCookieBannerShown,
  setShowCookieBanner as setShowCookieBannerAction,
} from '@/modules/redux/slices/cookieSlice';
import { RootState } from '@/modules/redux/stores/storeClient';

export function useCookieBanner(
  segmentWriteKey?: string,
  googleMeasureMentId?: string
) {
  const dispatch = useDispatch();
  const { sessionStorageSessionId } = getCurrentSession();
  const [hasSessionId, setHasSessionId] = useState(
    Boolean(sessionStorageSessionId)
  );
  const { showBanner, hasShownBanner, showModalOnly } = useSelector(
    (state: RootState) => state.cookies.cookieBanner
  );

  function handleSessionIdChange() {
    if (hasSessionId) {
      return;
    }
    setHasSessionId(true);
  }

  function showCookiesModal() {
    dispatch(setShowCookieModal());
  }

  function setShowCookieBanner(shouldShow: boolean) {
    dispatch(setShowCookieBannerAction(shouldShow));
  }

  useEffect(() => {
    window.addEventListener(CustomEvents.SessionId, handleSessionIdChange);

    return () => {
      window.removeEventListener(CustomEvents.SessionId, handleSessionIdChange);
    };
  }, []);

  const trackingProviders = useTrackingProviders();
  const { location } = useCurrentLocation();

  useEffect(() => {
    if (!googleMeasureMentId && !segmentWriteKey) {
      return;
    }
    try {
      const isActionNeeded = trackingProviders.init({
        [TRACKING_PROVIDER_ID.FACEBOOK]: {
          pixelId: OVERRIDE_PIXEL_ID,
        },
        [TRACKING_PROVIDER_ID.GOOGLE]: {
          trackingCode: googleMeasureMentId,
          segmentWriteKey,
          location,
        },
        [TRACKING_PROVIDER_ID.DEPOP]: null,
      });
      if (isActionNeeded && hasSessionId) {
        dispatch(setCookieBannerActionNeeded());
      }
    } catch {
      recordMetric({
        type: 'increment',
        stat: Stdv1Stat['cookie.trackers.init.failed'],
      });
    }
  }, [hasSessionId]);

  useEffect(() => {
    if (!showBanner && hasShownBanner) {
      window.dispatchEvent(new Event(CustomEvents.CookieAcceptance));
      dispatch(resetCookieBannerShown());
    }
  }, [showBanner, hasShownBanner]);

  return {
    showCookieBanner: showBanner,
    showCookiesModal,
    showModalOnly,
    setShowCookieBanner,
  };
}
