import React from 'react';
import { useTranslations } from 'next-intl';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Carousel } from '@depop/web-ui-kit/Carousel';

import { BagDeleteSoldOutItems } from '../BagDeleteSoldOutItems';

import styles from './styles.module.css';

import {
  filterRequiredPictures,
  getNearestSizePictureFormat,
} from '@/modules/pictures/helpers';
import { BagDetailedProduct } from '@/modules/bag/types';
import { SellingItemImage } from '@/components/SellingItemImage';

type SoldOutProps = {
  products: BagDetailedProduct[];
  sellerId: number;
};

const itemsPerSlide = {
  xs: 3,
  sm: 3,
  md: 4,
  lg: 4,
  xl: 3,
};

function getProductToDisplay(product: BagDetailedProduct) {
  const { pictures, status } = product;
  if (pictures) {
    return (
      <div className={styles.soldOutImageContainer}>
        <SellingItemImage
          className={styles.sellingItemImage}
          src={
            getNearestSizePictureFormat(
              filterRequiredPictures(pictures[0]),
              120
            )?.url
          }
          status={status}
        />
      </div>
    );
  }
  return null;
}

function BagSoldOutItems({ products, sellerId }: SoldOutProps) {
  const t = useTranslations('bag');

  return (
    <>
      <div className={styles.headingContainer}>
        <H3 className={styles.heading}>{t('Bag.SoldOut.Title')}</H3>
      </div>
      <Carousel
        itemsPerSlide={itemsPerSlide}
        items={products.map(getProductToDisplay)}
      />
      <BagDeleteSoldOutItems products={products} sellerId={sellerId} />
    </>
  );
}

export { BagSoldOutItems };
