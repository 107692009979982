import {
  COOKIE_TYPE,
  TRACKING_PROVIDER_ID,
} from '@depop/web-ui-kit/CookieBanner';

import { TrackingProvider } from './types';
import { hasConsent, setConsent } from './cookies';

export class Depop implements TrackingProvider {
  public id = TRACKING_PROVIDER_ID.DEPOP;
  public types = [COOKIE_TYPE.FUNCTIONAL];
  public hasInitialised = false;

  constructor() {
    // Do nothing for now
  }

  public onPageLoad = () => {
    const shouldInit = hasConsent(this.id, this.types[0]);
    if (shouldInit) {
      this.init();
    }
  };
  public onConsentAccepted = (type: COOKIE_TYPE) => {
    setConsent(this.id, type, true);
    if (!this.hasInitialised) {
      this.init();
    }
  };
  public onConsentRejected = (type: COOKIE_TYPE) => {
    setConsent(this.id, type, false);
  };

  private init = () => {
    if (navigator.doNotTrack !== '1') {
      this.hasInitialised = true;
    }
  };
}
