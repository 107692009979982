import { WEB_SEGMENT_CONSENT_ENDPOINT } from '@/constants/endpoints';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import http from '@/modules/http';

export type SetConsentArgs = {
  uuid: string;
  social_media_cookies: boolean;
  analytics_cookies: boolean;
  functionality_cookies: boolean;
};

export async function setWebConsent(
  args: SetConsentArgs
): Promise<AxiosCompatibleResponse> {
  const { uuid, ...data } = args;
  return http.post(WEB_SEGMENT_CONSENT_ENDPOINT.replace(':uuid', uuid), data, {
    withAuth: true,
  });
}
