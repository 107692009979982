import React from 'react';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import {
  ActivityTrackerEventType,
  moduleOriginParamName,
  NavigationComponentTypes,
} from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { CategoryList as MeganavCategoryList } from '@/modules/meganav/types';
import { ExtendedLink } from '@/components/ExtendedLink';
import { appendParams } from '@/modules/routing/appendParams/appendParams.ts';
import { TrackingType } from '@/modules/home/types.ts';
import { isNotNull } from '@/modules/cms/helpers/isNotNull';

type Props = {
  isOpen?: boolean;
} & MeganavCategoryList;

export function CategoryList({
  isOpen,
  title,
  itemsCollection: { items },
  footer,
}: Props) {
  const [, currentUser] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({
    userId: currentUser?.id,
  });
  const tabIndex = isOpen ? 0 : -1;

  function sendTracking(params: {
    href: string;
    label: string;
    componentType: string;
  }) {
    sendActivityTrackerEvent(ActivityTrackerEventType.NAVBAR_CLICK_ACTION, {
      ...params,
      schemaVersion: '1.0',
    });
  }

  function onLinkClick(href: string, label: string) {
    sendTracking({
      href,
      label,
      componentType: NavigationComponentTypes.TextLink,
    });
  }

  function onFooterLinkClick() {
    sendTracking({
      href: footer?.path || '',
      label: footer?.label || '',
      componentType: NavigationComponentTypes.TextLinkFooter,
    });
  }

  return (
    <ul className={styles.list}>
      <li className={styles.baseListItem}>
        <H3>{title}</H3>
      </li>
      {items.filter(isNotNull).map((item) => (
        <li className={styles.baseListItem} key={item.label}>
          <ExtendedLink
            href={appendParams(item.path, [
              moduleOriginParamName,
              TrackingType.meganav,
            ])}
            tabIndex={tabIndex}
            onClick={() => onLinkClick(item.path, item.label)}
            className={styles.anchor}
          >
            <Text
              as="span"
              className={styles.anchor}
              style={
                item.colourOverride
                  ? {
                      '--textColor': item.colourOverride,
                    }
                  : {}
              }
            >
              {item.label}
            </Text>
          </ExtendedLink>
        </li>
      ))}
      {footer && (
        <li className={styles.baseListItem}>
          <ExtendedLink
            className={styles['anchor--active']}
            href={footer.path}
            tabIndex={tabIndex}
            onClick={onFooterLinkClick}
          >
            {footer.label}
          </ExtendedLink>
        </li>
      )}
    </ul>
  );
}
