import React from 'react';
import { useTranslations } from 'next-intl';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { Placeholder } from '@/components/Placeholder';
import { BagCheckoutButton } from '@/components/Bag/BagCheckoutButton';
import {
  isOptimisticSeller,
  hasOptimisticProducts,
  BagDetailedSellerCache,
  isOptimisticSummary,
} from '@/modules/bag/types';
import { getNonoptimisticProducts } from '@/modules/bag/helpers';
import { Price } from '@/components/Price';

type Props = {
  seller: BagDetailedSellerCache['seller'];
  unsoldProducts: BagDetailedSellerCache['products'];
  summary: BagDetailedSellerCache['summary'];
  showEstimatedShipping: boolean;
};

function QuickAccessBagPricingAndCheckout({
  seller,
  unsoldProducts,
  summary,
  showEstimatedShipping,
}: Props) {
  const tBag = useTranslations('bag');
  const tCommon = useTranslations('common');
  const nonoptimisticUnsoldProducts = getNonoptimisticProducts(unsoldProducts);

  return (
    <div className={styles.paymentWrapper}>
      {isOptimisticSeller(seller) ||
      isOptimisticSummary(summary) ||
      hasOptimisticProducts(unsoldProducts) ? (
        <>
          <div className={styles.total}>
            <Placeholder width="153px" height="24px" shape="rectangle" />
            <Placeholder width="60px" height="24px" shape="rectangle" />
          </div>
          <Placeholder width="100%" height="44px" shape="rectangle" />
        </>
      ) : (
        <>
          <div className={styles.total}>
            <H3>{tBag('Bag.QuickAccess.Total')}</H3>
            <Price
              className={styles.totalPrice}
              currencyCode={summary.currency}
              price={summary.total_amount}
            />
          </div>
          {!showEstimatedShipping && (
            <Text className={styles.shipping} type="caption1">
              {tCommon('ShippingCalculatedAtCheckout')}
            </Text>
          )}
          <BagCheckoutButton
            trackingType="quickAccessBag"
            products={nonoptimisticUnsoldProducts}
            seller={seller}
          />
        </>
      )}
    </div>
  );
}

export { QuickAccessBagPricingAndCheckout };
