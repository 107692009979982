import React from 'react';
import { useTranslations } from 'next-intl';
import { H1 } from '@depop/web-ui-kit/Typography/H1';
import { CancelIcon } from '@depop/web-ui-kit/Icons/CancelIcon';

import { CustomOverrides } from '../index';

import styles from './styles.module.css';

type Props = {
  title?: string;
  showCloseButton?: boolean;
  customOverrides?: CustomOverrides;
  onClose?: () => void;
};

function PopoverHeader({
  title,
  showCloseButton = true,
  customOverrides,
  onClose,
}: Props) {
  const t = useTranslations('common');

  if (!showCloseButton && !title && !customOverrides?.headerTitle) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {customOverrides?.headerTitle || (title && <H1>{title}</H1>)}
      {showCloseButton && (
        <button className={styles.closeButton} onClick={onClose}>
          <CancelIcon title={t('Close')} aria-label={t('Close')} />
        </button>
      )}
    </div>
  );
}

export { PopoverHeader };
