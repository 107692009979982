import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CookieBanner = {
  showBanner: boolean;
  hasShownBanner: boolean;
  showModalOnly: boolean;
};

export interface CookiesState {
  cookieBanner: CookieBanner;
}

const initialState: CookiesState = {
  cookieBanner: {
    showBanner: false,
    hasShownBanner: false,
    showModalOnly: false,
  },
};

export const cookiesSlice = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    setShowCookieBanner: (state, action: PayloadAction<boolean>) => {
      state.cookieBanner.showBanner = action.payload;
      if (action.payload) {
        state.cookieBanner.hasShownBanner = true;
      }
    },
    setCookieBannerActionNeeded: (state) => {
      state.cookieBanner.showBanner = true;
      state.cookieBanner.hasShownBanner = true;
    },
    setShowCookieModal: (state) => {
      state.cookieBanner = {
        showModalOnly: true,
        showBanner: true,
        hasShownBanner: true,
      };
    },
    resetCookieBannerShown: (state) => {
      state.cookieBanner.hasShownBanner = false;
    },
  },
});

export const {
  resetCookieBannerShown,
  setShowCookieModal,
  setCookieBannerActionNeeded,
  setShowCookieBanner,
} = cookiesSlice.actions;

export default cookiesSlice.reducer;
