import { useTranslations } from 'next-intl';
import React from 'react';
import { LikeIcon } from '@depop/web-ui-kit/Icons/LikeIcon';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { H3 } from '@depop/web-ui-kit/Typography/H3';
import clsx from 'clsx';

import styles from './styles.module.css';

import { LegacyProductStatus, ProductStatus } from '@/modules/product/types';
import { isSold } from '@/modules/product/helpers/isSold';

type Props = {
  status?: ProductStatus | LegacyProductStatus;
  likeCount?: number;
  src?: string;
  className?: string;
  isInteractive?: boolean;
  altText?: string;
  displayBoostedOverlay?: boolean;
  hasProductInsights?: boolean;
};

/**
 * @param src
 * @param {number=} likeCount - pass undefined to not render likes info at all
 * @param status
 * @param className
 * @param isInteractive
 * @param altText
 * @param displayBoostedOverlay
 * @param hasProductInsights
 */
function SellingItemImage({
  src,
  likeCount,
  status,
  className,
  isInteractive = false,
  altText = '',
  displayBoostedOverlay = false,
  hasProductInsights,
}: Props) {
  const commonT = useTranslations('common');
  const itemSold = status && isSold(status);

  const shouldDisplayLikes =
    !itemSold && !hasProductInsights && typeof likeCount === 'number';

  return (
    <div
      className={clsx(styles.imageWrapper, className, {
        [styles.interactiveImageWrapper]: isInteractive,
      })}
    >
      {shouldDisplayLikes && (
        <div className={styles.likesWrapper}>
          <LikeIcon size={18} />
          <Text type="caption1" className={styles.likesNumber}>
            {likeCount}
          </Text>
        </div>
      )}
      <img src={src} alt={altText} className={styles.image} />
      {itemSold && (
        <div className={styles.imageOverlay}>
          <H3 as="p" className={styles.soldText}>
            {commonT('SoldSingular')}
          </H3>
        </div>
      )}
      {!itemSold && displayBoostedOverlay && (
        <div className={styles.imageOverlay}>
          <H3 as="p" className={styles.boostedText}>
            {commonT('BoostedListings.Boosted')}
          </H3>
        </div>
      )}
    </div>
  );
}

export { SellingItemImage };
