import { deleteCookie, getCookie } from 'cookies-next';
import { datadogLogs } from '@datadog/browser-logs';

import { revokeAccessToken } from './api';

import {
  ACCESS_TOKEN_KEY,
  USER_ID_KEY,
  HASHED_USER_EMAIL_KEY,
} from '@/constants/cookies';
import { useExternalTracking } from '@/modules/externalTracking/useExternalTracking';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { segmentEventTypes } from '@/modules/externalTracking/types';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { useCurrentUser } from '@/modules/user/useCurrentUser';

export function useAuth() {
  const { branch, ga, segment } = useExternalTracking();
  const { sendActivityTrackerEvent } = useActivityTracker();
  const [, user] = useCurrentUser();

  const logoutUserEvent = {
    category: 'Logout',
    action: 'user clicks logout',
  };

  function logout() {
    /**
     * Fire and forget request to revoke access token
     * it is not critical that this succeeds, just a nice-to-have
     */
    revokeAccessToken(getCookie(ACCESS_TOKEN_KEY) || '');

    ga.sendEvent(logoutUserEvent);
    sendActivityTrackerEvent(ActivityTrackerEventType.LOGOUT_ACTION);

    branch.logout();

    if (user) {
      segment.sendEvent(segmentEventTypes.SIGNED_OUT, {
        userId: user.id.toString(),
        username: user.username,
      });
    }

    deleteCookie(ACCESS_TOKEN_KEY, { path: '/' });
    deleteCookie(USER_ID_KEY, { path: '/' });
    deleteCookie(HASHED_USER_EMAIL_KEY, { path: '/' });

    datadogLogs.clearUser();

    window.location.reload();
  }

  return { logout };
}
