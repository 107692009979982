import React, { Ref } from 'react';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import styles from './styles.module.css';

import { Suggestion } from '@/modules/search/helpers';
import {
  SearchSuggestion as TSearchSuggestion,
  SearchSuggestionType,
} from '@/modules/search/types';

interface SearchSuggestionListProps {
  suggestions: Suggestion[];
  type: string;
  handleClick: (
    suggestion: Suggestion,
    suggestionType: SearchSuggestionType
  ) => void;
  suggestionsRef?: Ref<HTMLUListElement>;
  suggestionType: SearchSuggestionType;
}

function getSearchSuggestionType(suggestion: TSearchSuggestion) {
  if (suggestion.categoryId) {
    return SearchSuggestionType.POPULAR_WITH_CATEGORY;
  }
  return null;
}

export function SearchSuggestionList({
  suggestions,
  type,
  handleClick,
  suggestionsRef,
  suggestionType: defaultSuggestionType,
}: SearchSuggestionListProps) {
  return (
    <ul
      className={clsx(styles.suggestionList)}
      aria-label={`${type} searches`}
      id={`${type}-searches`}
      ref={suggestionsRef}
      data-testid={`searchBar__${type}--list`}
    >
      {suggestions.map((suggestion, i) => {
        const hasSuggestedFilterLabel = !!suggestion?.suggestedFilterLabel;
        const suggestionType =
          getSearchSuggestionType(suggestion) ?? defaultSuggestionType;
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <li key={`${suggestion.value}-${i}`} tabIndex={0}>
            <Text
              className={clsx(styles.searchSuggestion)}
              as="a"
              role="link"
              onClick={() => handleClick(suggestion, suggestionType)}
              data-testid={`searchBar__${type}--${i}`}
              data-value={suggestion.value}
            >
              <p className={clsx(styles.textWithEllipsis)}>
                <ReactMarkdown components={{ p: 'span' }}>
                  {suggestion.label}
                </ReactMarkdown>
                {hasSuggestedFilterLabel && (
                  <span className={clsx(styles.categorySuggestionText)}>
                    {' '}
                    {suggestion.suggestedFilterLabel}
                  </span>
                )}
              </p>
            </Text>
          </li>
        );
      })}
    </ul>
  );
}
